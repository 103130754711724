import { useEffect, useState } from "react";
import {
    Box,
    FormControl,
    MenuItem,
    Select,
    TextField,
    Typography,
} from "@mui/material";
import { getCandidateEventAssessmentGroupDynamic } from "../../lib/page";
import {
    setCandidateAssessmentResultComment,
    setCandidateAssessmentResultGroupDynamic,
} from "../../lib/db_candidate";
import getTime from "../../utils/getTime";
import {
    AssessmentButtonAction,
    AssessmentLayout,
} from "../layout/AssessmentLayout";
import SuccessMsg from "../SuccessMsg";

function GroupDynamic({ candidateId, eventId, username, isComment }) {
    const [result, setResult] = useState({
        group: "",
        overall: "",
    });
    const [overallError, setOverallError] = useState({
        msg: "",
        bool: false,
    });
    const [resultDetail, setResultDetail] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [initialStatus, setInitialStatus] = useState("");
    const [formError, setFormError] = useState(false);
    const [isShowPopup, setIsShowPopup] = useState(false);

    useEffect(() => {
        async function getResult() {
            const res = await getCandidateEventAssessmentGroupDynamic(
                candidateId,
                eventId
            );
            if (res !== undefined) {
                setResultDetail(res);
                setInitialStatus(res.status);
                setResult({
                    group: `${res.total_score} / ${res.interviewerInfo.length}`,
                    overall: res.status,
                });
            } else {
                setResultDetail();
                setInitialStatus("");
                setResult({
                    group: "",
                    overall: "",
                });
            }
        }
        if (candidateId !== undefined) {
            getResult();
        } else {
            setResult({
                group: "",
                overall: "",
            });
        }
    }, [candidateId, eventId]);

    const handleChange = (e) => {
        setResult({
            ...result,
            [e.target.name]: e.target.value,
        });
        if (e.target.value.length > 0) {
            setOverallError({
                msg: "",
                bool: false,
            });
        }
    };

    const handleReset = async () => {};

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (candidateId === undefined) {
            setFormError(true);
            setIsShowPopup(true);
            return;
        }
        if (initialStatus === result.overall) {
            return;
        }
        if (result.overall.length === 0) {
            setOverallError({
                msg: "Overall Score cannot be empty",
                bool: true,
            });
            setFormError(true);
            setIsShowPopup(true);
        }
        setIsLoading(true);
        await setCandidateAssessmentResultGroupDynamic(candidateId, eventId, {
            total_score: resultDetail?.passing_score ?? 0,
            passing_score: resultDetail?.passing_score ?? 0,
            status: result.overall,
            interviewerInfo: resultDetail?.interviewerInfo ?? {},
        });

        setCandidateAssessmentResultComment(candidateId, eventId, {
            id: new Date().getTime(),
            comment: `Changed status from ${initialStatus} to ${result.overall}`,
            module: "Group Dynamic",
            overall_score: result.overall,
            created_by: username,
            created_at: getTime(),
        });
        setInitialStatus(result.overall);
        isComment();
        setIsLoading(false);
        setFormError(false);
        setIsShowPopup(true);
    };

    return (
        <>
            <AssessmentLayout title={"Group Dynamic"}>
                <Box
                    sx={{
                        mt: 2,
                        mb: 2,
                        boxShadow: "0px 1px 4px 0px #00000029",
                        width: "50%",
                        p: 3,
                    }}
                >
                    <Typography>Candidate Score</Typography>
                    <TextField
                        value={result.group}
                        disabled
                        size="small"
                        style={{ width: "100%", marginTop: "12px" }}
                    />
                </Box>
                <Box
                    sx={{
                        mt: 2,
                        mb: 2,
                        p: 3,
                        textAlign: "left",
                        boxShadow: "0px 1px 4px 0px #00000029",
                        width: "50%",
                    }}
                >
                    <Typography>Overall Score</Typography>
                    <FormControl sx={{ minWidth: 250 }}>
                        <Select
                            name="overall"
                            value={result.overall}
                            onChange={handleChange}
                            error={overallError.bool}
                        >
                            <MenuItem value={""}>-</MenuItem>
                            <MenuItem value={"Passed"}>Passed</MenuItem>
                            <MenuItem value={"KIV"}>KIV</MenuItem>
                            <MenuItem value={"Failed"}>Failed</MenuItem>
                        </Select>
                        {overallError.bool ? (
                            <small style={{ color: "red" }}>
                                {overallError.msg}
                            </small>
                        ) : (
                            ""
                        )}
                    </FormControl>
                </Box>
            </AssessmentLayout>
            <AssessmentButtonAction
                saveAction={handleSubmit}
                secondButton={"Reset"}
                secondAction={handleReset}
                isLoading={isLoading}
            />
            <SuccessMsg
                isDialogOpen={isShowPopup}
                handleCloseDialog={() => setIsShowPopup(false)}
                title={formError ? "Error" : "Success"}
                message={
                    candidateId === undefined
                        ? "Please select candidate"
                        : formError
                        ? "Form is incomplete"
                        : "Success on saving candidate assessment"
                }
            />
        </>
    );
}

export default GroupDynamic;
