import {
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormLabel,
} from "@mui/material";

function AssessmentRender({ list, handleChange, formError, isDisabled }) {
    const rows = []
    for (let index = 0; index < list.length; index++) {
        rows.push(<FormControlLabel key={list[index].name} 
            control={
                <Checkbox
                    key={list[index].name}
                    checked={list[index].checked}
                    onChange={handleChange}
                    name={list[index].name}
                />
            }
            label={list[index].name}
        />)
    }

    return (
        <FormControl disabled={isDisabled}>
            <FormLabel>
                Select applicable assessment for the event {formError.error ? <span style={{color: "#c92d21"}} >({formError.msg})</span> : <span></span>}
            </FormLabel>
            <FormGroup sx={{ ml:2 }}>{rows}</FormGroup>
        </FormControl>
    )

}

export default AssessmentRender