import axios from 'axios';

// TODO:
// 1. if running in local run `firebase use <env> && firebase use emulators:start`
const axiosInstance = axios.create({
  baseURL: process.env['REACT_APP_API_BASE_URL'] ?? 'http://127.0.0.1:5001/airasia-incredible-stg/asia-southeast1/api', // Replace with your API base URL
  // timeout: 30000, // Set your preferred timeout
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = sessionStorage.getItem('id_token');

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const axiosService = {
  get: async (url, params = {} ) => {
    console.log(url);
    try {
      const response = await axiosInstance.get(url, { params });
      return response.data;
    } catch (error) {
      return handleRequestError(error);
    }
  },
  post: async (url, data) => {
    console.log(url);
    try {
      const response = await axiosInstance.post(url, data);
      return response.data;
    } catch (error) {
      return handleRequestError(error);
    }
  },
  // Add more methods as needed (e.g., put, delete, etc.)
};


const handleRequestError = (error) => {
  console.error('Request Error:', error.message);

  if (error.response) {
    // The request was made, but the server responded with a status code outside of the 2xx range
    console.error('Response Data:', error.response.data);
    console.error('Response Status:', error.response.status);
    console.error('Response Headers:', error.response.headers);
  } else if (error.request) {
    // The request was made, but no response was received
    console.error('No response received. Request:', error.request);
  } else {
    // Something happened in setting up the request that triggered an Error
    console.error('Error setting up the request:', error.message);
  }

  return `${error.message}`;
};

const apiService = {
  post: async ( url, data ) => {
    return await axiosService.post(url, data)
  },
  get: async ( url, params ) => {
    return await axiosService.get(url, params)
  },
  verifyReCaptchaToken: async (token) => {
    return await axiosService.post("/recaptcha/verify", {token})
  },
  addEvent: async ( data ) => {
    return await axiosService.post('/events/addEvent', data )
  },
  updateEvent: async ( data ) => {
    return await axiosService.post('/events/updateEvent', data )
  },
  getEventList: async ( data ) => {
    return await axiosService.post('/events/getEventList', data )
  },
  getEvent: async ( data ) => {
    return await axiosService.post('/events/getEvent', data )
  },
  autoAddCandidates: async ( data ) => {
    return await axiosService.post('/events/autoAddCandidates', data )
  },
  addCandidatesToEvent: async ( data ) => {
    return await axiosService.post('/events/addCandidatesToEvent', data)
  },
  getJrNumbers: async ( params ) => {
    return await axiosService.get('/events/getJrNumbers', params ); 
  },
  getSyncReports: async( data ) => {
    return await axiosService.post('/events/getSyncReports', data );
  },
  addCandidateToWorkday: async ( data ) => {
    return await axiosService.post('/workday/addCandidateToWorkday', data )
  },
  getCandidatesTotalCount: async ( data ) => {
    return await axiosService.post('/candidates/getCandidatesTotalCount', data )
  },
  getCandidateList: async ( data ) => {
    return await axiosService.post('/candidates/getCandidateList', data )
  },
  getEventCandidateList: async ( data ) => {
    return await axiosService.post('/candidates/getEventCandidateList', data )
  },
  registerCandidate: async ( data ) => {
    return await axiosService.post('/candidates/registerCandidate', data);
  },
  autoUpdateJobApplication: async ( data ) => {
    return await axiosService.post('/events/autoUpdateJobApplication', data )
  },
  getCandidateJobApplications: async ( data ) => {
    return await axiosService.post('/candidates/getCandidateJobApplications', data )
  },
  encrypt: async ( data ) => {
    return await axiosService.post('/encryption/encrypt', data );
  },
  decrypt: async ( data ) => {
    return await axiosService.post('/encryption/decrypt', data );
  }
}

export default apiService;