import { Box, Button, Toolbar, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, styled } from "@mui/material";
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import { useState } from "react";
import { getAuth, signOut } from "firebase/auth";
import { useNavigate } from 'react-router-dom'
import Typography from "@mui/material/Typography";
import MenuIcon from '@mui/icons-material/Menu';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import Divider from '@mui/material/Divider';

function MenuTab({ username, url }) {
  const navigate = useNavigate()
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const sidebarIconStyle = {
    fontSize: "11px", 
    display: "flex", 
    justifyContent: "center", 
    alignItems: "center"
  }
  const sidebarItem = [
    {
      path: "/candidates",
      name: "Candidates",
      second_line: "List",
      icon: <img src="/images/services.png" alt="Candidates" style={{height: 24, width: 24}}/>,
    },
    {
      path: "/events",
      name: "Events",
      second_line: "List",
      icon: <img src="/images/list.png" alt="Candidates" style={{height: 24, width: 24}}/>
    },
    {
      path: "/uploadResult",
      name: "Upload Results",
      first_line: "Upload",
      second_line: "Results",
      icon: <img src="/images/upload.png" alt="Candidates" style={{height: 24, width: 24}}/>
    },
    {
      path: "/dashboard",
      name: "Dashboard",
      icon: <DashboardIcon sx={{color: 'black'}} />,
    },
  ]
  const drawerWidth = 200;

  const openedSidePanel = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
  });

  const closedSidePanel = (theme) => ({
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
      width: `calc(${theme.spacing(8)} + 1px)`,
    },
  });

  const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // content to be below app bar
    ...theme.mixins.toolbar,
  }));

  const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
      boxSizing: 'border-box',
      ...(open && {
        ...openedSidePanel(theme),
        '& .MuiDrawer-paper': openedSidePanel(theme),
      }),
      ...(!open && {
        ...closedSidePanel(theme),
        '& .MuiDrawer-paper': closedSidePanel(theme),
      }),
    }),
  );

  const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
  })(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  }));

  function googleSignout() {
    const auth = getAuth();
    signOut(auth).then(() => {
      sessionStorage.clear();
      navigate("/")
    }).catch((error) => {
      alert('Signout error');
    });
  }
  return (
    <>
      <AppBar
        component="nav"
        position="absolute"
        color="menu"
        elavation={0}
        sx={{ position: 'relative', boxShadow: '0px 4px 4px 0px #00000014'}}
      >
        <Toolbar
          sx={{
            color: "#4C4C50",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box display="flex" width="100%" alignItems="center">
            <IconButton color="inherit" onClick={() => setIsDrawerOpen(!isDrawerOpen)}>
              <MenuIcon />
            </IconButton>
            <img src="/images/logoHeader.png" alt="logo" style={{ height: 40, width: 40, marginRight: '1%', marginLeft: '1%' }} />
            <Typography variant="h6" color="inherit" noWrap>
              Redhire
            </Typography>
          </Box>
          <Box display="flex" alignItems="center">
            <img src={url} alt="Display Pic" style={{ borderRadius: "50%", height: '30px', width: '30px' }} referrerPolicy="no-referrer" />
            <Typography variant="body1" color="inherit" noWrap sx={{ ml: 1 }}>
              {username}
            </Typography>
            <Divider orientation="vertical" flexItem sx={{ ml: 1, mr: 1 }} />
            <Button color="menuItem" size="medium" onClick={googleSignout}>
              <ExitToAppIcon />
            </Button>
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" anchor='left' open={isDrawerOpen} onClose={() => setIsDrawerOpen(false)}>
        <DrawerHeader/>
        <Box width="200px">
          <List>
            {
              sidebarItem.map((item, index) => (
                <ListItem disablePadding id={item.name} className={item.name} key={item.name}>
                  <ListItemButton 
                    sx={{
                      minHeight: 70,
                      justifyContent: isDrawerOpen ? 'initial' : 'center',
                      px: 2.5,
                    }} 
                    onClick={(e) => {
                      navigate(item.path, {
                        state: {
                          name: username,
                          displayPic: url
                        }
                      })
                    }} 
                    className={item.name} 
                    id={item.name}
                  >
                    <ListItemIcon sx={{
                      minWidth: 0,
                      mr: isDrawerOpen ? 3 : 4,
                      justifyContent: 'center',
                      flexDirection: "column",
                    }}>
                      {item.icon}
                      <Box width={"65px"} sx={{ml: -2.5}}>
                        <Typography fontWeight={500} sx={sidebarIconStyle} variant="caption">{item.first_line ?? item.name}</Typography>
                        <Typography fontWeight={500} sx={sidebarIconStyle} variant="caption">{item.second_line}</Typography>
                      </Box>
                    </ListItemIcon>
                    <ListItemText sx={{ p: 1, ml: -3}} primary={item.name} />
                  </ListItemButton>
                </ListItem>
              ))
            }
          </List>
        </Box>
      </Drawer>
    </>
  );
}

export default MenuTab;
