const docCheckMY = {
    document: { 
        "NRIC (ID)": false ,
        "Passport": false ,
        "Local ID": false ,
    },
    experience: {
        "Attestation card for airline crew": false,
        "Copy of latest education certificate for initial": false,
        "Full body photo": false,
        "Close up photo (portrait shot)":false,
        "Latest CV": false,
        "Airline certificate": false
    }
}

const docCheckIDN = {
    document: { 
        "Copy of Passport": false ,
        "ID / KTP": false ,
        "Copy of latest education certificate for initial": false ,
        "Full body photo": false ,
        "Close up photo (portrait shot)": false ,
        "CV": false ,
        "3rd doses of Covid Vaccination": false,
    },
    experience: {
        "Copy of FA Certificate": false ,
        "Copy of reference letter from previous company": false ,
        "Copy of latest education certificate for initial": false ,
        "Full body photo": false ,
        "Close up photo (portrait shot)": false ,
        "CV": false ,
        "Copy of passport": false ,
        "3rd doses of Covid Vaccination": false,
    }
}

const docCheckPH = {
    document: { 
        "Passport": false ,
        "National IDs/Governement IDs": false ,
    },
    experience: {
        "Passport": false ,
        "National IDs/Governement-issued IDs": false ,
    }
}
const docCheckTHA = {
    document: { 
        "Copy of Passport": false ,
        "Copy of ID Card": false ,
        "Copy of Hourse Registration": false ,
        "TOEIC Score/Certificate (650 points)": false ,
        "For Male: Military Service Certificate": false ,
        "Chinese-speaking: HSK Certificate": false ,
        "Full body photo": false ,
        "Passport size photo": false ,
        "CV": false ,
        "Transcript in English version": false ,
    },
    experience: {
        "Ex-crew must show certificate or crew ID from previous employer": false ,
        "Same requirements as that of the above": false ,
    }
}

export {
    docCheckMY,
    docCheckIDN,
    docCheckPH,
    docCheckTHA
}