import { TabContext, TabPanel } from '@mui/lab'
import { Box, Tab, Tabs } from '@mui/material'
import React, { useState } from 'react'
import DashboardCalendar from './DashboardCalendar'
import DashboardMainLayout from '../layout/DashboardMainLayout'

export default function DashboardMainInformation(props) {
    const {user} = props
    const [tabIndex, setTabIndex] = useState("1")

    const handleChangeEditTabs = (e, activeTab) => {
        setTabIndex(activeTab)
    }
    
    return (
        <DashboardMainLayout>
            <TabContext value={tabIndex}>
                <Box sx={{ width: "100%", pl: 1, boxShadow: "0px 4px 4px 0px #00000014", backgroundColor: "#FFFFFF", minHeight: "50px", position: "relative" }}>
                    <Box sx={{ bottom: "0px", position: "absolute" }}>
                        <Tabs orientation='horizontal' value={tabIndex} onChange={handleChangeEditTabs}>
                            <Tab disableRipple label={"AAX"} value="1" />
                            <Tab disableRipple label={"IAA"} value="2" />
                            <Tab disableRipple label={"TAA"} value="3" />
                            <Tab disableRipple label={"TAX"} value="4" />
                            <Tab disableRipple label={"PAA"} value="5" />
                            <Tab disableRipple label={"MAA"} value="6" />
                            <Tab disableRipple label={"CAM"} value="7" />
                        </Tabs>
                    </Box>
                </Box>
                <Box sx={{ width: "100%" }}>
                    <TabPanel value='1'>
                        <DashboardCalendar user={user} companyName={"AirAsia X Berhad"} companyCode={"AAX"} />
                    </TabPanel>
                    <TabPanel value='2'>
                        <DashboardCalendar user={user} companyName={"PT. INDONESIA AIRASIA"} companyCode={"IAA"} />
                    </TabPanel>
                    <TabPanel value='3'>
                        <DashboardCalendar user={user} companyName={"Thai AirAsia Co., Ltd"} companyCode={"TAA"} />
                    </TabPanel>
                    <TabPanel value='4'>
                        <DashboardCalendar user={user} companyName={"Thai AirAsia X Co Ltd"} companyCode={"TAX"} />
                    </TabPanel>
                    <TabPanel value='5'>
                        <DashboardCalendar user={user} companyName={"Philippines AirAsia Inc."} companyCode={"PAA"} />
                    </TabPanel>
                    <TabPanel value='6'>
                        <DashboardCalendar user={user} companyName={"AirAsia Berhad"} companyCode={"MAA"} />
                    </TabPanel>
                    <TabPanel value='7'>
                        <DashboardCalendar user={user} companyName={"AirAsia (Cambodia) Co., Ltd."} companyCode={"CAM"} />
                    </TabPanel>
                </Box>
            </TabContext>
        </DashboardMainLayout>
    )
}
