import { Box, Button, CircularProgress, Stack } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import RenderCellExpand from "../../utils/RenderCellExpand";
import {
    downloadEventCandidateAttachment,
    getAllEventCandidateFileList,
} from "../../lib/db_candidate";
import DownloadIcon from "@mui/icons-material/Download";

export default function CandidateAttachments({ candidateId }) {
    const [attachments, setAttachments] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const getCandidateAttachment = async () => {
            setIsLoading(true);
            const res = await getAllEventCandidateFileList(candidateId);
            res.forEach((att, index) => {
                att.number = index + 1;
            });
            setAttachments(res);
            setIsLoading(false);
        };
        getCandidateAttachment();
    }, [candidateId]);

    const handleSelectionChange = (selection) => {
        const index = attachments.findIndex((object) => {
            return object.id === selection[0];
        });
        setSelectedRows(attachments[index]);
        console.log(selectedRows);
    };

    const DownloadButtons = (props) => {
        const handleClose = async (e) => {
            await downloadEventCandidateAttachment(
                props.row.eventId,
                candidateId,
                props.row.id
            );
        };
        return (
            <Button
                disableRipple
                variant="text"
                onClick={handleClose}
                sx={{ color: "black", textDecoration: "none" }}
            >
                <DownloadIcon />
            </Button>
        );
    };

    const columnsAttachment = [
        {
            field: "number",
            headerClassName: "theme--header",
            headerName: "No",
            maxWidth: 30,
            filterable: false,
            headerAlign: "center",
        },
        {
            field: "uploaded_at",
            headerClassName: "theme--header",
            headerName: "Upload Date",
            minWidth: 100,
            flex: 1,
            headerAlign: "center",
        },
        {
            field: "file_name",
            headerClassName: "theme--header",
            headerName: "Document Name",
            flex: 3,
            renderCell: RenderCellExpand,
            minWidth: 100,
        },
        {
            field: "description",
            headerClassName: "theme--header",
            headerName: "Description",
            renderCell: RenderCellExpand,
            flex: 3,
            minWidth: "fit-content",
        },
        {
            field: "download",
            headerClassName: "theme--header",
            headerName: "Download",
            filterable: false,
            disableClickEventBubbling: true,
            flex: 1,
            minWidth: 100,
            renderCell: DownloadButtons,
            align: "center",
        },
    ];

    return (
        <>
            <Box
                sx={{
                    height: "auto",
                    width: "100%",
                    "& .theme--header": {
                        backgroundColor: "#F1F1F1",
                        opacity: 0.5,
                    },
                    m: -1,
                    left: 0,
                    mt: "-20px",
                    right: 0,
                }}
            >
                <div style={{ flexGrow: 1 }}>
                    <DataGrid
                        sx={{
                            "& .MuiDataGrid-columnHeader": {
                                // Forced to use important since overriding inline styles
                                height: "unset !important",
                            },
                            "& .MuiDataGrid-columnHeaders": {
                                // Forced to use important since overriding inline styles
                                maxHeight: "168px !important",
                            },
                            ".MuiDataGrid-columnSeparator": {
                                display: "none",
                            },
                            "& .MuiDataGrid-cell": {
                                border: 1,
                                borderColor: "#D9DBE0",
                            },
                        }}
                        rows={attachments}
                        columns={columnsAttachment}
                        autoHeight={true}
                        onRowSelectionModelChange={handleSelectionChange}
                        getRowClassName={(params) => {
                            return typeof params.row !== "object"
                                ? ""
                                : "theme--rows";
                        }}
                        components={{
                            NoRowsOverlay: () => (
                                <>
                                    <Stack
                                        height="100%"
                                        alignItems="center"
                                        justifyContent="center"
                                    >
                                        {isLoading ? (
                                            <>
                                                <p>Getting Attachments</p>
                                                <CircularProgress />
                                            </>
                                        ) : (
                                            "No Attachments Available"
                                        )}
                                    </Stack>
                                </>
                            ),
                        }}
                        disableColumnMenu
                    />
                </div>
            </Box>
        </>
    );
}
