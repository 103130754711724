import React, { useEffect, useState } from 'react'
import MenuTab from '../menu/MenuTab'
import { Box, Container, useTheme } from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom'
import useAppBarHeight from '../../utils/appBarHeight'
import Footer from '../menu/Footer'
import DashboardSideSearch from './components/DashboardSideSearch'
import DashboardMainInformation from './components/DashboardMainInformation'
import { authenticateUser } from '../../lib/userAuthenticate'

export default function DashboardPage() {
    const navigate = useNavigate()
    const location = useLocation()
    const [user, setUser] = useState({
        username: "",
        url: ""
    })

    useEffect(() => {
        authenticateUser(setUser, location, navigate)
    }, [location, navigate])

    const theme = useTheme();
    const appBarHeight = useAppBarHeight()
    return (
        <div style={{ minHeight: "100vh" }}>
            <MenuTab username={user.username} url={user.url} />
            <Container disableGutters maxWidth="100%" component="main" sx={{ pl: `calc(${theme.spacing(7)} + 5px)`, marginBottom: "56px" }}>
                <Box display={"flex"}>
                    <DashboardSideSearch appBarHeight={appBarHeight} user={user}/>
                    <DashboardMainInformation appBarHeight={appBarHeight} user={user}/>
                </Box>
            </Container>
            <Footer />
        </div>
    )
}
