import React, { useEffect, useState } from "react";
import { getBlacklistedAndKivCandidates } from "../../../lib/db_event";
import { Box, CircularProgress, Stack, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

export default function BlacklistKIV({ eventId, tab }) {
  const [rows, setRows] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    const getCandidates = async () => {
      setIsLoading(true);
      const res = await getBlacklistedAndKivCandidates(eventId);
      if (tab === "KIV") {
        res.iskivs.forEach((item) => {
          let identity;
          if (item.national_id.length > 0) {
            identity = item.national_id;
          } else {
            identity = item.passport;
          }
          item.natId = item.nationality.concat(",", identity);
          item.ageGender = `${item.age},${item.gender}`;
          item.name = `${item.first_name},${item.last_name}`;
        });
        setRows(res.iskivs);
      } else if (tab === "Blacklist") {
        res.blacklisted.forEach((item) => {
          let identity;
          if (item.national_id.length > 0) {
            identity = item.national_id;
          } else {
            identity = item.passport;
          }
          item.natId = item.nationality.concat(",", identity);
          item.ageGender = `${item.age},${item.gender}`;
          item.name = `${item.first_name},${item.last_name}`;
        });
        setRows(res.blacklisted);
      }
      setIsLoading(false);
    };
    getCandidates();
  }, [eventId, tab]);

  const renderCellBlacklistKIV = (props) => {
    let stringMain;
    let stringCaption;
    if (props.value.includes(",")) {
      const splitString = props.value.split(",");
      stringMain = splitString[0];
      stringCaption = splitString[1];
    } else {
      stringMain = props.value;
    }
    return (
      <>
        <Typography variant="body2">
          {stringMain}
          <br />
          <Typography variant="caption">{stringCaption}</Typography>
        </Typography>
      </>
    );
  };

  const columnsCandidate = [
    {
      field: "name",
      headerClassName: "theme--header",
      headerName: "Name",
      minWidth: 100,
      flex: 1,
      filterable: false,
      headerAlign: "center",
      renderCell: renderCellBlacklistKIV,
    },
    {
      field: "natId",
      headerClassName: "theme--header",
      renderHeader: (params) => (
        <>
          <Typography variant="body2">
            Nationality
            <br />
            <Typography variant="caption">National ID/Passport</Typography>
          </Typography>
        </>
      ),
      minWidth: 100,
      flex: 1,
      headerAlign: "center",
      renderCell: renderCellBlacklistKIV,
      align: "center",
    },
    {
      field: "ageGender",
      flex: 1,
      headerClassName: "theme--header",
      renderHeader: (params) => (
        <>
          <Typography variant="body2">
            Age
            <br />
            <Typography variant="caption">Gender</Typography>
          </Typography>
        </>
      ),
      minWidth: 100,
      headerAlign: "center",
      renderCell: renderCellBlacklistKIV,
      align: "center",
    },
    {
      field: "reason",
      headerClassName: "theme--header",
      headerName: "Reasons",
      minWidth: 100,
      filterable: false,
      flex: 1,
      headerAlign: "center",
      disableClickEventBubbling: true,
      align: "center",
    },
  ];
  return (
    <>
      <Box
        sx={{
          height: "auto",
          width: "100%",
          "& .theme--header": {
            backgroundColor: "white",
            marginBottom: "8px",
            marginTop: "8px"
          },
          "& .theme--rows": {
            backgroundColor: "white",
          },
        }}
      >
        <div style={{ flexGrow: 1 }}>
          <DataGrid
            sx={{
              "& .MuiDataGrid-columnHeader": {
                // Forced to use important since overriding inline styles
                height: "unset !important",
              },
              "& .MuiDataGrid-columnHeaders": {
                // Forced to use important since overriding inline styles
                maxHeight: "168px !important",
              },
              ".MuiDataGrid-columnSeparator": {
                display: "none",
              },
              "& .MuiDataGrid-cell": {
                backgroundColor: "white",
              },
              border: 0,
            }}
            rows={rows}
            columns={columnsCandidate}
            autoHeight={true}
            componentsProps={{
              panel: {
                placement: "bottom-end",
              },
            }}
            getRowClassName={(params) => {
              return typeof params.row !== "object" ? "" : "theme--rows";
            }}
            initialState={{
              pagination: { paginationModel: { pageSize: 10 } },
            }}
            pageSizeOptions={[10, 25, 50]}
            disableColumnMenu
            hideFooter
            slots={{
              noRowsOverlay: () => (
                <>
                  <Stack
                    height="100%"
                    alignItems="center"
                    justifyContent="center"
                  >
                    {isLoading ? (
                      <>
                        <p>Getting Data</p>
                        <CircularProgress />
                      </>
                    ) : (
                      'No Data'
                    )}
                  </Stack>
                </>
              )
            }}
          />
        </div>
      </Box>
    </>
  );
}
