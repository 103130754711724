import { Box, CircularProgress, IconButton, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { getEventsFilterByMonths } from "../../../lib/db_event";
import { getMonthQuaterly } from "../../../utils/getShortMonth";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { useNavigate } from "react-router-dom";

const colour = [
  { font: "#1A5436", backgroundColor: "#ECFBF3", strip: "#1A5436", status: "past" },
  { font: "#3A3D7D", backgroundColor: "#EDEDF6", strip: "#3A3D7D", status: "current"  },
  { font: "#4C4C50", backgroundColor: "#EBEBEB", strip: "#75767A", status: "future"  },
];

const legendBox = {
  height: "10px",
  width: "16px",
  borderRadius: "4px",
};

const buttonStyle = {
  borderRadius: "50%",
  backgroundColor: "white",
  width: "24px",
  height: "24px",
  "& :hover": {
    color: "white",
  },
};
export default function DashboardCalendar(props) {
  const navigate = useNavigate();
  const { companyName, companyCode, user } = props;
  const [events, setEvents] = useState([]);
  const [months, setMonths] = useState([]);
  const [year, setYear] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [monthFactor, setMonthFactor] = useState(0);
  useEffect(() => {
    const getEventsByAOC = async () => {
      setIsLoading(true);
      const response = await getEventsFilterByMonths(companyCode);
      const getMonths = getMonthQuaterly(monthFactor);
      const existedEvent = response.filter((data) => {
        return data.events.length > 0;
      });
      setEvents(existedEvent);
      setMonths(getMonths.monthObjects);
      setYear(getMonths.uniqueYears);
      setIsLoading(false);
    };
    getEventsByAOC();
  }, [companyCode, monthFactor]);

  useEffect(()=>{
    const checkEvents = () => {
      months.forEach((data)=>{
        events.forEach((event)=> {
          if(
            event.short_month === data.shortName &&
            event.year === data.year
          ){
            setHasEvents(true)
          }
        })
      })
    }

    checkEvents()
  },[events, months])

  const [hasEvents, setHasEvents] = useState(false)

  const handleClickEventsBox = (e) => {
    navigate(`/dashboard/${e.currentTarget.id}`, {
      state: {
        name: user.username,
        displayPic: user.url,
      },
    });
  };

  const handleClickNavigateMonth = (e) => {
    setHasEvents(false)
    if (e.target.id === "prevMonth") {
      setMonthFactor((prevState) => prevState - 1);
    } else if (e.target.id === "nextMonth") {
      setMonthFactor((prevState) => prevState + 1);
    }
  };

  return (
    <>
      <Typography sx={{ color: "black", m: 0 }} variant="h6">
        <strong>{companyName}</strong>
      </Typography>
      <Typography sx={{ color: "black" }} variant="body2">
        {companyCode}
      </Typography>
      {!isLoading ? (
        <Box
          marginRight={3}
          marginTop={"24px"}
          minHeight={"100%"}
          sx={{ width: "100%", height: "100%", backgroundColor: "white" }}
        >
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            padding={1}
            sx={{
              width: "100%",
              backgroundColor: "#3A3D7D",
              borderRadius: "4px 4px 0px 0px",
            }}
          >
            {months.length > 0 && (
              <Typography pl={0.5} sx={{ color: "white" }}>
                {`${months[0].fullName} - ${months[2].fullName} ${year[0]}`}
              </Typography>
            )}
            <Box display={"flex"} gap={"6px"}>
              <IconButton
                variant="contained"
                sx={buttonStyle}
                id="prevMonth"
                onClick={handleClickNavigateMonth}
              >
                <Typography id="prevMonth" color={"#3A3D7D"}>
                  &lt;
                </Typography>
              </IconButton>
              <IconButton
                variant="contained"
                sx={buttonStyle}
                id="nextMonth"
                onClick={handleClickNavigateMonth}
              >
                <Typography id="nextMonth" color={"#3A3D7D"}>
                  &gt;
                </Typography>
              </IconButton>
            </Box>
          </Box>
          <Box>
            <Box p={0.5} display={"flex"}>
              {months.map((data, indexMonth) => {
                let fontColour
                let backgroundColor
                let strip 
                colour.forEach((item)=>{
                  if(data.status === item.status){
                    fontColour = item.font;
                    backgroundColor = item.backgroundColor;
                    strip = item.strip
                  }
                })
                return (
                  <Box key={indexMonth} flex={1} m={1} mt={0}>
                    <Typography>{data.shortName}</Typography>
                    <Box sx={{ overflowY: "auto" }} maxHeight={`60vh`}>
                      {events.map((event) => {
                        if (
                          event.short_month === data.shortName &&
                          event.year === data.year
                        ) {
                          return event.events.map((item, indexEvents) => (
                            <div
                              id={item.id}
                              style={{ cursor: "pointer" }}
                              onClick={handleClickEventsBox}
                              key={indexEvents}
                            >
                              <Box
                                mb={"8px"}
                                sx={{
                                  borderRadius: "4px",
                                  backgroundColor: backgroundColor,
                                  overflowY: "auto",
                                }}
                                maxHeight={`50vh`}
                                display={"flex"}
                              >
                                <Box width={"5px"} sx={{backgroundColor: strip}}></Box>
                                <Box margin={"16px 16px 16px 11px"}>
                                <Typography color={fontColour} variant="h6">
                                  {item.event_code}
                                </Typography>
                                <Typography
                                  color={fontColour}
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    flexWrap: "wrap",
                                    mt: 1,
                                  }}
                                  variant="body2"
                                >
                                  <CalendarTodayIcon
                                    sx={{ mr: 0.5 }}
                                    fontSize="small"
                                    />
                                  {item.event_date}
                                </Typography>
                                <Typography
                                  color={fontColour}
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    flexWrap: "wrap",
                                    mt: 0.5,
                                  }}
                                  variant="body2"
                                  >
                                  <LocationOnIcon
                                    sx={{ mr: 0.5 }}
                                    fontSize="small"
                                    />
                                  {item.event_venue}
                                </Typography>
                                <Typography
                                  color={fontColour}
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    flexWrap: "wrap",
                                    mt: 0.5,
                                  }}
                                  variant="body2"
                                  >
                                  <strong>JR</strong>&nbsp;&nbsp;
                                  {item.jr_number}
                                </Typography>
                                  </Box>
                              </Box>
                            </div>
                          ));
                        } else {
                          return null;
                        }
                      })}
                    </Box>
                  </Box>
                );
              })}
            </Box>
            {!hasEvents && (
              <img src="/images/noInfos.png" alt="No Infos" style={{ display: "block", marginLeft: "auto", marginRight: "auto", width: "287px", height: "226px"}}/>
            )}
            <Box
              display={"flex"}
              gap={"8px"}
              alignItems={"center"}
              sx={{ p: 1, marginLeft: "65%" }}
            >
              <Box sx={{ ...legendBox, backgroundColor: "#1A5436" }} />{" "}
              <Typography variant="caption">Past event</Typography>
              <Box sx={{ ...legendBox, backgroundColor: "#3A3D7D" }} />{" "}
              <Typography variant="caption">Current Event</Typography>{" "}
              <Box sx={{ ...legendBox, backgroundColor: "#D9D9D9" }} />
              <Typography variant="caption">Future event</Typography>
            </Box>
          </Box>
        </Box>
      ) : (
        <CircularProgress />
      )}
    </>
  );
}
