import { Box, CircularProgress, Divider, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import DashboardSideLayout from '../layout/DashboardSideLayout'
import { getEventById, searchEventByDate, searchEventByJrNumber } from '../../../lib/db_event'
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { useNavigate } from 'react-router-dom';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { findCommonObjects } from '../../../utils/commonObject';

export default function DashboardSideSearch(props) {
    const { user } = props
    const navigate = useNavigate()
    const [jrNumber, setJrNumber] = useState("")
    const [date, setDate] = useState("")
    const [events, setEvents] = useState([])
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        const fetchSearchResult = async () => {
            setIsLoading(true)
            let res
            const resJr = await searchEventByJrNumber(jrNumber);

            if (date.length > 0) {
                const resDate = await searchEventByDate(date);
                const eventArray = await Promise.all(
                    resDate.map(async (id) => {
                       const events = await getEventById(id)
                       events.id = id
                       return events
                    })
                );
                if (jrNumber.length > 0) {
                    res = findCommonObjects(resJr, eventArray);
                } else {
                    res = eventArray;
                }
            } else {
                res = resJr;
            }
            setEvents(res)
            setIsLoading(false)
        }
        if (jrNumber.length > 0 || date.length > 0) fetchSearchResult()
    }, [jrNumber, date])

    const handleOnChange = (e) => {
        if (e.target.value.length === 0) setEvents([])
        setJrNumber(e.target.value.toUpperCase())
    }

    const handleOnChangeDate = async (e) => {
        let days
        let months
        if (e !== null && !isNaN(e.$D) && !isNaN(e.$M) && !isNaN(e.$y) && !isNaN(e.$H) && !isNaN(e.$m)) {
            e.$D < 10 ? days = `0${e.$D}` : days = e.$D
            e.$M < 9 ? months = `0${e.$M + 1}` : months = e.$M + 1
            setDate(`${days}/${months}/${e.$y}`)
        } else {
            return
        }
    }

    const handleClickEventsBox = (e) => {
        navigate(`/dashboard/${e.currentTarget.id}`, {
            state: {
                name: user.username,
                displayPic: user.url,
            }
        })
    }

    return (
        <DashboardSideLayout>
            <Typography sx={{ color: "#4B4FA6" }} variant='h5'><strong>Hi There!</strong></Typography>
            <Typography fontWeight={500} fontSize={"12px"} sx={{ color: "var(--Neutral-Black, #212124)"}} variant='caption'>Begin by navigating through these AOCs <br/></Typography>
            <Typography fontWeight={500} fontSize={"12px"} sx={{ color: "var(--Neutral-Black, #212124)"}} variant='caption'>Search by JR number</Typography>
            <TextField onChange={handleOnChange} value={jrNumber} sx={{ width: "100%" }} size='small' label={"JR number"} />
            <Typography fontWeight={500} fontSize={"12px"} sx={{ color: "var(--Neutral-Black, #212124)"}} variant='caption'>or search through date</Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker onChange={handleOnChangeDate} format='DD/MM/YYYY' sx={{ width: "100%" }} slotProps={{ textField: { size: "small" } }} />
            </LocalizationProvider>
            <Divider sx={{ borderBottomWidth: 2, mt: "16px" }} variant='fullWidth' />
            <Box p={1} display={'flex'} sx={{ overflowY: "auto" }} maxHeight={`60vh`}>
                {!isLoading ? <Box flex={1}>
                    {events.length > 0 && <Typography variant='caption'>Search result</Typography>}
                    {events.map((event, index) => (
                        <div key={index} id={event.id} style={{ cursor: "pointer" }} onClick={handleClickEventsBox}>
                            <Box mb={1} p={1} pl={2} sx={{ borderRadius: "4px", backgroundColor: "#FFFFFF", boxShadow: "0px 1px 4px 0px #00000029", }}>
                                <Typography color={"#1A5436"} variant='h6'>{event.event_code}</Typography>
                                <Typography color={"#1A5436"} sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', mt: 1 }} variant='body2'><CalendarTodayIcon sx={{ mr: 0.5 }} fontSize='small' />{event.event_date}</Typography>
                                <Typography color={"#1A5436"} sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', mt: 0.5 }} variant='body2'><LocationOnIcon sx={{ mr: 0.5 }} fontSize='small' />{event.event_venue}</Typography>
                                <Typography color={"#1A5436"} sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', mt: 0.5 }} variant='body2'><strong>JR</strong>&nbsp;&nbsp;{event.jr_number}</Typography>
                            </Box>
                        </div>
                    ))}
                </Box> : <CircularProgress />}

            </Box>
        </DashboardSideLayout>
    )
}
