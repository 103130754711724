import React, { useEffect, useState } from 'react';
import {
    Checkbox,
    FormControl,
    FormControlLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
} from "@mui/material";
import { getCandidateAssessmentResultDocumentCheck } from "../../lib/db_candidate";
import DocumentCheckbox from './AssessmentCheckbox';
import { setCandidateEventAssessmentComment, setCandidateEventAssessmentDocumentCheck } from '../../lib/page';
import getTime from '../../utils/getTime';
import ClearConfirmation from './ClearConfirmation';
import updateDocument from './DocumentCheck/DocCheckSetter';
import { AssessmentButtonAction, AssessmentLayout, AssessmentSection } from '../layout/AssessmentLayout';
import SuccessMsg from '../SuccessMsg';

const INITIAL_PAYLOAD = {
    overall_score: {
        status: '',
        comment: ''
    }
}

function DocumentCheck({ candidateId, eventId, username, isComment, isCompleted = false, docCheck, country }) {
    const [isCancelDialogOpen, setIsCancelDialogOpen] = useState(false)
    const [document, setDocument] = useState(docCheck.document)
    const [expDocument, setExpDocument] = useState(docCheck.experience)
    const [expatDocument, setExpatDocument] = useState(false)
    const [foreignLanguage, setForeignLanguage] = useState(false);
    const [certificates, setCertificates] = useState("")
    const [payload, setPayload] = useState(INITIAL_PAYLOAD)
    const [errorForm, setErrorForm] = useState({
        commentError: {
            msg: "",
            bool: false,
        },
        overallScoreError: {
            msg: "",
            bool: false
        }
    })
    const [formError, setFormError] = useState(false)
    const [isShowPopup, setIsShowPopup] = useState(false)

    useEffect(() => {
        const resetState = () => {
            setDocument(docCheck.document)
            setExpDocument(docCheck.experience)
            setCertificates("")
            setExpatDocument(false)
            setForeignLanguage(false)
            setPayload(INITIAL_PAYLOAD)
        }
        async function getResult() {
            const res = await getCandidateAssessmentResultDocumentCheck(
                candidateId,
                eventId
            );
            if (res !== undefined) {
                const document = updateDocument(country, res, "Document")
                const experience = updateDocument(country, res, "Experience")
                setDocument(document)
                setExpDocument(experience)
                if (res.required_document_for_expat) {
                    setExpatDocument(res.required_document_for_expat.pertinent_document)
                }
                if ( res.required_document_for_foreign_language_speakers?.has_relevant_certificates ) {
                    setForeignLanguage(res.required_document_for_foreign_language_speakers?.has_relevant_certificates ?? false)
                }
                if ( res.required_document_for_foreign_language_speakers?.comment ) {
                    setCertificates(res.required_document_for_foreign_language_speakers?.comment ?? "")
                }
                setPayload({
                    overall_score: {
                        status: res.overall_score.status,
                        comment: res.overall_score.comment
                    }
                })
            } else {
                resetState()
            }
        }
        if (candidateId !== undefined) {
            getResult();
        } else {
            resetState()
        }
    }, [candidateId, country, docCheck.document, docCheck.experience, eventId]);

    function handleChange(event) {
        setDocument({
            ...document,
            [event.target.name]: event.target.checked
        })
    }
    function handleExpChange(event) {
        setExpDocument({
            ...expDocument,
            [event.target.name]: event.target.checked
        })
    }

    function handleChangeExpat(event) {
        setExpatDocument(event.target.checked)
    }

    function handleChangeForeignLanguage(event) {
        setForeignLanguage(event.target.checked)
        if ( !event.target.checked ) {
            setCertificates("");
        }
    }

    function handleChangeCertificates(event) {
        setCertificates(event.target.value)
    }

    function handleScore(event) {
        var comment = payload.overall_score.comment
        if (event.target.value === "Passed") {
            comment = ""
        }
        setPayload({
            overall_score: {
                comment: comment,
                status: event.target.value
            }
        })
        if(event.target.value.length > 0){
            setErrorForm({
                ...errorForm,
                overallScoreError: {
                    bool: false,
                    msg: ""
                }
            })
        }
    }
    function handleComment(event) {
        setPayload({
            overall_score: {
                status: payload.overall_score.status,
                comment: event.target.value
            }
        })

        if (event.target.value.length !== 0) {
            setErrorForm({
                ...errorForm,
                commentError: {
                    msg: "",
                    bool: false
                }
            })
        }
    }
    const handleClickClear = () => {
        setIsCancelDialogOpen(!isCancelDialogOpen)
    }

    function handleClear(event) {
        setDocument(docCheck.document)
        setExpDocument(docCheck.experience)
        setExpatDocument(false)
        setForeignLanguage(false);
        setCertificates("");
        setPayload(INITIAL_PAYLOAD)
    }
    async function handleSubmit(event) {
        event.preventDefault()
        if (candidateId === undefined) {
            setFormError(true)
            setIsShowPopup(true)
            return
        }
        if(payload.overall_score.status.length === 0) {
            setErrorForm({
                ...errorForm,
                overallScoreError: {
                    bool: true,
                    msg: "Overall Score Required"
                }
            })
            setFormError(true)
            setIsShowPopup(true)
            return
        }
        if (payload.overall_score.status === "Failed" && payload.overall_score.comment.length === 0) {
            setErrorForm({
                ...errorForm,
                commentError: {
                    msg: "Missing Remarks",
                    bool: true
                }
            })
            setFormError(true)
            setIsShowPopup(true)
        } else {
            const form = {
                required_document: document,
                required_document_for_experienced: expDocument,
                required_document_for_expat: {
                    pertinent_document: expatDocument
                },
                required_document_for_foreign_language_speakers: {
                    has_relevant_certificates: foreignLanguage,
                    comment: foreignLanguage ? certificates : ""
                },
                overall_score: {
                    status: payload.overall_score.status,
                    comment: payload.overall_score.comment
                }
            }

            let commentsArr = [];
            if ( payload.overall_score.comment && payload.overall_score.comment !== "" ) {
                commentsArr.push(payload.overall_score.comment)
            }

            if ( foreignLanguage && certificates && certificates !== "" ) {
                commentsArr.push(`Licences/Certificates: ${certificates}`)
            }

            const commentString = commentsArr.join(", ")

            const commentForm = {
                id: new Date().getTime(),
                comment: commentString,
                module: "Document Check",
                overall_score: payload.overall_score.status,
                created_by: username,
                created_at: getTime()
            }
            try {
                await setCandidateEventAssessmentComment(candidateId, eventId, commentForm)
                await setCandidateEventAssessmentDocumentCheck(candidateId, eventId, form)
                isComment()
                setFormError(false)
                setIsShowPopup(true)
            } catch (error) {
                alert(`Error saving Document Check: ${error}`)
            }
        }
    }

    return (
        <>
            <AssessmentLayout title={"Document Check"}>
                <AssessmentSection>
                    <Typography>Required Documents</Typography>
                    <DocumentCheckbox handleChange={handleChange} collection={document} disabled={isCompleted} />
                </AssessmentSection>
                <AssessmentSection>
                    <Typography>Required Documents for Experienced candidates</Typography>
                    <DocumentCheckbox handleChange={handleExpChange} collection={expDocument} disabled={isCompleted} />
                </AssessmentSection>
                <AssessmentSection>
                    <Typography>For Expat</Typography>
                    <FormControlLabel
                        label={<Typography color={"#75767A"}>All Pertinent Document</Typography>}
                        control={
                            <Checkbox
                                key={"All Pertinent Document"}
                                checked={expatDocument}
                                onChange={handleChangeExpat}
                                name={"All Pertinent Document"}
                                style={{ display: 'flex', flexDirection: 'row', justifyContent: "space-around" }}
                                disabled={isCompleted}
                            />
                        }
                        sx={{ minWidth: 500 }}
                    />
                </AssessmentSection>
                <AssessmentSection>
                    <Typography>For Foreign Language Speakers</Typography>
                    <FormControlLabel
                        label={<Typography color={"#75767A"}>Relevant licenses and certification</Typography>}
                        control={
                            <Checkbox
                                key={"Relevant licenses and certification"}
                                checked={foreignLanguage}
                                onChange={handleChangeForeignLanguage}
                                name={"Relevant licenses and certification"}
                                style={{ display: 'flex', flexDirection: 'row', justifyContent: "space-around" }}
                                disabled={isCompleted}
                            />
                        }
                        sx={{ minWidth: 500 }}
                    />
                    { foreignLanguage && (
                        <TextField
                            disabled={isCompleted}
                            onChange={handleChangeCertificates}
                            value={certificates}
                            size="small"
                            label="Relevant licenses and certification"
                            style={{ width: "100%" }}
                        />
                    )}
                </AssessmentSection>
                <AssessmentSection>
                    <Typography>Overall Score</Typography>
                    <FormControl sx={{ minWidth: 250 }}>
                        <Select
                            disabled={isCompleted}
                            name='score'
                            value={payload.overall_score.status}
                            onChange={handleScore}
                            error={errorForm.overallScoreError.bool}
                        >
                            <MenuItem value={""}>-</MenuItem>
                            <MenuItem value={"Passed"}>Passed</MenuItem>
                            <MenuItem value={"KIV"}>KIV</MenuItem>
                            <MenuItem value={"Failed"}>Failed</MenuItem>
                        </Select>
                        {errorForm.overallScoreError.bool ? <small style={{color: "red"}}>{errorForm.overallScoreError.msg}</small> : ""}
                    </FormControl>
                    <FormControl variant="standard" sx={{ width: "100%", pt: 1 }}>
                        {((payload.overall_score.status === 'Failed' || payload.overall_score.status === 'KIV') && !isCompleted) && <TextField
                            required
                            onChange={handleComment}
                            value={payload.overall_score.comment}
                            size="small"
                            id="comment"
                            name="comment"
                            label="Remarks"
                            error={errorForm.commentError.bool}
                            helperText={errorForm.commentError.msg}
                        />}
                    </FormControl>
                </AssessmentSection>
            </AssessmentLayout>
            {!isCompleted && (
                <AssessmentButtonAction saveAction={handleSubmit} secondButton={"Clear"} secondAction={handleClickClear} isLoading={false} />
            )}
            <ClearConfirmation isDialogOpen={isCancelDialogOpen} handleCloseDialog={() => setIsCancelDialogOpen(false)} clear={handleClear} />
            <SuccessMsg isDialogOpen={isShowPopup} handleCloseDialog={() => setIsShowPopup(false)} title={formError ? "Error" : "Success"} message={candidateId === undefined ? "Please select candidate" : formError ? "Form is incomplete" : "Success on saving candidate assessment"}/>
        </>
    );
}

export default DocumentCheck;
