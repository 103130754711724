import { Button, Container } from "@mui/material";
import React from 'react';
import {
    getAllCandidate,
    getAllCandidateJobApplication,
    getCandidateById,
    getJrNumberListFromCandidate,
    updateCandidateBlacklistStatus
} from '../lib/db_candidate.js';
import {
    addCandidateToEvent,
    addEvent,
    assignCandidateNumberToEventCandidate,
    cancelEventById,
    getAllEvent,
    getAllJrNumberDetails,
    getEmailSentList,
    getEventById,
    getEventCandidateIds,
    getEventEmailTemplateById,
    getEventEmailTemplateFinalStatus,
    setAttachmentDetails,
    setEmailSent,
    setEventEmailTemplateById,
    updateEventEmailTemplateFinalStatus
} from '../lib/db_event.js';
import {
    autoCreateCandidateJobApplication,
    getCandidateEventAssessmentCatwalk,
    getCandidateEventAssessmentComment,
    getCandidateEventAssessmentDocumentCheck,
    getCandidateEventAssessmentEnglishExam,
    getCandidateEventAssessmentGroupDynamic,
    getCandidateEventAssessmentMeasurement,
    getCandidateEventAssessmentOneToOneInterview,
    getCandidateEventAssessmentOverall,
    getCandidateEventAttended,
    getEventDetailsPageInfoByEvenId,
    overwriteCandidateBlacklistStatus,
    overwriteCandidateCoolingPeriod,
    setCandidateEventAssessmentCatwalk,
    setCandidateEventAssessmentComment,
    setCandidateEventAssessmentDocumentCheck,
    setCandidateEventAssessmentMeasurement,
    setCandidateEventAssessmentOneToOneInterview,
    triggerAutoSendInvitationEmail,
    uploadAssessmentResultEnglishExam,
    uploadAssessmentResultGroupDynamic,
} from '../lib/page.js';

import {
    addToWorkday,
    sendEmail
} from "../lib/cloud_function.js";

import {
    generateAcceptInvitationToken,
    generateSendEmailToken,
    getInvitationTokenDetails,
    getSendEmailDetails,
    setInvitationTokenToHasProcessed,
    setTokenToHasProcessed
} from "../lib/db_token";

function TestPage(){
    const eventInfo = {
        event_aoc : "MAA",
        event_assessment_module : [
            'Measurement', 
            'Document Check', 
            'Catwalk', 
            'English Examinations', 
            'Group Dynamic', 
            '1:1 Interview'
        ],
        event_date : "2023-05-31",
        event_dress_code : "Formal dress",
        event_name : "Event20230530",
        event_parking_details : "123",
        event_venue : "RedQ",
        event_registration_time : "0830",
        event_status : "Active",
        event_time : "0900",
        jr_number : "JR134",
        posting_date : "2023-05-29"
    };

    const documentCheckResult = {
        required_document: {
            nric: true,
            passport: true,
            local_id: true
        },
        required_document_for_experienced: {
            attestation_card: true,
            education_certificate: true,
            full_body_photo: true,
            portrait_photo: true,
            cv: true,
            airline_certificate: true
        },
        overall_score: {
            status: 'Passed',
        }
    };

    const measurementResult = {
        height: "172",
        weight: "72",
        arm_reached_above_210cm: true,
        overall_score: {
            status: "Passed",
            comment: ""
        }
    }

    const catwalkResult = {
        upper_body: {
            label: "hair/head/face/nose/ears/teeth/mouth",
            acceptable: true,
            comment: "Beatiful face"
        },
        middle_body:{
            label: "torso/hands/arms/shoulders",
            acceptable: true,
            comment: "low body fat, high muscle mass."
        },
        lower_body:{
            label: "hips/legs/knee",
            acceptable: true,
            comment: "Slim leg"
        },
        overall_body:{
            label: "Overall body",
            acceptable: true,
            comment: "Pretty"
        },
        overall_score: 'A'
    }

    const oneToOneInterviewResult = {
        personality: "5",
        communication: "Proficient",
        overall_performance: "Describe overall performance of a candidate",
        overall_score: "Passed"
    }

    const assessmentComment = {
        comment: "",
        module: "English Test", // English Test | 1-1 Interview | Catwalk | Document Check | Group Dynamic | Measurement | General
        overall_score: "Passed",
        created_by: "Edison Choong",
        created_at: new Date().getTime()
    }

    const assessmentResultEnglishExamination = `EVENT ID,CANDIDATE NO.,CANDIDATE SCORE,PASSING SCORE,TOTAL SCORE,STATUS,COMMENTS
    A01,0001,16,20,25,Failed,
    A01,0002,16,20,25,Failed,Pass TOEIC english exam
    A01,0049,22,20,25,Passed,`

    const assessmentResultGroupDynamic = `EVENT ID,CANDIDATE NO.,P1 windsorguadalupejr@airasia.com,P2 BECCA,P2 CECI,HANNAH - 4,RA - 5,TOTAL POINTS,PASSING SCORE,REMARKS - P1,REMARKS - 2,REMARKS - 3,REMARKS - 4,REMARKS - 5
    A01,0049,1,1,,,1,3,3,Very good communication skills,Very good projection,Ok,Ok,Ok
    A01,0002,,,1,,,1,3,,,,,`

    const emailTemplate = {
        image_url: 'image',
        email_content: 'content'
    }

    const walkinCandidate = {}
    walkinCandidate['first_name'] = 'Chern Shien';
    walkinCandidate['last_name'] = 'Choong';
    walkinCandidate['phone_number'] = '0163375500';
    walkinCandidate['email'] = 'edison.choong@airasia.com';
    walkinCandidate['linkin_url_profile'] = '';
    walkinCandidate['city'] = 'Dengkil';
    walkinCandidate['country'] = 'Malaysia';
    walkinCandidate['gender'] = 'Male';
    walkinCandidate['national_id'] = '11223344';
    walkinCandidate['nationality'] = 'Malaysian';
    walkinCandidate['dob'] = '10/21/1984';
    walkinCandidate['how_you_heard_about_this_job'] = 'Career AirAsia';
    walkinCandidate['have_you_worked_at_airasia_before'] = 'Yes';
    walkinCandidate['language_spoken'] = [];
    walkinCandidate['language_spoken'] = [{
        language: 'English',
        language_ability_type: 'Speaking',
        language_proficiency: 'Intermediate'
    }];
    walkinCandidate['work_experience'] = [];
    walkinCandidate['work_experience'].push({
        company_name: 'airasia',
        job_title: 'Senior Software Engineer',
        from: '16/12/2013',
        to: '14/07/2023',
        location: 'Malaysia',
        role_description: 'airasia-incredible-dashboard'
    });

    const attachmentDetails = []
    attachmentDetails.push({
        url: "http://localhost/",
        file_name: "file.type",
        description: "Hello world"
    })

    const candidateBlacklistDetails = {
        is_blacklist: true,
        comment: "Breach of conduct code in previous company"
    }

    /*
    const coolingPeriodDetails = {
        cooling_period: "",
        comment: "Set empty to remove cooling period"
    }
    */
    
    const coolingPeriodDetails = {
        cooling_period: "12/31/2023",
        comment: "Set cooling period until year end"
    }
    return (
        <Container component="main" maxWidth="xs">
            <div style={{
                position: 'absolute', left: '50%', top: '50%',
                transform: 'translate(-50%, -50%)'
            }}>
                    { false && 
                    <Button id='loginMenu' name='loginMenu' onClick={ () => getCandidateById('1241241')} color='primary' sx={{
                            boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)'
                    }}>
                        Get a candidate
                    </Button>
                    }
                    
                    { false && 
                    <Button id='loginMenu2' name='loginMenu2' onClick={getAllCandidate} color='primary' sx={{
                            boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)'
                    }}>
                        Get all candidates
                    </Button>
                    }
                    
                    { false && 
                    <Button id='loginMenu6' name='loginMenu6' onClick={() => getEventById('1686205461809')} color='primary' sx={{
                            boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)'
                    }}>
                        Get event by id
                    </Button>
                    }
                    
                    { false && 
                    <Button id='loginMenu7' name='loginMenu7' onClick={() => getAllEvent()} color='primary' sx={{
                            boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)'
                    }}>
                        Get all event
                    </Button>
                    }
                    
                    { false && 
                    <Button id='loginMenu8' name='loginMenu8' onClick={() => getEventCandidateIds('1685424764254')} color='primary' sx={{
                            boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)'
                    }}>
                        Get all candidate id for an event
                    </Button>
                    }
                    
                    { false && 
                    <Button id='loginMenu9' name='loginMenu9' onClick={() => addEvent(eventInfo)} color='primary' sx={{
                            boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)'
                    }}>
                        Add event
                    </Button>
                    }
                    
                    { false && 
                    <Button id='loginMenu10' name='loginMenu10' onClick={() => addCandidateToEvent('1412443543', '1685424764254')} color='primary' sx={{
                            boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)'
                    }}>
                        Add candidate to event
                    </Button>
                    }
                    
                    { false && 
                    <Button id='loginMenu12' name='loginMenu12' onClick={() => updateCandidateBlacklistStatus('1412443543', true)} color='primary' sx={{
                            boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)'
                    }}>
                        Update candidate blacklist status true
                    </Button>
                    }
                    
                    { false && 
                    <Button id='loginMenu13' name='loginMenu13' onClick={() => updateCandidateBlacklistStatus('1412443543', false)} color='primary' sx={{
                            boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)'
                    }}>
                        Update candidate blacklist status false
                    </Button>
                    }
                    
                    { false && 
                    <Button id='loginMenu14' name='loginMenu14' onClick={() => getJrNumberListFromCandidate()} color='primary' sx={{
                            boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)'
                    }}>
                        Get JR Number list
                    </Button>
                    }
                    
                    { false && 
                    <Button id='loginMenu16' name='loginMenu16' onClick={() => cancelEventById('1686205461809')} color='primary' sx={{
                            boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)'
                    }}>
                        Cancel Event by ID
                    </Button>
                    }
                    
                    { false && 
                    <Button id='loginMenu16' name='loginMenu16' onClick={() => assignCandidateNumberToEventCandidate('1626465', '1686205461809')} color='primary' sx={{
                            boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)'
                    }}>
                        Auto Assign Candidate Number by Candidate ID and Event ID
                    </Button>
                    }

                    { false && 
                    <Button id='loginMenu16' name='loginMenu16' onClick={() => getEventDetailsPageInfoByEvenId('1691740791793')} color='primary' sx={{
                            boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)'
                    }}>
                        Get Event Details Page Info by Event ID
                    </Button>
                    }
                    
                    { false && 
                    <Button id='loginMenu16' name='loginMenu16' onClick={() => getCandidateEventAssessmentDocumentCheck('1241241','1687492511775')} color='primary' sx={{
                            boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)'
                    }}>
                        Get Candidate Event Assessment Document Check
                    </Button>
                    }

                    { false && 
                    <Button id='loginMenu16' name='loginMenu16' onClick={() => setCandidateEventAssessmentDocumentCheck('1241241','1687492511775', documentCheckResult)} color='primary' sx={{
                            boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)'
                    }}>
                        Set Candidate Event Assessment Document Check
                    </Button>
                    }

                    { false && 
                    <Button id='loginMenu16' name='loginMenu16' onClick={() => getCandidateEventAssessmentMeasurement('1241241','1687492511775')} color='primary' sx={{
                            boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)'
                    }}>
                        Get Candidate Event Assessment Measurement
                    </Button>
                    }

                    { false && 
                    <Button id='loginMenu16' name='loginMenu16' onClick={() => setCandidateEventAssessmentMeasurement('1241241','1687492511775', measurementResult)} color='primary' sx={{
                            boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)'
                    }}>
                        Set Candidate Event Assessment Measurement
                    </Button>
                    }

                    { false && 
                    <Button id='loginMenu16' name='loginMenu16' onClick={() => getCandidateEventAssessmentCatwalk('1241241','1687492511775')} color='primary' sx={{
                            boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)'
                    }}>
                        Get Candidate Event Assessment Catwalk
                    </Button>
                    }

                    { false && 
                    <Button id='loginMenu16' name='loginMenu16' onClick={() => setCandidateEventAssessmentCatwalk('1241241','1687492511775', catwalkResult)} color='primary' sx={{
                            boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)'
                    }}>
                        Set Candidate Event Assessment Catwalk
                    </Button>
                    }

                    { false && 
                    <Button id='loginMenu16' name='loginMenu16' onClick={() => getCandidateEventAssessmentOneToOneInterview('1241241','1687492511775')} color='primary' sx={{
                            boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)'
                    }}>
                        Get Candidate Event Assessment 1-1 Interview
                    </Button>
                    }

                    { false && 
                    <Button id='loginMenu16' name='loginMenu16' onClick={() => setCandidateEventAssessmentOneToOneInterview('1241241','1687492511775', oneToOneInterviewResult)} color='primary' sx={{
                            boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)'
                    }}>
                        Set Candidate Event Assessment 1-1 Interview
                    </Button>
                    }

                    { false && 
                    <Button id='loginMenu16' name='loginMenu16' onClick={() => getCandidateEventAssessmentComment('1626465','1687492511775')} color='primary' sx={{
                            boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)'
                    }}>
                        Get Candidate Event Assessment Comment
                    </Button>
                    }

                    { false && 
                    <Button id='loginMenu16' name='loginMenu16' onClick={() => setCandidateEventAssessmentComment('1241241','1687492511775', assessmentComment)} color='primary' sx={{
                            boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)'
                    }}>
                        Set Candidate Event Assessment Comment
                    </Button>
                    }

                    { false && 
                    <Button id='loginMenu16' name='loginMenu16' onClick={() => getAllCandidateJobApplication()} color='primary' sx={{
                            boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)'
                    }}>
                        Get All Candidate Job Application
                    </Button>
                    }
                    
                    { false && 
                    <Button 
                        id='loginMenu16' 
                        name='loginMenu16' 
                        onClick={() => autoCreateCandidateJobApplication('1691651893664')} 
                        color='primary' sx={{
                            boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)'
                        }
                    }>
                        Auto Create Job Application
                    </Button>
                    }

                    { false && 
                    <Button 
                        id='loginMenu16' 
                        name='loginMenu16' 
                        onClick={() => uploadAssessmentResultEnglishExam('1687492511775', assessmentResultEnglishExamination)} 
                        color='primary' sx={{
                            boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)'
                        }
                    }>
                        Upload Assessment Result - English Examination
                    </Button>
                    }
                    
                    { false && 
                    <Button 
                        id='loginMenu16' 
                        name='loginMenu16' 
                        onClick={() => getCandidateEventAssessmentEnglishExam('4524657', '1687492511775')} 
                        color='primary' sx={{
                            boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)'
                        }
                    }>
                        Get Candidate Event Assessment English Examination
                    </Button>
                    }
                    
                    { false && 
                    <Button 
                        id='loginMenu16' 
                        name='loginMenu16' 
                        onClick={() => getCandidateEventAssessmentGroupDynamic('4524657', '1687492511775')} 
                        color='primary' sx={{
                            boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)'
                        }
                    }>
                        Get Candidate Event Assessment Group Dynamic
                    </Button>
                    }

                    { false && 
                    <Button 
                        id='loginMenu16' 
                        name='loginMenu16' 
                        onClick={() => uploadAssessmentResultGroupDynamic('1687492511775', assessmentResultGroupDynamic)} 
                        color='primary' sx={{
                            boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)'
                        }
                    }>
                        Upload Assessment Result - Group Dynamic
                    </Button>
                    }

                    { false && 
                    <Button 
                        id='loginMenu16' 
                        name='loginMenu16' 
                        onClick={() => getEventEmailTemplateById('1687492511775')} 
                        color='primary' sx={{
                            boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)'
                        }
                    }>
                        Get Event Email Template By ID
                    </Button>
                    }

                    { false && 
                    <Button 
                        id='loginMenu16' 
                        name='loginMenu16' 
                        onClick={() => setEventEmailTemplateById('1687492511775', emailTemplate)} 
                        color='primary' sx={{
                            boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)'
                        }
                    }>
                        Set Event Email Template By ID
                    </Button>
                    }
                    
                    { false && 
                    <Button 
                        id='loginMenu16' 
                        name='loginMenu16' 
                        onClick={() => getCandidateEventAssessmentOverall('1241241', '1687492511775')} 
                        color='primary' sx={{
                            boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)'
                        }
                    }>
                        Get Candidate Event Assessment Overall Result
                    </Button>
                    }
                    
                    { false && 
                    <Button 
                        id='loginMenu16' 
                        name='loginMenu16' 
                        onClick={() => setAttachmentDetails('1241241', '1687492511775', attachmentDetails)} 
                        color='primary' sx={{
                            boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)'
                        }
                    }>
                        Set Attachment Details
                    </Button>
                    }
                    
                    { false && 
                    <Button 
                        id='loginMenu16' 
                        name='loginMenu16' 
                        onClick={() => overwriteCandidateBlacklistStatus('1241241', candidateBlacklistDetails)} 
                        color='primary' sx={{
                            boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)'
                        }
                    }>
                        Overwrite Candidate Blacklist Status
                    </Button>
                    }
                    
                    { false && 
                    <Button 
                        id='loginMenu16' 
                        name='loginMenu16' 
                        onClick={() => overwriteCandidateCoolingPeriod('1241241', coolingPeriodDetails)} 
                        color='primary' sx={{
                            boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)'
                        }
                    }>
                        Overwrite Candidate Cooling Period
                    </Button>
                    }
                    
                    { false && 
                    <Button 
                        id='loginMenu16' 
                        name='loginMenu16' 
                        onClick={() => getEventEmailTemplateFinalStatus('1687492511775')} 
                        color='primary' sx={{
                            boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)'
                        }
                    }>
                        Get Event Email Template Final Status
                    </Button>
                    }
                    
                    { false && 
                    <Button 
                        id='loginMenu16' 
                        name='loginMenu16' 
                        onClick={() => updateEventEmailTemplateFinalStatus('1687492511775', true)} 
                        color='primary' sx={{
                            boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)'
                        }
                    }>
                        Set Event Email Template Final Status
                    </Button>
                    }
                    
                    { false && 
                    <Button 
                        id='loginMenu16' 
                        name='loginMenu16' 
                        onClick={() => sendEmail("tD2qnZ3vxegueGR6Lx9O")} 
                        color='primary' sx={{
                            boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)'
                        }
                    }>
                        Test Cloud Function
                    </Button>
                    }
                    
                    { false && 
                    <Button 
                        id='loginMenu16' 
                        name='loginMenu16' 
                        onClick={() => setEmailSent('1241241', '1687492511775')} 
                        color='primary' sx={{
                            boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)'
                        }
                    }>
                        Set Email Sent 
                    </Button>
                    }
                    
                    { false && 
                    <Button 
                        id='loginMenu16' 
                        name='loginMenu16' 
                        onClick={() => getEmailSentList('1687492511775')} 
                        color='primary' sx={{
                            boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)'
                        }
                    }>
                        Get Email Sent List
                    </Button>
                    }
                    
                    { false && 
                    <Button 
                        id='loginMenu16' 
                        name='loginMenu16' 
                        onClick={() => generateSendEmailToken({}, [], '')} 
                        color='primary' sx={{
                            boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)'
                        }
                    }>
                        Generate Send Email Token
                    </Button>
                    }
                    
                    { false && 
                    <Button 
                        id='loginMenu16' 
                        name='loginMenu16' 
                        onClick={() => getSendEmailDetails('WgbVqRLflKD5uVs4s281')} 
                        color='primary' sx={{
                            boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)'
                        }
                    }>
                        Get Send Email Details
                    </Button>
                    }
                    
                    { false && 
                    <Button 
                        id='loginMenu16' 
                        name='loginMenu16' 
                        onClick={() => setTokenToHasProcessed('yvaYJWSHv2SueqUQoAKG')} 
                        color='primary' sx={{
                            boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)'
                        }
                    }>
                        Set Token to Has Processed
                    </Button>
                    }
                    
                    { false && 
                    <Button 
                        id='loginMenu16' 
                        name='loginMenu16' 
                        onClick={() => triggerAutoSendInvitationEmail('1687492511775')} 
                        color='primary' sx={{
                            boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)'
                        }
                    }>
                        Trigger Auto Send Invitation Email
                    </Button>
                    }
                    
                    { false && 
                    <Button 
                        id='loginMenu16' 
                        name='loginMenu16' 
                        onClick={() => generateAcceptInvitationToken('1687492511775', '1241241')} 
                        color='primary' sx={{
                            boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)'
                        }
                    }>
                        Generate Accept Invitation Token
                    </Button>
                    }
                    
                    { false && 
                    <Button 
                        id='loginMenu16' 
                        name='loginMenu16' 
                        onClick={() => getInvitationTokenDetails('pXZhoeXo91fsN2j168as')} 
                        color='primary' sx={{
                            boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)'
                        }
                    }>
                        Get Invitation Token Details
                    </Button>
                    }
                    
                    { false && 
                    <Button 
                        id='loginMenu16' 
                        name='loginMenu16' 
                        onClick={() => setInvitationTokenToHasProcessed('pXZhoeXo91fsN2j168as')} 
                        color='primary' sx={{
                            boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)'
                        }
                    }>
                        Set Invitation Token to Has Processed
                    </Button>
                    }
                    
                    { false && 
                    <Button 
                        id='loginMenu16' 
                        name='loginMenu16' 
                        onClick={() => getCandidateEventAttended('11223344')} 
                        color='primary' sx={{
                            boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)'
                        }
                    }>
                        Get Candidate Event Attended
                    </Button>
                    }
                    
                    { false && 
                    <Button 
                        id='loginMenu16' 
                        name='loginMenu16' 
                        onClick={() => getAllJrNumberDetails()} 
                        color='primary' sx={{
                            boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)'
                        }
                    }>
                        Get All JR Number Details
                    </Button>
                    }
                    { false && 
                    <Button 
                        onClick={() => addToWorkday({
                            candidateId: '0000-1234-5678',
                            jrNumber: 'JR0019903'
                        })} 
                        color='primary' sx={{
                            boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)'
                        }
                    }>
                        Workday
                    </Button>
                    }
            </div>
        </Container>
    )
}

export default TestPage