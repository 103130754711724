// import { functions } from "./firebase"
// import { httpsCallable } from "firebase/functions"
// import { connectFunctionsEmulator } from "firebase/functions";

const sendEmail = async (token) => {
    //const apiUrl = 'https://us-central1-airasia-incredible-dev.cloudfunctions.net/sendemail'
    
    const apiUrl = process.env['REACT_APP_FIREBASE_CLOUD_FUNCTION_SEND_EMAIL'] + '?token=' + token
    const response = await fetch(apiUrl);
    const data = await response.json();
    //console.log(data)
    return data;
    
    /*
    connectFunctionsEmulator(functions, "127.0.0.1", 5001);
    const caller = httpsCallable(functions, 'sendemail')
    caller({ text: "i'm edison choong" })
    .then((result) => {
        // Read result of the Cloud Function.
        console.log(result)
    })
    */
}

export {
    sendEmail,
}