import CancelIcon from '@mui/icons-material/Cancel';
import EditIcon from '@mui/icons-material/Edit';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import { Autocomplete, Box, Button, ButtonGroup, CircularProgress, Container, FormControl, IconButton, MenuItem, Stack, TextField, Tooltip, Typography, useTheme } from "@mui/material";
import { DataGrid, GridToolbarContainer } from '@mui/x-data-grid';
import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getAllEvent } from "../lib/db_event";
import RenderCellExpand from '../utils/RenderCellExpand';
import AddEvent from './event/AddEvent';
import CancelEvent from './event/CancelEvent';
import CustomPagination from './event/CustomPagination';
import EditEvent from './event/EditEvent';
import ViewEvent from './event/ViewEvent';
import Footer from './menu/Footer';
import MenuTab from './menu/MenuTab';
// import { getAllJrNumberListFromCandidate } from '../lib/db_candidate';
import apiService from '../lib/apiService';
import { authenticateUser } from '../lib/userAuthenticate';
import { EVENT_COMPANY_NAME } from '../utils/candidateFilterUtil';

const intialFilterState = {
    eventName: '',
    jrNumber: '',
    companyName: '',
    location: '',
    eventDate: 'All',
    eventStatus: 'Active'
}

function EventListPage() {
    const navigate = useNavigate()
    const location = useLocation()
    const [user, setUser] = useState({
        username: "",
        url: ""
    });
    const [isLoading, setIsLoading] = useState(false)

    const [addEventCallback, setAddEventCallback] = useState({});

    useEffect(() => {
        authenticateUser(setUser, location, navigate)
    }, [location, navigate])

    const [allEvent, setAllEvent] = useState([])
    const [rowsEvents, setRowsEvents] = useState([])
    const [isAddDialogOpen, setIsAddDialogOpen] = useState(false)
    const [isViewDialogOpen, setIsViewDialogOpen] = useState(false)
    const [isEditDialogOpen, setIsEditDialogOpen] = useState(false)
    const [isCancelDialogOpen, setIsCancelDialogOpen] = useState(false)
    const [selectedRows, setSelectedRows] = useState([])
    const [tab, setTab] = useState("1")
    const [color, setColor] = useState("black")
    const PAGE_SIZE = 10;
    const [paginationModel, setPaginationModel] = useState({
        pageSize: PAGE_SIZE,
        page: 0,
    });
    const [filterHolder, setFilterHolder] = useState(intialFilterState)
    const [allJrNumber, setAllJrNumber] = useState([])
    const jrNumberRef = useRef()

    useEffect(() => {
        const getAllData = async () => {
            setIsLoading(true)
            await getEvents()
            getAvailableJrNumber()
            setIsLoading(false)
        }
        getAllData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    async function getEvents() {
        const listEvents = await getAllEvent(filterHolder);
        setAllEvent(listEvents)
        const rowEvents = listEvents.filter(event => {
            return event.event_status === "Active"
        })
        setRowsEvents(rowEvents)
    }

    function getAvailableJrNumber(){
        apiService.getJrNumbers().then((result) => {
            const allJrNumbers = result.data?.jr_numbers ?? [];

            console.log(result);
            setAllJrNumber(allJrNumbers);
        });    
    }

    function handleAddOpen() {
        setIsAddDialogOpen(!isAddDialogOpen)
    }

    const handleSelectionChange = (selection) => {
        if(selection.length === 0){
            setSelectedRows([])
            return
        }
        const index = rowsEvents.findIndex(object => {
            return object.id === selection[0]
        })
        if (rowsEvents[index].event_status === "Active") {
            setColor("#008647")
        } else if (rowsEvents[index].event_status === "Cancelled") {
            setColor("red")
        }
        setSelectedRows(rowsEvents[index])
    }

    function EventLink(props) {
        const handleClick = (e) => {
            navigate(`/events/${e.target.id}`, {
                state: {
                    name: user.username,
                    displayPic: user.url,
                    selectedRows
                }
            })
        }
        return (
            <>
                <Button disableRipple sx={{ color: "black" }} id={props.row.id} variant='text' onClick={handleClick}>
                    {props.value}
                </Button>
            </>
        )
    }

    function ActionMenu(row) {
        const handleClose = (e) => {
            if (e.target.id === 'editMenu') {
                setTab("1")
                setIsEditDialogOpen(!isEditDialogOpen)
            } else if (e.target.id === 'cancelMenu') {
                setIsCancelDialogOpen(!isCancelDialogOpen)
            } else if (e.target.id === 'viewMenu') {
                setIsViewDialogOpen(!isViewDialogOpen)
            } else if (e.target.id === 'addCandidatesMenu') {
                setTab("3")
                setIsEditDialogOpen(!isEditDialogOpen)
            }
        };
        return (
            <Box sx={{
                display: "flex",
                width: "100%",
                justifyContent: "center"
            }}>
                <ButtonGroup variant='text' size="small">
                    {(row.event_status !== "Cancelled" && row.event_status !== "Completed") &&
                        <Tooltip title="Add Candidate">
                            <IconButton id='addCandidatesMenu' name='addCandidatesMenu' onClick={handleClose}>
                                <GroupAddIcon id='addCandidatesMenu' name='addCandidatesMenu' sx={{
                                    pointerEvents: 'none',
                                    color: 'black'
                                }} />
                            </IconButton>
                        </Tooltip>}
                    <Tooltip title="View">
                        <IconButton id='viewMenu' name='viewMenu' onClick={handleClose}>
                            <FindInPageIcon id='viewMenu' name='viewMenu' sx={{
                                pointerEvents: 'none',
                                color: 'black'
                            }} />
                        </IconButton>
                    </Tooltip>
                    {(row.event_status !== "Cancelled" && row.event_status !== "Completed") &&
                        <Tooltip title="Edit">
                            <IconButton id='editMenu' name='editMenu' onClick={handleClose}>
                                <EditIcon id='editMenu' name='editMenu' sx={{
                                    pointerEvents: 'none',
                                    color: 'black'
                                }} />
                            </IconButton>
                        </Tooltip>}
                    {(row.event_status !== "Cancelled" && row.event_status !== "Completed") &&
                        <Tooltip title="Cancel Event">
                            <IconButton id='cancelMenu' name='cancelMenu' onClick={handleClose}>
                                <CancelIcon id='cancelMenu' name='cancelMenu' sx={{
                                    pointerEvents: 'none',
                                    color: 'black'
                                }} />
                            </IconButton>
                        </Tooltip>
                    }
                </ButtonGroup>
            </Box>
        )
    }

    const columns = [
        { field: 'event_code', headerClassName: 'theme--header', headerName: 'Event ID', width: 120, headerAlign: 'center', renderCell: EventLink },
        { field: 'event_name', headerClassName: 'theme--header', headerName: 'Event Name', minWidth: 250, renderCell: RenderCellExpand, headerAlign: 'center', flex: 2 },
        { field: 'jr_number', headerClassName: 'theme--header', headerName: 'JR Number', width: 180, headerAlign: 'center' },
        { field: 'event_aoc', headerClassName: 'theme--header', headerName: 'Company Name', width: 130, filterable: false, renderCell: RenderCellExpand, headerAlign: 'center' },
        { field: 'event_venue', headerClassName: 'theme--header', headerName: 'Location', width: 110, filterable: false, renderCell: RenderCellExpand, headerAlign: 'center' },
        { field: 'event_date_time', headerClassName: 'theme--header', headerName: 'Event Date Time', width: 160, filterable: false, headerAlign: 'center', align: 'center' },
        { field: 'posting_date', headerClassName: 'theme--header', headerName: 'Created At', width: 160, filterable: false, headerAlign: 'center', align: 'center' },
        { field: 'event_status', headerClassName: 'theme--header', headerName: 'Status', width: 100, filterable: false, headerAlign: 'center' },
        { field: 'action', headerClassName: 'theme--header', headerName: 'Action', minWidth: 200, filterable: false, renderCell: (params) => ActionMenu(params.row), disableClickEventBubbling: true, flex: 1, headerAlign: 'center', align: 'center' },
    ]

    function CustomToolbar() {
        const [filter, setFilter] = useState(intialFilterState)
        useEffect(() => {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            setFilter(filterHolder)
        }, [])

        const [options,setOptions] = useState([]);

        const handleInputBlur = () => {
            setOptions([]);
        }

        const handleSelect = (e, value) => {
            const name = jrNumberRef.current.getAttribute("name");

            if ( name === "jrNumber" ) {
                if ( value && value !== "" ) {
                    setOptions(allJrNumber)
                } else {
                    setOptions(allJrNumber.slice(0, 10));
                }
            }

            setFilter({
                ...filter,
                [name]: value
            })
        }

        const handleChange = (e) => {
            const name = e.target.name;
            const val = e.target.value;

            if ( name === "jrNumber" ) {
                if ( val && val !== "" ) {
                    setOptions(allJrNumber)
                } else {
                    setOptions(allJrNumber.slice(0, 10));
                }
            }

            setFilter({
                ...filter,
                [name]: val
            })
        }
        const handleFilter = (e, val) => {
            e.preventDefault()
            if (e.target.id === "applyFilter") {
                setFilterHolder(filter)
                let event = allEvent
                if (
                    filter.companyName.length === 0 &&
                    filter.eventDate === 'All' &&
                    filter.eventName.length === 0 &&
                    filter.jrNumber.length === 0 &&
                    filter.location.length === 0 &&
                    filter.eventStatus === 'Active'
                ) {
                    return getEvents()
                }
                if (filter.companyName.length > 0) {
                    event = event.filter(obj => obj.event_aoc.toLowerCase().includes(filter.companyName.toLowerCase()))
                }
                if (filter.eventDate !== 'All') {
                    const today = new Date()
                    if (filter.eventDate === 'Upcoming') {
                        event = event.filter(obj => {
                            const date = obj.event_date
                            const [year, month, day] = date.split('-').map(Number)
                            const convertDate = new Date(year, month - 1, day)
                            const stamp = convertDate.getTime()
                            return stamp > today
                        })
                    } else if (filter.eventDate === 'Live') {
                        const year = today.getFullYear()
                        const month = today.getMonth() + 1
                        const day = today.getDate()
                        const formatMonth = month.toString().padStart(2, '0')
                        const formatDay = day.toString().padStart(2, '0')
                        const formattedDate = `${year}-${formatMonth}-${formatDay}`
                        event = event.filter(obj => obj.event_date === formattedDate)
                    } else if (filter.eventDate === 'Past') {
                        event = event.filter(obj => {
                            const date = obj.event_date
                            const [year, month, day] = date.split('-').map(Number)
                            const convertDate = new Date(year, month - 1, day)
                            const stamp = convertDate.getTime()
                            // const date = new Date(obj.event_date)
                            return stamp < today
                        })
                    }
                }
                if (filter.eventStatus !== "All") {
                    if (filter.eventStatus === 'Cancelled') {
                        event = event.filter(obj => obj.event_status === "Cancelled")
                    }
                    if (filter.eventStatus === 'Active') {
                        event = event.filter(obj => obj.event_status === "Active")
                    }
                    if (filter.eventStatus === 'Completed') {
                        event = event.filter(obj => obj.event_status === "Completed")
                    }
                }
                if (filter.eventName.length > 0) {
                    event = event.filter(obj => obj.event_name.toLowerCase().includes(filter.eventName.toLowerCase()))
                }
                if (filter.jrNumber.length > 0) {
                    event = event.filter(obj => obj.jr_number.toLowerCase().includes(filter.jrNumber.toLowerCase()))
                }
                if (filter.location.length > 0) {
                    event = event.filter(obj => obj.event_venue.toLowerCase().includes(filter.location.toLowerCase()))
                }
                setRowsEvents(event)
            } else if (e.target.id === "clearFilter") {
                setFilterHolder(intialFilterState)
                getEvents()
            }
        }
        const option = ["All", "Upcoming", "Live", "Past"]
        const optionStatus = ["All", "Active", "Completed", "Cancelled"]
        return (
            <GridToolbarContainer sx={{ width: '100%', justifyContent: "center", pt: 1, pb: 2 }}>
                <FormControl onChange={handleChange} sx={{ flex: 1 }}>
                    <TextField autoComplete='off' key='123' id='eventName' name='eventName' label="Event Name" size='small' defaultValue={filterHolder.eventName} variant="outlined" />
                </FormControl>
                <FormControl sx={{ flex: 1 }}>
                    <Autocomplete
                        ref={jrNumberRef}
                        key="jrNumber"
                        id="jrNumber"
                        name="jrNumber"
                        defaultValue={filterHolder.jrNumber}
                        options={options}
                        limitTags={10}
                        multiple={false}
                        getOptionLabel={(option) => option ?? ""}
                        onBlur={handleInputBlur}
                        onSelect={handleChange}
                        onChange={handleSelect}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                autoComplete="off"
                                id="jrNumber"
                                name="jrNumber"
                                label="JR Number"
                                size="small"
                                variant="outlined"
                            />
                        )}
                    />
                </FormControl>
                <FormControl sx={{ flex: 1 }}>
                    <TextField onChange={handleChange} autoComplete='off' id='companyName' name='companyName' label="Company Name" size='small' defaultValue={filterHolder.companyName} variant="outlined" select>
                        {EVENT_COMPANY_NAME.map((option)=>(
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                </FormControl>
                <FormControl onChange={handleChange} sx={{ flex: 1 }}>
                    <TextField autoComplete='off' id='location' name='location' label="Location" size='small' defaultValue={filterHolder.location} variant="outlined" />
                </FormControl>
                <FormControl sx={{ flex: 1 }}>
                    <TextField onChange={handleChange} id='eventDate' name='eventDate' label="Event Date" size='small' variant="outlined" defaultValue={filterHolder.eventDate} select >
                        {option.map((opt) => (
                            <MenuItem key={opt} value={opt}>{opt}</MenuItem>
                        ))}
                    </TextField>
                </FormControl>
                <FormControl sx={{ flex: 1 }}>
                    <TextField onChange={handleChange} id='eventStatus' name='eventStatus' label="Event Status" size='small' variant="outlined" defaultValue={filterHolder.eventStatus} select >
                        {optionStatus.map((opt) => (
                            <MenuItem key={opt} value={opt}>{opt}</MenuItem>
                        ))}
                    </TextField>
                </FormControl>
                <Box sx={{
                    display: "flex",
                }}>
                    <ButtonGroup variant='text' size="small">
                        <Tooltip title="Apply Filter">
                            <IconButton id='applyFilter' name='applyFilter' onClick={handleFilter}>
                                <FilterAltIcon id='applyFilter' name='applyFilter' sx={{
                                    pointerEvents: 'none',
                                    color: 'black'
                                }} />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Clear Filter">
                            <IconButton id='clearFilter' name='clearFilter' onClick={handleFilter}>
                                <FilterAltOffIcon id='clearFilter' name='clearFilter' sx={{
                                    pointerEvents: 'none',
                                    color: 'black'
                                }} />
                            </IconButton>
                        </Tooltip>
                    </ButtonGroup>
                </Box>
            </GridToolbarContainer>
        );
    }
    const theme = useTheme();
    return (
        <div style={{ minHeight: "100vh" }}>
            <MenuTab username={user.username} url={user.url} />
            <Container disableGutters maxWidth="100%" component="main" sx={{ pl: `calc(${theme.spacing(7)} + 5px)`, minHeight: `calc(100vh - ${theme.mixins.toolbar})`, pb: "65px" }}>
                <Box p={2} mt={1}>
                    <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" marginBottom="20px">
                        <Typography variant="h5" color="inherit" noWrap>Event List</Typography>
                        <Button 
                            disabled={addEventCallback?.isAdding}
                            variant='contained' 
                            onClick={handleAddOpen}>
                            <Box>
                                {addEventCallback?.isAdding
                                    ? "Saving..."
                                    : "Add Event"
                                }
                            </Box>
                        </Button>
                    </Box>
                    <Box sx={{
                        height: "auto", width: "100%",
                        '& .theme--header': {
                            backgroundColor: '#F1F1F1',
                            opacity: 0.5,
                        },
                    }}>
                        <div style={{ flexGrow: 1 }}>
                            <DataGrid
                                sx={{
                                    "& .MuiDataGrid-columnHeader": {
                                        height: "unset !important"
                                    },
                                    "& .MuiDataGrid-columnHeaders": {
                                        maxHeight: "168px !important"
                                    },
                                    '.MuiDataGrid-columnSeparator': {
                                        display: 'none',
                                    },
                                    "& .MuiDataGrid-cell": {
                                        border: 1,
                                        borderColor: "#D9DBE0"
                                    },
                                    p: 3
                                }}
                                rows={rowsEvents}
                                columns={columns}
                                autoHeight={true}
                                onRowSelectionModelChange={handleSelectionChange}
                                components={{ 
                                    toolbar: CustomToolbar, 
                                    Pagination: CustomPagination,
                                    NoRowsOverlay: () => (
                                        <>
                                            <Stack height="100%" alignItems="center" justifyContent="center">
                                                {isLoading ? <><p>Getting Events</p><CircularProgress/></> : "No events"}
                                            </Stack>
                                        </>
                                    )
                                 }}
                                componentsProps={{
                                    panel: {
                                        placement: "bottom-end",
                                    },
                                }}
                                paginationModel={paginationModel}
                                onPaginationModelChange={setPaginationModel}
                                getRowClassName={(params) => {
                                    return typeof params.row !== "object" ? "" : "theme--rows"
                                }}
                                disableColumnMenu />
                        </div>
                    </Box>
                </Box>
            </Container>
            <AddEvent 
                isDialogOpen={isAddDialogOpen} 
                handleCloseDialog={() => setIsAddDialogOpen(false)} 
                allEvent={rowsEvents} 
                username={user.username} 
                onUpdate={(callback) => {
                    setAddEventCallback(callback)
                }}
            />
            <ViewEvent isDialogOpen={isViewDialogOpen} handleCloseDialog={() => setIsViewDialogOpen(false)} handleOpenEdit={() => {
                setTab("1")
                setIsEditDialogOpen(true)
            }} selectedRows={selectedRows} color={color} />
            <EditEvent isDialogOpen={isEditDialogOpen} handleCloseDialog={() => setIsEditDialogOpen(false)} selectedRows={selectedRows} tab={tab} color={color} />
            <CancelEvent isDialogOpen={isCancelDialogOpen} handleCloseDialog={() => setIsCancelDialogOpen(false)} selectedRows={selectedRows} username={user.username} />
            <Footer />
        </div>
    )
}

export default EventListPage;