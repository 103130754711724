import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

export default function Loading({ isLoading, handleStopLoading, state }) {

  const handleClose = () => {
    handleStopLoading(false);
  };

  return (
    <div>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
        onClick={handleClose}
      >
        <div>
            <CircularProgress color="inherit" sx={{ mr: 2}}/>
        </div>
        {state ? <>Finalizing and sending invitation email to the candidates</> : <>Saving changes</>}
        
      </Backdrop>
    </div>
  );
}

export function LoadingWithMsg({ isLoading, handleStopLoading, msg }) {

  const handleClose = () => {
    handleStopLoading(false);
  };

  return (
    <div>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
        onClick={handleClose}
      >
        <div>
            <CircularProgress color="inherit" sx={{ mr: 2}}/>
        </div>
        {msg}
      </Backdrop>
    </div>
  );
}