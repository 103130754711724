import * as React from 'react'
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Typography,
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { cancelEventById } from '../../lib/db_event';

function CancelEvent({isDialogOpen, handleCloseDialog, selectedRows, username}){
    function handleClose(){
        handleCloseDialog(false);
    }

    async function handleSubmit(e){
        e.preventDefault()
        await cancelEventById(selectedRows.id)
        handleCloseDialog(false);
        window.location.reload();
    }

    return (
        <div>
            <Dialog
                open={isDialogOpen}
                onClose={handleClose}
                fullWidth={true}
                maxWidth={"xs"}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle>
                    <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
                        <Typography variant='h6'></Typography>
                        <IconButton id='close' name='close' onClick={handleClose}>
                            <CloseIcon id='close' name='close' sx={{
                                pointerEvents: 'none',
                                color: 'black'
                            }} />
                        </IconButton>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <Typography variant='h6'>Cancel Event</Typography>
                    <Typography color="#75767A" sx={{mt: 1}}>Are you sure you want to cancel 'Event ID: {selectedRows.event_code}'</Typography>
                </DialogContent>
                <DialogActions sx={{ m: 1, display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <Button size='large' sx={{width: "40%"}} variant="outlined" onClick={handleClose}> No </Button>
                    <Button size='large' sx={{width: "40%"}} autoFocus type="submit" onClick={handleSubmit} variant="contained"> Yes </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default CancelEvent