const getShortMonthName = (monthNumber) => {
  const months = [
    'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
    'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
  ];

  if (monthNumber >= 1 && monthNumber <= 12) {
    return months[monthNumber - 1];
  } else {
    console.error('Invalid month number. Please provide a number between 1 and 12.');
    return "";
  }
}

export const getMonthQuaterly = (factor) => {
  const months = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ];
  
  const monthsByQuarter = [
    ['January', 'February', 'March'],
    ['April', 'May', 'June'],
    ['July', 'August', 'September'],
    ['October', 'November', 'December']
  ]
  const shortMonthsByQuarter = [
    ['Jan', 'Feb', 'Mar'],
    ['Apr', 'May', 'Jun'],
    ['Jul', 'Aug', 'Sep'],
    ['Oct', 'Nov', 'Dec']
  ]
  
  let isFuture = false
  let isCurrent = true
  if(factor < 0){
    isFuture = false
    isCurrent = false
  } else if(factor > 0) {
    isCurrent = false
    isFuture = true
  }
  
  const makePositive = (value, year) =>{
    while(value < 0){
      value += 4
      year= year - 1
    }
    return {value, year}
  }

  const makeLessthan4 = (value, year) => {
    while(value > 3){
      value -= 4
      year = year + 1
    }
    return {value, year}
  }
  
  const currentDate = new Date();
  const currentMonthFullName = months[currentDate.getMonth()]
  let currentMonthIndex = monthsByQuarter.findIndex(quarter => quarter.includes(months[currentDate.getMonth()]))
  let currentYear = currentDate.getFullYear();
  let quarterIndex = currentMonthIndex + factor

  if(quarterIndex > 3){
    const result = makeLessthan4(quarterIndex, currentYear)
    currentMonthIndex = result.value
    currentYear = result.year
  } else if(quarterIndex < 0) {
    const result = makePositive(quarterIndex, currentYear)
    currentMonthIndex = result.value
    currentYear = result.year
  } else {
    currentMonthIndex = quarterIndex
  }

  const monthObjects = monthsByQuarter[currentMonthIndex].map((item, index)=>({
    fullName: item,
    shortName: shortMonthsByQuarter[currentMonthIndex][index],
    year: currentYear
  }))

  monthObjects.forEach((itemOuter, indexOuter)=>{
    if(isCurrent) {
      if(itemOuter.fullName === currentMonthFullName){
        itemOuter.status = "current"
        monthObjects.forEach((itemInner, indexInner) => {
          if(indexOuter > indexInner) {
            itemInner.status = "past"
          }
          else if(indexOuter < indexInner) {
            itemInner.status = "future"
          }
        })
      }
    } else {
      isFuture ? itemOuter.status = "future" : itemOuter.status = "past"
    }
  })

  const uniqueYears = [currentYear];

  return { monthObjects, uniqueYears };
};

export default getShortMonthName;