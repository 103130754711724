import React, { useEffect, useState } from 'react';
import { Box, FormControl, FormControlLabel, MenuItem, Radio, RadioGroup, Select, TextField, Typography } from "@mui/material"
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { getCandidateAssessmentResultMeasurement } from '../../lib/db_candidate';
import { setCandidateEventAssessmentComment, setCandidateEventAssessmentMeasurement } from '../../lib/page';
import getTime from '../../utils/getTime';
import ClearConfirmation from './ClearConfirmation';
import { AssessmentButtonAction, AssessmentLayout, AssessmentSection } from '../layout/AssessmentLayout';
import SuccessMsg from '../SuccessMsg';

const INITIAL_PAYLOAD = {
    height: '',
    weight: '',
    bmi: '',
    arm_reached_above_210cm: "",
    overall_score:  {
        status: '',
        comment: ''
    }
}

function Measurement({candidateId, eventId, username, isComment, isCompleted = false}){
    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;
    const [isCancelDialogOpen, setIsCancelDialogOpen] = useState(false)
    const [payload, setPayload] = useState(INITIAL_PAYLOAD)
    const [errorForm, setErrorForm] = useState({
        commentError: {
            msg: "",
            bool: false,
        },
        overallScoreError: {
            msg: "",
            bool: false
        }
    })
    const [formError, setFormError] = useState(false)
    const [isShowPopup, setIsShowPopup] = useState(false)

    useEffect(()=>{
        let BMI = 0
        function setBMI(){
            if(payload.weight.length > 0 && payload.height.length > 0){
                BMI = (payload.weight / ((payload.height / 100) * (payload.height / 100))).toFixed(2)
            }
    
            setPayload({
                ...payload,
                "bmi": BMI
            })
        }
        setBMI()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[payload.weight, payload.height])

    useEffect(()=>{
        async function getResult(){
            try {
                const res = await getCandidateAssessmentResultMeasurement(candidateId, eventId)
                if (res !== undefined){
                    setPayload(res)
                } else {
                    setPayload(INITIAL_PAYLOAD)
                }
            } catch (error) {
                alert('Error getting candidate result measurement')
            }
        }
        if (candidateId !== undefined) {
            getResult();
        } else {
            setPayload(INITIAL_PAYLOAD)
        }
    },[candidateId, eventId])

    const checkStrings = (string) => {
        return !isNaN(string) && !isNaN(parseFloat(string))
    }
    const handleChange = (e) => {
        let newValue = ''

        if(e.target.name === 'arm_reached_above_210cm'){
            if(e.target.value === 'true'){
                newValue = true
            } else {
                newValue = false
            }
        } else {
            if(e.target.name === 'weight' || e.target.name === 'height'){
                const val = checkStrings(e.target.value)
                if(val === true){
                    newValue = e.target.value
                } else {
                    e.target.value = ''
                }
            } else {
                newValue = e.target.value
            }
        }

        setPayload({
            ...payload,
            [e.target.name]: newValue
        })
    }
    function handleScore(event){
        var comment = payload.overall_score.comment
        if(event.target.value === "Passed"){
            comment = ""
        } 
        setPayload({
            ...payload,
            overall_score: {
                comment: comment,
                status: event.target.value
            }
        })
        if(event.target.value.length > 0){
            setErrorForm({
                ...errorForm,
                overallScoreError: {
                    bool: false,
                    msg: ""
                }
            })
        }
    }
    function handleComment(event){
        setPayload({
            ...payload,
            overall_score: {
                status: payload.overall_score.status,
                comment: event.target.value
            }
        })

        if(event.target.value.length !== 0){
            setErrorForm({
                ...errorForm,
                commentError: {
                    msg: "",
                    bool: false
                }
            })
        }
    }

    const handleClear = () =>{
        setPayload(INITIAL_PAYLOAD)
    }

    const handleClickClear = () => {
        setIsCancelDialogOpen(!isCancelDialogOpen)
    }

    const handleSubmit = async(e) => {
        if(candidateId === undefined){
            setFormError(true)
            setIsShowPopup(true)
            return
        }
        if(payload.overall_score.status.length === 0) {
            setErrorForm({
                ...errorForm,
                overallScoreError: {
                    bool: true,
                    msg: "Overall Score Required"
                }
            })
            setFormError(true)
            setIsShowPopup(true)
            return
        }
        if(payload.overall_score.status === "Failed" && payload.overall_score.comment.length === 0 ){
            setErrorForm({
                ...errorForm,
                commentError: {
                    bool: true,
                    msg: "Missing Remarks"
                }
            })
            setFormError(true)
            setIsShowPopup(true)
            return
        }
        const commentForm = {
            id: new Date().getTime(),
            comment: payload.overall_score.comment,
            module: "Measurement",
            overall_score: payload.overall_score.status,
            created_by: username,
            created_at: getTime()
        }
        try {
            await setCandidateEventAssessmentComment(candidateId, eventId, commentForm)
            await setCandidateEventAssessmentMeasurement(candidateId, eventId, payload)
            isComment()
            setFormError(false)
            setIsShowPopup(true)
        } catch (error) {
            alert(`Error saving measurement: ${error}`)
        }
    }
    return(
        <>
            <AssessmentLayout title={"Measurement"}>
                <AssessmentSection width="50%">
                    <FormControl variant="standard" sx={{width: "100%", pt: 1}}>
                        <Typography>Height</Typography>
                        <TextField
                            required
                            onChange={handleChange}
                            value={payload.height}
                            size="small"
                            id="height"
                            name="height"
                            label="(cm)"
                            style={{width: "70%"}}
                            disabled={isCompleted}
                        />
                    </FormControl>
                </AssessmentSection>
                <AssessmentSection width="50%">
                    <FormControl variant="standard" sx={{width: "100%", pt: 1}}>
                        <Typography>Weight</Typography>
                        <TextField
                            required
                            onChange={handleChange}
                            value={payload.weight}
                            size="small"
                            id="weight"
                            name="weight"
                            label="(kg)"
                            style={{width: "70%"}}
                            disabled={isCompleted}
                        />
                    </FormControl>
                </AssessmentSection>
                <AssessmentSection width="50%">
                    <FormControl variant="standard" sx={{width: "100%", pt: 1}}>
                        <Typography>BMI</Typography>
                        <TextField
                            disabled
                            onChange={handleChange}
                            value={payload.bmi}
                            size="small"
                            id="bmi"
                            name="bmi"
                            label="e.g 18.5"
                            style={{width: "70%"}}
                        />
                    </FormControl>
                </AssessmentSection>
                <AssessmentSection width="50%">
                    <FormControl variant="standard" sx={{width: "100%", pt: 1}}>
                        <Typography>Arm reach above 210 cm?</Typography>
                        <RadioGroup onChange={handleChange} name='arm_reached_above_210cm' value={payload.arm_reached_above_210cm} >
                            <FormControlLabel disabled={isCompleted} value='true' control={<Radio icon={icon} checkedIcon={checkedIcon} />} label="Yes" />
                            <FormControlLabel disabled={isCompleted} value='false' control={<Radio  icon={icon} checkedIcon={checkedIcon} />} label="No" />
                        </RadioGroup>
                    </FormControl>
                </AssessmentSection>
                <Box
                    sx={{
                        mt: 2,
                        mb: 2,
                        boxShadow: "0px 1px 4px 0px #00000029",
                        p: 3,
                        width: "50%",
                    }}
                >
                    <Typography>Overall Score</Typography>
                    <FormControl sx={{minWidth: 250}}>
                        <Select
                            disabled={isCompleted}
                            name='score'
                            value={payload.overall_score.status}
                            onChange={handleScore}
                            error={errorForm.overallScoreError.bool}
                        >
                            <MenuItem value={""}>-</MenuItem>
                            <MenuItem value={"Passed"}>Passed</MenuItem>
                            <MenuItem value={"KIV"}>KIV</MenuItem>
                            <MenuItem value={"Failed"}>Failed</MenuItem>
                        </Select>
                        {errorForm.overallScoreError.bool ? <small style={{color: "red"}}>{errorForm.overallScoreError.msg}</small> : ""}
                    </FormControl>
                    <FormControl variant="standard" sx={{width: "100%", pt: 1}}>
                    {((payload.overall_score.status === 'Failed' || payload.overall_score.status === 'KIV' ) && !isCompleted ) && <TextField
                        required
                        onChange={handleComment}
                        value={payload.overall_score.comment}
                        size="small"
                        id="comment"
                        name="comment"
                        label="Remarks"
                        error={errorForm.commentError.bool}
                        helperText={errorForm.commentError.msg}
                    />}
                    </FormControl>
                </Box>
            </AssessmentLayout>
            { !isCompleted && (
                <AssessmentButtonAction saveAction={handleSubmit} secondButton={"Clear"} secondAction={handleClickClear} isLoading={false}/>
            )}
            <ClearConfirmation isDialogOpen={isCancelDialogOpen} handleCloseDialog={() => setIsCancelDialogOpen(false)} clear={handleClear}/>
            <SuccessMsg isDialogOpen={isShowPopup} handleCloseDialog={() => setIsShowPopup(false)} title={formError ? "Error" : "Success"} message={candidateId === undefined ? "Please select candidate" : formError ? "Form is incomplete" : "Success on saving candidate assessment"}/>
        </>
    )
}

export default Measurement