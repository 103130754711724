
export  const parseEventDateTime = (dateString) => {
  if ( dateString ) {
    const parts = dateString.match(/(\d{2})-(\d{2})-(\d{4}) (\d{2})(\d{2})/);

    if (parts === null) {
      throw new Error('Invalid date string format. Expected format: DD-MM-YYYY HHmm');
    }
  
    const day = parseInt(parts[1], 10);
    const month = parseInt(parts[2], 10) - 1; // Month in JavaScript Date is zero-indexed (0-11)
    const year = parseInt(parts[3], 10);
    const hours = parseInt(parts[4], 10);
    const minutes = parseInt(parts[5], 10);
  
    return new Date(year, month, day, hours, minutes);
  }
}

export const currentEventDateTimeString = () => {
  const currentDate = new Date();
  const day = String(currentDate.getDate()).padStart(2, '0');
  const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Month is zero-indexed (0-11)
  const year = currentDate.getFullYear();
  const hours = String(currentDate.getHours()).padStart(2, '0');
  const minutes = String(currentDate.getMinutes()).padStart(2, '0');

  return `${day}-${month}-${year} ${hours}${minutes}`;
}

export const yyyyMMtoMonthYear = (dateString) => {
  const dateObj = new Date(dateString);
  if (isNaN(dateObj.getTime())) {
    return dateString;
  }

  const monthNames = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ];

  const month = monthNames[dateObj.getMonth()];
  const year = dateObj.getFullYear();

  return `${month} ${year}`;
}