import { Close, FileUpload } from "@mui/icons-material";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, TextField, Typography } from "@mui/material";
import { styled } from '@mui/system';
import { useState } from "react";

function AttachmentsModal( props ) {
  const { open, handleClose, handleSubmit } = props;
  
  const [formValues, setFormValues] = useState({});
  const [formErrors, setFormErrors] = useState({});

  
  const OverflowTypography = styled(Typography)`
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  `;

  const standardizeFileName = (fileName) => {
    if ( !fileName ) {
      return "";
    }

    // Remove special characters and replace spaces with hyphens
    const sanitizedFileName = fileName.replace(/[^\w\s.-]/gi, '').replace(/\s+/g, '-');

    // Convert to lowercase
    const standardizedFileName = sanitizedFileName.toLowerCase();

    return standardizedFileName;
  }

  const handleOnChange = ( e ) => {
    const value = `${e.target.value}`;
    const name = `${e.target.name}`;

    if ( name ) {
      const error = formErrors[name];
      if ( error ) {
        setFormErrors({
          ...formErrors,
          [name] : ""
        });
      } 

      if ( name === "attachment" ) {
        const file = e.target.files[0];
        const file_name = standardizeFileName(file.name);

        setFormValues({
          ...formValues,
          [name] : file,
          file_name
        });
      } else {
        let newVal = value;

        if ( name === "file_name" ) {
          newVal = standardizeFileName(newVal);
        }

        setFormValues({
          ...formValues,
          [name] : newVal
        });
      }
      
    }
  }

  const handleCloseModal = () => {
    setFormErrors({});
    setFormValues({});
    handleClose();
  }

  const handleSubmitAttachment = () => {
    const attachment = formValues.attachment;
    const fileName = formValues.file_name;

    setFormErrors({
      attachment: !attachment ? "Attachment is required" : "",
      fileName: !fileName ? "File name is required" : ""
    });

    let proceed = attachment && fileName;

    const fileExt = attachment ? `.${attachment.name.split(".").pop()}` : "";

    let newFileName = fileName;
    if ( fileExt ) {
      if( !newFileName.includes(fileExt) ) {
        newFileName = `${newFileName}${fileExt}`;
      }
    }

    if (  proceed ) {
      // proceed
      handleSubmit({
        ...formValues,
        file_name: newFileName
      });
      handleCloseModal();
    }

  } 

  return(
    <Dialog open={open} 
      onClose={handleCloseModal}
      maxWidth="xs"
      fullWidth>
      <DialogTitle>
        <Box display="flex" alignItems="center" justifyContent="space-between" pt={1}>
          <Typography variant="h5" fontSize="20px" fontWeight="medium" color="#4C4C50" mb={-3}>Add Attachment</Typography>
          <IconButton onClick={handleCloseModal}>
            <Close />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box height="10px"></Box>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box display="flex" alignItems="center">
              <Button
                variant="outlined"
                component="label"
                size="medium"
                sx={{ mr: 2, minWidth: "120px" }}
                >
                <FileUpload />
                Add File
                <input
                  name="attachment"
                  key={new Date().toISOString()}
                  accept=".doc, .docx, .xlsx, .pdf, .xl application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document, .jpg, .png"
                  onChange={handleOnChange}
                  type="file"
                  hidden
                />
              </Button>
              <OverflowTypography fontSize="14px" color={formErrors["attachment"] ? "primary" : "#999"}>
                { formErrors["attachment"] 
                  ? "No file selected" 
                  :  formValues["attachment"]
                    ? formValues["attachment"].name
                    : "Please select a file"
                }
              </OverflowTypography>
            </Box>
          </Grid>
          { formValues["attachment"] && (
            <Grid item xs={12}>
              <TextField 
                name="file_name"
                label="File Name"
                placeholder="eg. birth-certificate"
                type="text"
                size="small"
                onChange={handleOnChange}
                value={formValues["file_name"]}
                error={formErrors["file_name"] ? true : false }
                fullWidth
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <TextField 
              name="description"
              label="Description"
              placeholder="Description"
              type="text"
              size="small"
              onChange={handleOnChange}
              value={formValues["description"]}
              maxRows={3}
              multiline
              fullWidth
            />
          </Grid>
          
        </Grid>
      </DialogContent>
      <Box px={2} pb={2}>
        <DialogActions spacing={3}>
          <Button
            onClick={handleCloseModal} 
            sx={{ minWidth: "90px" }}>
            Cancel
          </Button>
          <Button 
            variant="contained"
            onClick={handleSubmitAttachment}
            sx={{ minWidth: "90px" }}>
            Save
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
}

export default AttachmentsModal;