import * as React from 'react'
import { useState, useEffect } from "react";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    Tab,
    Tabs,
    TextField,
    Typography,
} from "@mui/material";
import {
    DateTimePicker,
    LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import AssessmentRender from "../AssessmentRender";
import dayjs from 'dayjs';
import EmailTemplateViewer from './EmailTemplateViewer';
import { dateToDDMMYYYHHmm } from '../../utils/date';

function ViewEvent({isDialogOpen, handleCloseDialog, handleOpenEdit,selectedRows, color}){
    const [assessmentList, setAssessmentList] = useState([]);
    const [tabIndex, setTabIndex] = useState(0)

    useEffect(()=>{
        function fetchDoc(){
            const arr = [
                { name: "Measurements", checked: false },
                { name: "Document Check", checked: false },
                { name: "Catwalk", checked: false },
                { name: "English Examinations", checked: false },
                { name: "Group Dynamics", checked: false },
                { name: "1:1 Interview", checked: false },]
                if(selectedRows){
                    if(selectedRows.event_assessment_module){
                        for(var i in arr){
                            if(selectedRows.event_assessment_module.includes(arr[i].name)){
                                arr[i].checked = true
                            }
                        }
                        setAssessmentList(arr)
                    } else {
                        setAssessmentList(arr)
                    }
                }
        }
        fetchDoc()
    },[selectedRows])

    function TabPanel(props) {
        const { children, value, index, onChange,...other } = props;
        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`${index}`}
                aria-labelledby={`${index}`}
                {...other}
            >
                {value === index && (
                    <Box>
                        <Typography>{children}</Typography>
                    </Box>
                )}
            </div>
        );
    }

    function tabProps(index) {
        return {
            id: `${index}`,
            "aria-controls": `{index}`,
        };
    }

    const handleChangeEditTabs = (e, newValue) => {
        setTabIndex(newValue);
    };

    function handleClose() {
        handleCloseDialog(false);
        setTabIndex(0)
    }

    function handleEdit(){
        setTabIndex(0)
        handleCloseDialog(false);
        handleOpenEdit()
    }

    const getRegistrationUrl = () => {
        return `${window.location.origin}/registration/${selectedRows.id}`;
    }
    
    return(
        <div>
            <Dialog
                open={isDialogOpen}
                onClose={handleClose}
                fullWidth={true}
                maxWidth={"lg"}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle sx={{ p: 2, backgroundColor: "#9BD6AC" }}>
                    <Typography variant="h6">{`EVENT #: ${selectedRows ? selectedRows.event_code : ""}`}{" "}<span style={{color:color}} >{`(${selectedRows ? selectedRows.event_status : ""})`}</span></Typography>
                    <Typography variant='subtitle1' color="black" sx={{opacity: 0.5}}>{selectedRows ? selectedRows.event_name : ""}</Typography>
                </DialogTitle>
                <Box p={1} sx={{border: 1, borderColor: "#9BD6AC", borderWidth: "3px"}}>
                    <Tabs value={tabIndex} onChange={handleChangeEditTabs}>
                        <Tab disableRipple label={"DETAILS"} {...tabProps(0)}/>
                        <Tab disableRipple label={"EMAIL TEMPLATE"} {...tabProps(1)}/>
                    </Tabs>
                </Box>
                <DialogContent sx={{ border: 1, borderColor: "#EBEBEB", m: 1, backgroundColor: "#F8F8F8" }}>
                    <TabPanel value={tabIndex} index={0}>
                        <form autoComplete="off">
                            <Box display="flex" flexDirection="row" width="100%">
                                <Box sx={{ m: 1, flexGrow: 1}}>
                                    <p style={{color: "#4C4C50"}}><strong>Created By</strong>: {selectedRows ? selectedRows.created_by : ""}</p>
                                    <p style={{color: "#4C4C50"}}><strong>Created At</strong>: {selectedRows ? dateToDDMMYYYHHmm(new Date(selectedRows?.created_at)) : ""}</p>
                                </Box>
                                <Box sx={{ m: 1, flexGrow: 1 }}>
                                    <p style={{color: "#4C4C50"}}><strong>Modified By</strong>: {selectedRows ? selectedRows.updated_by : ""}</p>
                                    <p style={{color: "#4C4C50"}}><strong>Last Modified Date</strong>: {selectedRows ? dateToDDMMYYYHHmm( new Date(selectedRows?.updated_at)) : ""}</p>
                                </Box>
                            </Box>
                            <Box display="flex" flexDirection="row" width="100%">
                                <Box sx={{flex: 2}}>
                                    <FormControl variant="standard" sx={{width: "100%", p:1}}>
                                        <TextField disabled size='small' id="name" name="name" label="Event Name" defaultValue={selectedRows ? selectedRows.event_name : ""} />
                                    </FormControl>
                                    <FormControl variant="standard" sx={{width: "100%", p:1}}>
                                        <TextField
                                            disabled
                                            size='small'
                                            id="venue"
                                            name="venue"
                                            label="Event Location"
                                            defaultValue={selectedRows ? selectedRows.event_venue : ''}
                                        />
                                    </FormControl>
                                    <FormControl variant="standard" sx={{width: "100%", p:1}}>
                                        <TextField disabled size='small' id="aoc" name="aoc" label="Company Name" defaultValue={selectedRows ? selectedRows.event_aoc : ''} />
                                    </FormControl>
                                </Box>
                                <Box sx={{flex: 1}}>
                                    <FormControl variant="standard" sx={{width: "100%", p:1}}>
                                        <TextField
                                            disabled
                                            size='small'
                                            id="jr-number"
                                            name="jrNumber"
                                            label="JR Number"
                                            defaultValue={selectedRows ? selectedRows.jr_number : ''}
                                        />
                                    </FormControl>
                                    <FormControl variant="standard" sx={{width: "100%", p:1}}>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DateTimePicker disabled ampm={false} className="date" label="Event Date Time" defaultValue={selectedRows ?  dayjs(selectedRows.event_date_time, 'DD-MM-YYYY HHmm') : ''}
                                                slotProps={{
                                                    textField: {
                                                    size: 'small'
                                                    },
                                                }}  />
                                        </LocalizationProvider>
                                    </FormControl>
                                    <FormControl variant="standard" sx={{width: "100%", p:1}}>
                                        <TextField
                                            disabled
                                            size='small'
                                            id="company-country"
                                            name="companyCountry"
                                            label="Hiring Country"
                                            defaultValue={selectedRows ? (selectedRows.hiring_country ? selectedRows.hiring_country : "-") : ''}
                                        />
                                    </FormControl>
                                </Box>
                            </Box>
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    width: "100%",
                                    ml: 1,
                                    mt: 1,
                                }}
                            >
                                <AssessmentRender list={assessmentList} formError={{error: false}} isDisabled={true} />
                            </Box>
                        </form>
                        <Box py={2} px={3}>
                            <Typography color="black" fontWeight="medium">
                                Walk-in Registration:&nbsp;
                                <a href={getRegistrationUrl()} target="_blank" 
                                    rel="noreferrer noopener"
                                    style={{ fontWeight: "normal", color: "black", textDecoration: "none"}}>
                                    {getRegistrationUrl()}
                                </a>
                            </Typography>
                        </Box>
                    </TabPanel>
                    <TabPanel value={tabIndex} index={1}>
                        <EmailTemplateViewer
                            event={selectedRows}
                        />
                    </TabPanel>
                </DialogContent>
                <DialogActions sx={{ m: 1 }}>
                    <Button onClick={handleClose}> Close </Button>
                    {selectedRows.event_status === 'Active' && <Button onClick={handleEdit} variant='contained'> Edit </Button>}
                </DialogActions>
            </Dialog>
        </div>

    )

}

export default ViewEvent