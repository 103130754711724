function isCoolingPeriod(dateString) {
  if ( dateString ) {
    try {
      const dateParts = dateString.split('-')
      const year = parseInt(dateParts[0])
      const month = parseInt(dateParts[1]) - 1
      const day = parseInt(dateParts[2])
      const inputDate = new Date(year, month, day)
    
      const today = new Date()

      if (inputDate <= today) {
        return false
      } else {
        return true
      }
    } catch(err) {
      console.error('isCoolingPeriod:', err);
      return false;
    }
  } else {
    return false
  }
}

function getAge(dob){
  console.log('dob:', dob)
  if (dob) {
    try {
      const birthDate = new Date(dob);

      if ( isNaN(birthDate.getTime()) || birthDate.getTime() === 0 ) {
        return 0;
      }

      const today = new Date();

      const timeDifferenceInMillis = today - birthDate;

      const millisecondsInYear = 1000 * 60 * 60 * 24 * 365.25;
      const ageInYears = Math.floor(timeDifferenceInMillis / millisecondsInYear);

      return ageInYears;
    } catch(err) {
      console.error('getAge:', err);
      return 0;
    }
  } else {
    return 0;
  }
}

export {
  isCoolingPeriod,
  getAge
}