import {
    FormControl,
    FormControlLabel,
    FormLabel,
    Radio,
    RadioGroup,
    TextField,
} from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { AssessmentSection } from "../layout/AssessmentLayout";

function CatwalkForm({ handleChange, handleChangeRemarks, data, disabled = false }) {
    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;
    const row = []
    const collection = data

    for (var i in collection) {
        if (collection.hasOwnProperty(i)) {
            row.push(
                <AssessmentSection width="100%">
                    <FormControl>
                        <FormLabel>Comments on {i}</FormLabel>
                        <RadioGroup
                            value={collection[i].checked}
                        >
                            <FormControlLabel
                                value={true}
                                control={
                                    <Radio
                                        disabled={disabled}
                                        icon={icon}
                                        checkedIcon={checkedIcon}
                                        name={i}
                                        onChange={handleChange}
                                    />
                                }
                                label="Acceptable"
                            />
                            <FormControlLabel
                                value={false}
                                control={
                                    <Radio
                                        disabled={disabled}
                                        icon={icon}
                                        checkedIcon={checkedIcon}
                                        name={i}
                                        onChange={handleChange}
                                    />
                                }
                                label="Not Acceptable"
                            />
                            <FormControlLabel
                                value={"not selected"}
                                control={
                                    <Radio
                                        disabled={disabled}
                                        name={i}
                                        onChange={handleChange}
                                        sx={{display: "none"}}
                                    />
                                }
                            />
                        </RadioGroup>
                        <TextField
                            disabled={disabled}
                            onChange={handleChangeRemarks}
                            value={collection[i].remarks}
                            size="small"
                            id={i}
                            name={i}
                            label="Remarks: "
                            style={{ width: "100%" }}
                        />
                    </FormControl>
                </AssessmentSection>
            )
        }
    }
    return (
        <>
            {row}
        </>
    )
}

export default CatwalkForm