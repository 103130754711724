import { Add, CheckCircleOutline, ErrorOutline, FileCopy, LanguageRounded } from "@mui/icons-material";
import { Box, CircularProgress, Container, Dialog, DialogContent, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getEventById, setAttachmentDetails } from "../lib/db_event";
import { FORM_COUNTRY_OPTIONS, FORM_GENDER_OPTIONS, FORM_SOCIAL_OPTIONS, FORM_YESNO_OPTIONS } from "../utils/registrationUtils";
import PublicPageLayout from "./layout/PublicPageLayout";
import AttachmentsModal from "./registration/AttachmentsModal";
import LanguagesModal from "./registration/LanguagesModal";
import CustomForm from "./shared/CustomForm";
import { uploadFileToStorage } from "../lib/page";
import SuccessMsg from "./SuccessMsg";
import { getFormattedDate } from "../utils/date";
import apiService from "../lib/apiService";

function RegistrationPage( props ) {

  const [event, setEvent] = useState(undefined);
  const [isLoading, setIsLoading] = useState(true);
  const [formValues, setFormValues] = useState({});
  const [keyValue, setKeyValue] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  const [templateMsg, setTemplateMsg] = useState({});

  const params = useParams();
  const { eventID } = params;

  const formFieldConfig = [
    {
      tag: "title",
      label: "My Information",
    },
    {
      xs: 12,
      md: 6,
      name: "first_name",
      label: "First Name",
      required: true,
      tag: "input"
    },
    {
      xs: 12,
      md: 6,
      name: "last_name",
      label: "Last Name",
      required: true,
      tag: "input"
    },
    {
      xs: 12,
      md: 5,
      name: "national_id",
      label: "ID Number",
      placeholder: "National ID (IC)/Government Issued ID/Passport Number",
      required: true,
      tag: "input"
    },
    {
      xs: 12,
      md: 4,
      type: "date",
      name: "dob",
      label: "Date of Birth",
      required: true,
      tag: "input"
    },
    {
      xs: 12,
      md: 3,
      name: "gender",
      label: "Gender",
      required: true,
      options: FORM_GENDER_OPTIONS,
      tag: "select",
    },
    {
      xs: 12,
      md: 5,
      name: "phone_number",
      label: "Phone Number",
      required: true,
    },
    {
      xs: 12,
      md: 7,
      name: "email",
      label: "Email Address",
      required: true,
    },
    {
      name: "linkin_url_profile",
      label: "LinkedIn Profile URL",
      tag: "input"
    },
    {
      xs: 12,
      md: 4,
      name: "city",
      label: "City",
      required: true,
    },
    {
      xs: 12,
      md: 5,
      tag: "select",
      label: "Country",
      name: "country",
      options: FORM_COUNTRY_OPTIONS,
      required: true,
    },
    {
      xs: 12,
      md: 3,
      name: "nationality",
      label: "Nationality",
      required: true
    },
    {
      tag: "div"
    },
    {
      tag: "label",
      label: "How did you hear about this job? *",
      name: "how_you_heard_about_this_job",
    },
    {
      name: "how_you_heard_about_this_job",
      label: "Your Answer",
      required: true,
      tag: "select",
      options: FORM_SOCIAL_OPTIONS
    },
    {
      tag: "label",
      label: "Have you worked at Airasia before? *",
      name: "worked_before"
    },
    {
      name: "have_you_worked_at_airasia_before",
      label: "Your Answer",
      required: true,
      tag: "select",
      options: FORM_YESNO_OPTIONS
    },
    {
      tag: "div"
    },
    {
      xs: 6,
      md: 9.5,
      tag: "title",
      label: "Working Experience",
    },
    {
      xs: 6,
      md: 2.5,
      size: "small",
      label: "",
      name: "work_applicable",
      tag: "select",
      defaultValue: "applicable",
      options: [
        { value: "applicable",  label: "Applicable" },
        { value: "not_applicable",  label: "Not Applicable" }
      ]
    },
    {
      xs: 12,
      md: 7,
      name: "company_name",
      label: "Company Name",
      required: true,
      required_if: "work_applicable",
      required_if_value: "applicable"
    },
    {
      xs: 12,
      md: 5,
      name: "job_title",
      label: "Job Title",
      required: true,
      required_if: "work_applicable",
      required_if_value: "applicable"
    },
    {
      xs: 12,
      md: 3,
      name: "from",
      label: "From",
      type: "month",
      required: true,
      required_if: "work_applicable",
      required_if_value: "applicable"
    },
    {
      xs: 12,
      md: 3,
      name: "to",
      label: "To",
      type: "month",
      required: true,
      required_if: "work_applicable",
      required_if_value: "applicable"
    },
    {
      xs: 12,
      md: 6,
      name: "location",
      label: "Location",
      required: true,
      required_if: "work_applicable",
      required_if_value: "applicable"
    },
    {
      xs: 12,
      md: 12,
      name: "role_description",
      label: "Role Description",
      multiline: true,
      required: true,
      required_if: "work_applicable",
      required_if_value: "applicable"
    },
    {
      tag: "div"
    },
    {
      tag: "title",
      label: "Languages Spoken",
      buttonText: (<>ADD LANGUAGE <Add sx={{ ml: 1 }} /></>),
      name: "language_spoken"
    },
    {
      tag: "list_item",
      label: "Language Spoken",
      name: "language_spoken",
      item_name_field: "language",
      required: true,
      listIcon: (<LanguageRounded sx={{ color: "#999" }} />)
    },
    {
      tag: "div"
    },
    {
      tag: "title",
      label: "Attachments",
      buttonText: (<>ADD ATTACHMENT <Add sx={{ ml: 1 }} /></>),
      name: "attachments",
      description: "Attach application requirements here (ie. resume, photos, etc.)"
    },
    {
      tag: "list_item",
      label: "Attachments",
      name: "attachments",
      item_name_field: "file_name",
      required: true,
      listIcon: (<FileCopy sx={{ color: "#999" }} />)
    }
  ];

  const handleCloseTemplateMsgModal = () => {
    setTemplateMsg({});
  }

  
  const handleOpenModal = (name) => {
    setKeyValue(name);
  }

  const handleCloseModal = () => {
    setKeyValue("");
  }

  const modalIsOpen = (name) => {
    return keyValue === name;
  }

  const handleSubmitAttachments = (callbackData) => {

    const att = formValues.attachments ?? [];
    
    setFormValues({
      ...formValues,
      attachments: [
        ...att,
        callbackData
      ]
    });
  }

  const handleSubmitLanguages = (callbackData) => {

    const lang = formValues.language_spoken ?? [];
    
    setFormValues({
      ...formValues,
      language_spoken: [
        ...lang,
        callbackData
      ]
    });
  }


  const refreshForm = () => {
    setFormValues({});
  }

  // eslint-disable-next-line no-unused-vars
  const handleRegister = async ( callback ) => {
    setIsSaving(true);
    try {
      const result = await apiService.registerCandidate({
        eventId: eventID,
        candidateData: {
          ...callback,
          work_experience: [
            {
              company_name: callback.company_name ?? "",
              job_title: callback.job_title ?? "",
              location: callback.location ?? "",
              to: callback.to ?? "",
              from: callback.from ?? "",
              role_description: callback.role_description ?? ""
            }
          ]
        }
      });

      try {
        const candidateID = result.data?.candidateId;
        
        const attachments = callback.attachments ?? [];

        if ( candidateID ) {
          let uploadedAttachments = [];
          for( const attachment of attachments ) {
            const file = attachment.attachment;
            const fileName = attachment.file_name;
            const description = attachment.description ?? "";

            const url = await uploadFileToStorage(`registration/${eventID}/attachments/${candidateID}`, fileName, file);
            uploadedAttachments.push({
              url: url,
              file_name: fileName,
              description,
              uploaded_at: getFormattedDate(false)
            });
          }

          // TODO: save uploadedAttachments to candidate and events
          await setAttachmentDetails( candidateID, eventID, uploadedAttachments );
        }
      } catch(_) {
        console.error(_);
      }

      if ( result.data?.hasException ) {
        setTemplateMsg({
          type: "error",
          title: "Sorry!!",
          message: "Kindly please refer to the organizer on your registration."
        });

      } else {
        if ( result.data?.exist ) {
          setTemplateMsg({
            type: "error",
            title: "Already applied",
            message: `Hi ${callback.first_name}, welcome back! It looks like you've already applied for this position. 
            Your candidate number is ${result.data.existCandidateNumber}. 
            Please approach the registration booth or any of the recruiters to confirm your presence.`
          });
        } else {
          setTemplateMsg({
            type: "success",
            title: "Successful",
            message: `Hi ${callback.first_name}, your registration was successful. 
            Your candidate number is ${result.data.candidateNumber}. 
            Please approach the registration booth or any of the recruiters to confirm your presence.`
          });
        }

        refreshForm();
      }

    } catch(e) {
      // TODO: open an error message
      console.log(e);
    }

    setIsSaving(false);
  }

  const handleFormUpdate = (callback) => {
    setFormValues(callback);
    console.log(callback)
  }

  useEffect(() => {
    const getEventDetails = async () => {
      setIsLoading(true);
      try {
        const result = await getEventById(eventID);
        
        if (result.event_status === "Active" ) {
          setEvent(result);
        } else {
          setEvent(undefined);
        }
      } catch(err) {
        console.log(`getEventDetails::error ${err}`)
      }
      setIsLoading(false);
    }
    getEventDetails();
    return () => {}
  }, [eventID])

  return(
    <PublicPageLayout contentWidth="lg" isLoading={isLoading}>
      
        {(!event && !isLoading) && (
          <Container maxWidth="sm">
            <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" minHeight="80vh" px={2} py={10} textAlign="center">
              <Box maxWidth="288px">
                <img src="/images/sessionExpired.png" alt="Session Expired" style={{ width: "100%" }} />
              </Box>
              <Typography fontSize="24px" color="#000" fontWeight="500" my={2}>Sorry!!</Typography>
              <Typography fontSize="16px" color="#000">
                Kindly please refer to the organizer on your registration.
              </Typography>
            </Box>
          </Container>
        )}
        {event && (
          <Container maxWidth="lg">
            <Box px={2} py={8}>
              <Box sx={{ display: {
                md: "flex"
              }}} mb={3}>
                <Typography fontSize="14px" lineHeight="32px" color="#000" mr={1} width={200}>You are applying for -</Typography>
                { event.event_name && (
                  <Typography fontSize="24px" lineHeight="32px" color="#000">{event.event_name ?? ""} ({event.jr_number ?? "-"})</Typography>
                )}
              </Box>
              <CustomForm
                isLoading={isLoading || isSaving}
                config={formFieldConfig}
                values={formValues}
                setKey={handleOpenModal}
                onUpdate={handleFormUpdate}
                handleSubmit={handleRegister}
                removeItemClick={(name, index) => {
                  const list = formValues[name] ?? [];
                  
                  list.splice( index, 1);

                  setFormValues({
                    ...formValues,
                    [name] : list
                  })
                }}
              />
              <AttachmentsModal
                open={modalIsOpen("attachments")}
                handleClose={handleCloseModal}
                handleSubmit={handleSubmitAttachments}
              />
              <LanguagesModal 
                open={modalIsOpen("language_spoken")}
                handleClose={handleCloseModal}
                handleSubmit={handleSubmitLanguages}
              />
              <SuccessMsg 
                handleCloseDialog={handleCloseTemplateMsgModal}
                isDialogOpen={templateMsg?.message ?? false} 
                title={
                    <Box display="flex"
                        alignItems="center">
                            { templateMsg?.type === "success" && (
                                <CheckCircleOutline color="success" />
                            )}
                            { templateMsg?.type === "error" && (
                                <ErrorOutline color="primary" />
                            )}
                            <Box ml={1}>{templateMsg?.title ?? ""}</Box>
                    </Box>
                } 
                message={templateMsg?.message ?? ""} />
              <Dialog open={isSaving} disableBackdropClick>
                <DialogContent>
                  <Box display="flex" alignItems="center" justifyContent="center" minHeight="64px" minWidth="64px">
                    <CircularProgress />
                    <Typography ml={2}>Saving candidate details...</Typography>
                  </Box>
                </DialogContent>
              </Dialog>
            </Box>
          </Container>
        )}
    </PublicPageLayout>
  );
}

export default RegistrationPage;