import * as React from 'react'
import {
    Box,
    Button,
    Dialog,
    DialogTitle,
} from "@mui/material";

function ErrorEvent({isDialogOpen,handleCloseDialog, error }){
    function handleClose(){
        handleCloseDialog(false);
    }
    return(
        <div>
            <Dialog
                open={isDialogOpen}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth={true}
                maxWidth={"sm"} >
                <DialogTitle id="alert-dialog-title">
                    {`Error ${error.type} the Event`}
                </DialogTitle>
                <Box sx={{
                    m:2,
                    pl:2,
                    pb:3
                }}>
                    {error.msg}
                </Box>
                <Button onClick={handleClose}>Close</Button>
            </Dialog>
        </div>
    )
}

export default ErrorEvent

