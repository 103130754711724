import { useEffect, useState } from "react";
import CatwalkForm from "./CatwalkForm";
import { Box, FormControl, MenuItem, Select, TextField, Typography } from "@mui/material";
import { getCandidateAssessmentResultCatwalk } from "../../lib/db_candidate";
import getTime from "../../utils/getTime";
import { setCandidateEventAssessmentComment, setCandidateEventAssessmentCatwalk } from "../../lib/page";
import ClearConfirmation from "./ClearConfirmation";
import { AssessmentButtonAction, AssessmentLayout } from "../layout/AssessmentLayout";
import SuccessMsg from "../SuccessMsg";

const INITIAL_PAYLOAD = {
    "hair/head/face/nose/ears/teeth/mouth": { checked: "not selected", remarks: '' },
    "torso/hands/arms/shoulders": { checked: "not selected", remarks: '' },
    "hips/legs/knee": { checked: "not selected", remarks: '' },
    "Overall Body": { checked: "not selected", remarks: '' },
}

const INITIAL_SCORE = {
    status: '',
    comment: ''
}

function Catwalk({ candidateId, eventId, username, isComment, isCompleted = false }) {
    const [isCancelDialogOpen, setIsCancelDialogOpen] = useState(false)
    const [payload, setPayload] = useState(INITIAL_PAYLOAD)

    const [score, setScore] = useState(INITIAL_SCORE)
    const [errorForm, setErrorForm] = useState({
        commentError: {
            msg: "",
            bool: false,
        },
        overallScoreError: {
            msg: "",
            bool: false
        }
    })
    const [formError, setFormError] = useState(false)
    const [isShowPopup, setIsShowPopup] = useState(false)

    useEffect(() => {
        async function getResult() {
            const res = await getCandidateAssessmentResultCatwalk(
                candidateId,
                eventId
            );
            if (res !== undefined) {
                setPayload({
                    "hair/head/face/nose/ears/teeth/mouth": { checked: res.upper_body.acceptable, remarks: res.upper_body.comment ? res.upper_body.comment : "" },
                    "torso/hands/arms/shoulders": { checked: res.middle_body.acceptable, remarks: res.middle_body.comment ? res.middle_body.comment : "" },
                    "hips/legs/knee": { checked: res.lower_body.acceptable, remarks: res.lower_body.comment ? res.lower_body.comment : ""},
                    "Overall Body": { checked: res.overall_body.acceptable, remarks: res.overall_body.comment ? res.overall_body.comment : ""},
                })
                setScore({
                    status: res.overall_score,
                    comment: res.overall_comment
                })
            } else {
                setPayload(INITIAL_PAYLOAD)
                setScore(INITIAL_SCORE)
            }
        }
        if (candidateId !== undefined) {
            getResult();
        } else {
            setPayload(INITIAL_PAYLOAD)
            setScore(INITIAL_SCORE)
        }
    }, [candidateId, eventId])

    const handleChange = (e) => {
        const value = e.target.value === 'true'
        setPayload({
            ...payload,
            [e.target.name]: {
                checked: value,
                remarks: payload[e.target.name].remarks
            }
        })

    };

    const handleChangeRemarks = (e) => {
        setPayload({
            ...payload,
            [e.target.name]: {
                checked: payload[e.target.name].checked,
                remarks: e.target.value
            }
        })
    }

    function handleScore(event) {
        var comment = score.comment
        if(event.target.value === "Passed"){
            comment = ""
        } 
        setScore({
            status: event.target.value,
            comment: comment,
        })
        if(event.target.value.length > 0){
            setErrorForm({
                ...errorForm,
                overallScoreError: {
                    bool: false,
                    msg: ""
                }
            })
        }
    }

    function handleComment(event) {
        setScore({
            status: score.status,
            comment: event.target.value
        })

        if (event.target.value.length !== 0) {
            setErrorForm({
                ...errorForm,
                commentError: {
                    msg: "",
                    bool: false
                }
            })
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        if (candidateId === undefined) {
            setFormError(true)
            setIsShowPopup(true)
            return
        }
        if(score.status.length === 0) {
            setErrorForm({
                ...errorForm,
                overallScoreError: {
                    bool: true,
                    msg: "Overall Score Required"
                }
            })
            setFormError(true)
            setIsShowPopup(true)
            return
        }
        if (score.status === "D" && score.comment.length === 0) {
            setErrorForm({
                ...errorForm,
                commentError: {
                    msg: "Missing Remarks",
                    bool: true
                }
            })
            setFormError(true)
            setIsShowPopup(true)
        } else {
            const form = {
                upper_body: {
                    label: "hair/head/face/nose/ears/teeth/mouth",
                    acceptable: payload["hair/head/face/nose/ears/teeth/mouth"].checked,
                    comment: payload["hair/head/face/nose/ears/teeth/mouth"].remarks
                },
                middle_body: {
                    label: "torso/hands/arms/shoulders",
                    acceptable: payload["torso/hands/arms/shoulders"].checked,
                    comment: payload["torso/hands/arms/shoulders"].remarks
                },
                lower_body: {
                    label: "hips/legs/knee",
                    acceptable: payload["hips/legs/knee"].checked,
                    comment: payload["hips/legs/knee"].remarks
                },
                overall_body: {
                    label: "Overall body",
                    acceptable: payload["Overall Body"].checked,
                    comment: payload["Overall Body"].remarks
                },
                overall_score: score.status,
                overall_comment: score.comment
            }
            const commentForm = {
                id: new Date().getTime(),
                comment: score.comment,
                module: "Catwalk",
                overall_score: score.status,
                created_by: username,
                created_at: getTime()
            }

            try{
                await setCandidateEventAssessmentCatwalk(candidateId, eventId, form)
                await setCandidateEventAssessmentComment(candidateId, eventId, commentForm)
                isComment()
                setFormError(false)
                setIsShowPopup(true)
            } catch (error) {
                alert(`Error saving Catwalk: ${error}`)
            }
        }
    }

    const handleClickClear = () => {
        setIsCancelDialogOpen(!isCancelDialogOpen)
    }
    const handleClear = () => {
        setPayload(INITIAL_PAYLOAD)
        setScore(INITIAL_SCORE)
    }

    return (
        <>
            <AssessmentLayout title={"Catwalk"}>
                <CatwalkForm handleChange={handleChange} handleChangeRemarks={handleChangeRemarks} data={payload} disabled={isCompleted} />
            </AssessmentLayout>
            <Box
                sx={{
                    mt: 2,
                    mb: 2,
                    boxShadow: "0px 1px 4px 0px #00000029",
                    mr: -3,
                    ml: -3,
                    p: 3,
                    width: "70%",
                    textAlign: "left",
                }}
            >
                <Typography>Overall Score</Typography>
                <FormControl sx={{ minWidth: 250 }}>
                    <Select
                        disabled={isCompleted}
                        name='score'
                        value={score.status}
                        onChange={handleScore}
                        error={errorForm.overallScoreError.bool}
                    >
                        <MenuItem value={""}>-</MenuItem>
                        <MenuItem value={"A"}>Airasia Media Ambassador, AA Potential Face</MenuItem>
                        <MenuItem value={"B"}>Beautiful/Handsome</MenuItem>
                        <MenuItem value={"C"}>Can be considered </MenuItem>
                        <MenuItem value={"D"}>Does not qualify</MenuItem>
                        <MenuItem value={"KIV"}>KIV</MenuItem>
                    </Select>
                    {errorForm.overallScoreError.bool ? <small style={{color: "red"}}>{errorForm.overallScoreError.msg}</small> : ""}
                </FormControl>
                <FormControl variant="standard" sx={{ width: "100%", pt: 1 }}>
                    {(score.status === 'D' || score.status === 'KIV') && <TextField
                        required
                        onChange={handleComment}
                        value={score.comment}
                        size="small"
                        id="comment"
                        name="comment"
                        label="Remarks"
                        error={errorForm.commentError.bool}
                        helperText={errorForm.commentError.msg}
                        disabled={isCompleted}
                    />}
                </FormControl>
            </Box>
            { !isCompleted && (
                <AssessmentButtonAction saveAction={handleSubmit} secondButton={"Clear"} secondAction={handleClickClear} isLoading={false}/>
            )}
            <ClearConfirmation isDialogOpen={isCancelDialogOpen} handleCloseDialog={() => setIsCancelDialogOpen(false)} clear={handleClear}/>
            <SuccessMsg isDialogOpen={isShowPopup} handleCloseDialog={() => setIsShowPopup(false)} title={formError ? "Error" : "Success"} message={candidateId === undefined ? "Please select candidate" : formError ? "Form is incomplete" : "Success on saving candidate assessment"}/>
        </>
    );
}

export default Catwalk;
