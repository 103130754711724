const updateDocumentMY = (res) => {
    return {
        "NRIC (ID)": res.required_document['NRIC (ID)'] ,
        "Passport": res.required_document['Passport'],
        "Local ID": res.required_document['Local ID'],
    }
}

const updateExperienceMY = (res) => {
    return {
        "Attestation card for airline crew": res.required_document_for_experienced['Attestation card for airline crew'] ,
        "Copy of latest education certificate for initial": res.required_document_for_experienced['Copy of latest education certificate for initial'],
        "Full body photo": res.required_document_for_experienced['Full body photo'],
        "Close up photo (portrait shot)":res.required_document_for_experienced['Close up photo (portrait shot)'],
        "Latest CV": res.required_document_for_experienced['Latest CV'],
        "Airline certificate": res.required_document_for_experienced['Airline certificate']
    }
}

const updateDocumentIDN = (res) => {
    return { 
        "Copy of Passport": res.required_document['Copy of Passport'] ,
        "ID / KTP": res.required_document['ID / KTP'] ,
        "Copy of latest education certificate for initial": res.required_document['Copy of latest education certificate for initial'] ,
        "Full body photo": res.required_document['Full body photo'] ,
        "Close up photo (portrait shot)": res.required_document['Close up photo (portrait shot)'] ,
        "CV": res.required_document['CV'] ,
        "3rd doses of Covid Vaccination": res.required_document['3rd doses of Covid Vaccination'],
    }
}

const updateExperienceIDN = (res) => {
    return {
        "Copy of FA Certificate": res.required_document_for_experienced['Copy of FA Certificate']  ,
        "Copy of reference letter from previous company": res.required_document_for_experienced['Copy of reference letter from previous company']  ,
        "Copy of latest education certificate for initial": res.required_document_for_experienced['Copy of latest education certificate for initial'] ,
        "Full body photo": res.required_document_for_experienced['Full body photo'] ,
        "Close up photo (portrait shot)": res.required_document_for_experienced['Close up photo (portrait shot)'] ,
        "CV": res.required_document_for_experienced['CV'] ,
        "Copy of passport": res.required_document_for_experienced['Copy of passport']  ,
        "3rd doses of Covid Vaccination": res.required_document_for_experienced['3rd doses of Covid Vaccination'],
    }
}
const updateDocumentPH = (res) => {
    return { 
        "Passport": res.required_document['Passport'] ,
        "National IDs/Governement IDs": res.required_document['National IDs/Governement IDs'] ,
    }
}

const updateExperiencePH = (res) => {
    return  {
        "Passport": res.required_document_for_experienced['Passport'] ,
        "National IDs/Governement-issued IDs": res.required_document_for_experienced['National IDs/Governement-issued IDs'] ,
    }
}

const updateDocumentTHA = (res) => {
    return { 
        "Copy of Passport": res.required_document['Copy of Passport'] ,
        "Copy of ID Card": res.required_document['Copy of ID Card']  ,
        "Copy of Hourse Registration": res.required_document['Copy of Hourse Registration']  ,
        "TOEIC Score/Certificate (650 points)": res.required_document['TOEIC Score/Certificate (650 points)']  ,
        "For Male: Military Service Certificate": res.required_document['For Male: Military Service Certificate']  ,
        "Chinese-speaking: HSK Certificate": res.required_document['Chinese-speaking: HSK Certificate']  ,
        "Full body photo": res.required_document['Full body photo']  ,
        "Passport size photo": res.required_document['Passport size photo']  ,
        "CV": res.required_document['CV']  ,
        "Transcript in English version": res.required_document['Transcript in English version']  ,
    }
}

const updateExperienceTHA = (res) => {
    return {
        "Ex-crew must show certificate or crew ID from previous employer": res.required_document_for_experienced['Ex-crew must show certificate or crew ID from previous employer'] ,
        "Same requirements as that of the above": res.required_document_for_experienced['Same requirements as that of the above'] ,
    }
}

const updateDocument = (country, res, type) => {
    let updatedDocs = {}
    if(country === "Malaysia"){
        if(type === "Document"){
            updatedDocs = updateDocumentMY(res)
        } else if (type === "Experience"){
            updatedDocs = updateExperienceMY(res)
        }
    } else if (country === "Indonesia"){
        if(type === "Document"){
            updatedDocs = updateDocumentIDN(res)
        } else if (type === "Experience"){
            updatedDocs = updateExperienceIDN(res)
        }
    } else if (country === "Philippines"){
        if(type === "Document"){
            updatedDocs = updateDocumentPH(res)
        } else if (type === "Experience"){
            updatedDocs = updateExperiencePH(res)
        }
    } else if (country === "Thailand"){
        if(type === "Document"){
            updatedDocs = updateDocumentTHA(res)
        } else if (type === "Experience"){
            updatedDocs = updateExperienceTHA(res)
        }
    }
    return updatedDocs
}

export default updateDocument