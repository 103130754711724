import { red } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';

const defaultTheme = createTheme()

const theme = createTheme({
  shadows: [...defaultTheme.shadows].map(()=> 'none'),
  palette: {
    background: {
      paper: '#F8F8F8',
    },
    primary: {
      main: '#c92d21',
    },
    secondary: {
      main: '#fbebe9',
    },
    error: {
      main: red.A400,
    },
    menu: {
      main: '#FFFFFF'
    },
    menuItem: {
      main: '#75767A'
    },
    csvButton: {
      main: '#4B4FA6'
    }
  },
  components: {
    MuiDrawer: {
      styleOverrides: {
        paper: {
          background: '#FFFFFF'
        }
      }
    },
    MuiButton: {
      variants: [
        {
          props: { size: "rating" },
          style: { fontSize: 14, padding: "4px 2px", width: "50px", height: "50px",borderRadius: "50%",'&:hover': {color: "black"} }
        }
      ]
    }
  },
  typography: {
    button: {
      textTransform: "none"
    },
    fontFamily: `"DM Sans", "sans-serif"`
  }
});

export default theme;