import { useEffect, useState } from "react"
import { useNavigate, useLocation } from 'react-router-dom'
import { getCandidateEventAttended } from "../../lib/page"
import { DataGrid } from "@mui/x-data-grid"
import { Box, Button, CircularProgress, Typography } from "@mui/material"
import RenderCellExpand from "../../utils/RenderCellExpand"
import { getEventInfoById } from "../../lib/db_event"

function CandidateAttendedEvent({ candidateId, candidateName }) {
    const navigate = useNavigate()
    const location = useLocation()

    let username
    let url
    if(!location.state){
        const token = sessionStorage.getItem("access_token")
        if(token){
            username = sessionStorage.getItem("user_name")
            url = sessionStorage.getItem("user_url")
        } else {
            navigate("/")
        }
    } else {
        username = location.state.name
        url = location.state.displayPic
    }
    const [rowsEvent, setRowsEvent] = useState([])
    const [selectedRows, setSelectedRows] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [isRedirecting, setIsRedirecting] = useState(false)

    useEffect(() => {
        setIsLoading(true)
        async function getAttendedEvent() {
            const listEvent = await getCandidateEventAttended(candidateId)
            setRowsEvent(listEvent)
        }
        if (candidateId !== undefined){
            getAttendedEvent()
        } 
        setIsLoading(false)
    }, [candidateId])
    

    const handleSelectionChange = (selection) =>{
        const index = rowsEvent.findIndex(object => {
            return object.id === selection[0]
        })
        setSelectedRows(rowsEvent[index])
        console.log(selectedRows);
    }

    const LinkFullResult = (props) => {
        const handleClose = async (e) => {
            setIsRedirecting(true)
            //
            const {
                candidates = [],
            } = await getEventInfoById(props.row.event_id);
            const state = {
                name: username,
                displayPic: url,
                candidate: candidates,
                candidateNumber: props.row.candidate_number, 
                candidateId: candidateId,
            };
            const queryString = Object.keys(state).map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(state[key])}`).join('&');
            setIsRedirecting(false)
            window.open(`/events/${props.row.event_id}/assessment?${queryString}`, '_blank');
        }
        return (
            <Button disableRipple variant="text" onClick={handleClose} sx={{color: "black", textDecoration: "none"}}><u>{props.row.candidate_number}</u></Button>
        )
    }

    const columnsEvent = [
        { field: 'id',headerClassName: 'theme--header', headerName: 'No', maxWidth: 30, filterable: false, headerAlign: 'center' },
        { field: 'application_date',headerClassName: 'theme--header', headerName: 'Application Date', minWidth: 100,flex:1, headerAlign: 'center' },
        { field: 'jr_number',headerClassName: 'theme--header', headerName: 'JR Number', flex:1, renderCell: RenderCellExpand,minWidth: 100 },
        { field: 'job_description',headerClassName: 'theme--header', headerName: 'Job Description', renderCell: RenderCellExpand,flex:3, minWidth: 'fit-content'},
        { field: 'job_location',headerClassName: 'theme--header', headerName: 'Job Location', renderCell: RenderCellExpand,flex:1, minWidth: 100 },
        { field: 'company_name',headerClassName: 'theme--header', headerName: 'Company Name',renderCell: RenderCellExpand ,flex:1, minWidth: 100 },
        { field: 'source',headerClassName: 'theme--header', headerName: 'Source',  filterable: false, renderCell: RenderCellExpand, flex:1, minWidth: 100 },
        { field: 'workday_status',headerClassName: 'theme--header', headerName: 'Workday Status', filterable: false, renderCell: RenderCellExpand,flex:1, minWidth: 100 },
        { field: 'candidate_number',headerClassName: 'theme--header', headerName: 'E.Candidate #',  filterable: false, disableClickEventBubbling: true, flex:1, minWidth: 100, renderCell: LinkFullResult },
        { field: 'assessment_status',headerClassName: 'theme--header', headerName: 'Assessment Status',  filterable: false,renderCell: RenderCellExpand,flex:1,minWidth: 100, headerAlign: 'center' },
    ]

    return (
        <>
            { !isRedirecting ? 
            <Box sx={{
                height: "auto", width: "100%",
                '& .theme--header': {
                    backgroundColor: '#F1F1F1',
                    opacity: 0.5,
                },
                m: -1,left: 0, mt: "-20px", right: 0,
            }}>
                <div style={{ flexGrow: 1 }}>
                    {(rowsEvent.length > 0 && isLoading === false) ? <DataGrid
                        sx={{
                            "& .MuiDataGrid-columnHeader": {
                                // Forced to use important since overriding inline styles
                                height: "unset !important"
                            },
                            "& .MuiDataGrid-columnHeaders": {
                                // Forced to use important since overriding inline styles
                                maxHeight: "168px !important"
                            },
                            '.MuiDataGrid-columnSeparator': {
                                display: 'none',
                            },
                            "& .MuiDataGrid-cell": {
                                border: 1,
                                borderColor: "#D9DBE0"
                            },
                        }}
                        rows={rowsEvent}
                        columns={columnsEvent}
                        autoHeight={true}
                        onRowSelectionModelChange={handleSelectionChange}
                        getRowClassName={(params) => {
                            return typeof params.row !== "object" ? "" : "theme--rows"
                        }}
                        disableColumnMenu /> :  <CircularProgress/>}
                </div>
            </Box>
            :
            <Box p={2}>
                <Typography variant='h6'>Redirecting to {candidateName}'s results</Typography> <CircularProgress/>
            </Box>
            }
        </>
    )
}

export default CandidateAttendedEvent