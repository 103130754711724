import { Box, Container, LinearProgress, Typography } from "@mui/material";


const PublicPageLayout = (props) => {
  const { title, children, contentWidth = "xl", isLoading } = props;

  return(
    <Box display="flex" flexDirection="column" position="relative" minHeight="100vh" bgcolor="#F8F8F8">
      <PublicHeader isLoading={isLoading} contentWidth={contentWidth} title={title} />
      <Box flex="1" pt="64px">
        {children}
      </Box>
      <PublicFooter contentWidth={contentWidth} />
    </Box>
  ) 
}

const PublicHeader = (props) => {
  const { logo = "/images/logoHeader.png", title = "RedHire", contentWidth = "xl", isLoading } = props;

  return(
    <Box bgcolor="#FFF" boxShadow="0px 4px 4px 0px #00000014" position="fixed" top={0} left={0} right={0} zIndex={99}>
      <Container maxWidth={contentWidth}>
        <Box height="64px" display="flex" alignItems="center" px={2}>
          <Box height="32px" mr={2}>
            <img src={logo} alt="aa logo" style={{ width: "auto", height: "100%"}} />
          </Box>
          <Typography fontSize="20px" fontWeight="700">{title}</Typography>
        </Box>
      </Container>
      { isLoading && (<LinearProgress />)}
    </Box>
  )
}

const PublicFooter = (props) => {
  const { text = "2023 Airasia Berhad. All rights reserved.", contentWidth = "xl" } = props;

  return(
    <Box bgcolor="#FFF" boxShadow="0px -4px 4px rgba(0, 0, 0, 0.08)">
      <Container maxWidth={contentWidth}>
        <Box height="56px" display="flex" alignItems="center" px={2}>
          <Typography color="#75767A" variant="body2"> © {text} </Typography>
        </Box>
      </Container>
    </Box>
  )
}

export default PublicPageLayout;