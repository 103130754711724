import { Box, Button, CircularProgress, Container, Typography, useTheme } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { getDashboardDetails } from '../lib/page';
import RenderCellExpand from '../utils/RenderCellExpand';
import CandidateToolbarFilter from './candidate/CandidateToolbarFilter';
import ViewCandidate from './candidate/ViewCandidate';
import TableDetail from './eventDetail/TableDetail';
import Footer from './menu/Footer';
import MenuTab from './menu/MenuTab';
import { convertEventCandidatesToCSVString, getEventInfoById } from '../lib/db_event';
import { Download } from '@mui/icons-material';
import { INITIAL_FILTER } from '../utils/candidateFilterUtil';
import { authenticateUser } from '../lib/userAuthenticate';
import { LoadingWithMsg } from './shared/Loading';

function EventDetail(){
    const navigate = useNavigate()
    const location = useLocation()
    const [user, setUser] = useState({
        username: "",
        url: ""
    })

    const {eventID} = useParams()

    const [event, setEvent] = useState({
        eventInfo: {},
        dashboard: {}
    })
    const [selectedRows, setSelectedRows] = useState([])
    const [rowsCandidates, setRowsCandidate] = useState([])
    const [rowsDetail, setRowsDetail] = useState([])
    // const [allJrNumber, setAllJrNumber] = useState([])
    const [allCandidates, setAllCandidates] = useState([])
    const [isViewDialogOpen, setIsViewDialogOpen] = useState(false)
    const [filterHolder, setFilterHolder] = useState(INITIAL_FILTER)
    const [isCSV, setIsCSV] = useState(false)

    useEffect(() => {
        authenticateUser(setUser, location, navigate)
    }, [location, navigate])

    const isObjectNotEmpty = (object) => {
        return Object.keys(object).length !== 0
    }

    const [dashboardDetails, setDashboardDetails] = useState({});
    

    const getEventDetails = useCallback(async () => {
        const {
            candidates = [],
            eventInfo = {}
        } = await getEventInfoById(eventID, filterHolder);

        const db = await getDashboardDetails(eventID, eventInfo, candidates);

        setDashboardDetails(db)
        setAllCandidates(candidates)
        setRowsCandidate(candidates)
        setEvent(eventInfo)
       
        setRowsDetail([eventInfo])

        // eslint-disable-next-line react-hooks/exhaustive-deps  
    },[eventID])

    useEffect(()=>{
        // getListJrNumber()
        getEventDetails()
    },[getEventDetails])

    // const getListJrNumber  = async () => {
    //     const arrJrNumber = await getAllJrNumberListFromCandidate()
    //     arrJrNumber.unshift("")
    //     setAllJrNumber(arrJrNumber)
    // }

    function OpenFirstName(props) {
        const handleClose = (e) => {
            setIsViewDialogOpen(!isViewDialogOpen)
        }
        return (
            <Button disableRipple variant="text" onClick={handleClose} sx={{color: "black", textDecoration: "none", display: "block", textAlign: "left", whiteSpace: 'normal'}}>
                <Typography fontSize={'0.875rem'} lineHeight={'1rem'} color={'black'} fontWeight={500} maxWidth={'100%'}>{props.row.first_name}</Typography>
            </Button>
        )
    }
    function OpenLastName(props) {
        const handleClose = (e) => {
            setIsViewDialogOpen(!isViewDialogOpen)
        }
        return (
            <Button disableRipple variant="text" onClick={handleClose} sx={{color: "black", textDecoration: "none", display: "block", textAlign: "left", whiteSpace: 'normal'}}>
                <Typography fontSize={'0.875rem'} lineHeight={'1rem'} color={'black'} fontWeight={500} maxWidth={'100%'}>{props.row.last_name}</Typography>
            </Button>
        )
    }

    const handleSelectionChange = (selection) => {
        if(selection.length === 0){
            setSelectedRows([])
            return
        }
        const index = rowsCandidates.findIndex(object => {
            return object.id === selection[0]
        })
        setSelectedRows(rowsCandidates[index])
    }

    const handleAssessment = () => {
        navigate(`/events/${eventID}/assessment`, {
            state: {
                name: user.username,
                displayPic: user.url,
                candidate: allCandidates,
                eventNumber: event.event_id
            }
        })
    }

    const LinkFullResult = (props) => {
        const handleClose = async (e) => {
            navigate(`/events/${eventID}/assessment`, {
                state: {
                    name: user.username,
                    displayPic: user.url,
                    candidate: allCandidates,
                    candidateNumber: props.row.candidate_number, 
                    candidateId: props.row.id,
                    eventNumber: event.event_id
                }
            })
        }
        return (
            <Button disableRipple variant="text" onClick={handleClose} sx={{color: "black", textDecoration: "none"}}>{props.row.candidate_number}</Button>
        )
    }

    const columnsCandidate = [
        { field: 'candidate_number',headerClassName: 'theme--header', headerName: 'Candidate #', minWidth: 150,flex:2, filterable: false, headerAlign: 'center', renderCell: LinkFullResult },
        { field: 'first_name',headerClassName: 'theme--header', headerName: 'First Name', minWidth: 150,flex:2, filterable: false, renderCell: OpenFirstName, headerAlign: 'center' },
        { field: 'last_name',headerClassName: 'theme--header', headerName: 'Last Name', minWidth: 150,flex:2, headerAlign: 'center', renderCell: OpenLastName },
        { field: 'application_date',headerClassName: 'theme--header', headerName: 'Application Date', width: 150},
        { field: 'no_of_events',headerClassName: 'theme--header', headerName: '# of events', width: 100 },
        { field: 'gender',headerClassName: 'theme--header', headerName: 'Gender',renderCell: RenderCellExpand ,width: 80 },
        { field: 'city',headerClassName: 'theme--header', headerName: 'City', width: 150, filterable: false, renderCell: RenderCellExpand },
        { field: 'country',headerClassName: 'theme--header', headerName: 'Country', width: 150, filterable: false, renderCell: RenderCellExpand },
        { field: 'source',headerClassName: 'theme--header', headerName: 'Source', width: 100, filterable: false, renderCell: RenderCellExpand },
        { field: 'assessment_status',headerClassName: 'theme--header', headerName: 'Assessment Status', width: 150, renderCell: RenderCellExpand },
        { field: 'registration_status',headerClassName: 'theme--header', headerName: 'Registration Status', width: 150, renderCell: RenderCellExpand }
    ]
    const columnsDetails = [
        { field: 'event_name',headerClassName: 'theme--header', headerName: 'Event Name', minWidth: 150,flex:2, filterable: false, headerAlign: 'center', renderCell: RenderCellExpand  },
        { field: 'jr_number',headerClassName: 'theme--header', headerName: 'JR No.', minWidth: 100,flex:1, headerAlign: 'center'},
        { field: 'company_name',headerClassName: 'theme--header', headerName: 'Company',flex:1, minWidth: 100 },
        { field: 'event_location',headerClassName: 'theme--header', headerName: 'Location',flex:1, minWidth: 100},
        { field: 'event_date_time',headerClassName: 'theme--header', headerName: 'Event Date Time',flex:1, minWidth: 100 },
        { field: 'event_creation_date',headerClassName: 'theme--header', headerName: 'Created At',flex:1, minWidth: 100 },
        { field: 'total_assessment',headerClassName: 'theme--header', headerName: 'Total Assessment',flex:1, minWidth: 100, filterable: false, renderCell: RenderCellExpand },
    ]

    const CustomToolbar = useMemo(() => {
        return function CustomToolbarComponent() {
            const [filter, setFilter] = useState(filterHolder)

            const handleChange = useCallback((e, val) => {
                setFilter(prevFilter => ({
                    ...prevFilter,
                    [e.target.name]: e.target.value
                }))
            }, [])

            const handleSelect = useCallback((name, val) => {
                setFilter(prevFilter => ({
                    ...prevFilter,
                    [name]: val
                }))
            }, [])

            const handleFilter = useCallback(async (e, val) => {
                e.preventDefault()

                if (e.target.id === "applyFilter") {
                    setFilterHolder(filter)
                } else if (e.target.id === "clearFilter") {
                    setFilterHolder(INITIAL_FILTER)
                }

                const { candidates = [] } = await getEventInfoById(
                    eventID,
                    e.target.id === "clearFilter"
                        ? INITIAL_FILTER
                        : filter
                );
                setRowsCandidate(candidates);
            }, [filter])

            return (
                <CandidateToolbarFilter
                    forEvent={true}
                    handleChange={handleChange}
                    handleSelect={handleSelect}
                    allJrNumber={[]}
                    handleFilter={handleFilter}
                    holder={filterHolder}
                />
            )
        }
    }, [filterHolder, eventID, setFilterHolder, setRowsCandidate])

    const exportAsCSV = async() => {
        setIsCSV(true)
        await convertEventCandidatesToCSVString(rowsCandidates, eventID)
        setIsCSV(false)
    }

    const theme = useTheme();
    return(
        <div style={{ minHeight: "100vh" }}>
            <MenuTab username={user.username} url={user.url}/>
            {isObjectNotEmpty(dashboardDetails) ? <><Box mt={2} display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" paddingLeft={`calc(${theme.spacing(7)} + 15px)`} paddingRight={2} marginBottom={2}>
                <Typography variant='h5'>Event ID - {event.event_id} {event.event_status === "Completed" ? "(Completed)" : ""}</Typography>
                <Box display="flex" gap={1}>
                    <Button onClick={exportAsCSV} variant="outlined" size="large">
                        <Download />
                        <Box ml={1}>
                            Download CSV
                        </Box>
                    </Button>
                    <Button variant='contained' size='large' onClick={handleAssessment}>
                        <Box>Assessment</Box>
                    </Button>
                </Box>
            </Box>
            <Container disableGutters maxWidth="100%" component="main" sx={{textAlign: "center", justifyContent: 'center', pl: `calc(${theme.spacing(7)} + 5px)`, marginBottom: "56px"}}>
                <Box p={2}>
                    <Box sx={{
                            height: "auto", width: "100%",
                            '& .theme--header': {
                                backgroundColor: '#F1F1F1',
                                opacity: 0.5,
                            },
                            p: 3, pb:0, pt:0
                        }}>
                        <div style={{ flexGrow: 1 }}>
                            <DataGrid
                                sx={{
                                    "& .MuiDataGrid-columnHeader": {
                                        // Forced to use important since overriding inline styles
                                        height: "unset !important"
                                    },
                                    "& .MuiDataGrid-columnHeaders": {
                                        // Forced to use important since overriding inline styles
                                        maxHeight: "168px !important"
                                    },
                                    '.MuiDataGrid-columnSeparator': {
                                        display: 'none',
                                      },
                                      "& .MuiDataGrid-cell": {
                                        border: 1,
                                        borderColor: "#D9DBE0"
                                        },
                                }}
                                rows={rowsDetail}
                                columns={columnsDetails}
                                autoHeight={true}
                                disableColumnMenu
                                disableRowSelectionOnClick
                                hideFooter />
                        </div>
                    </Box>        
                    <TableDetail data={dashboardDetails} eventId={eventID}/>
                    <Box sx={{
                        height: "auto", width: "100%",
                        '& .theme--header': {
                            backgroundColor: '#F1F1F1',
                            opacity: 0.5,
                        },
                    }}>
                        <div style={{ flexGrow: 1 }}>
                            <DataGrid
                                sx={{
                                    "& .MuiDataGrid-columnHeader": {
                                        // Forced to use important since overriding inline styles
                                        height: "unset !important"
                                    },
                                    "& .MuiDataGrid-columnHeaders": {
                                        // Forced to use important since overriding inline styles
                                        maxHeight: "168px !important"
                                    },
                                    '.MuiDataGrid-columnSeparator': {
                                        display: 'none',
                                      },
                                      "& .MuiDataGrid-cell": {
                                        border: 1,
                                        borderColor: "#D9DBE0"
                                        },
                                      p: 3
                                }}
                                rows={rowsCandidates}
                                columns={columnsCandidate}
                                autoHeight={true}
                                onRowSelectionModelChange={handleSelectionChange}
                                getRowClassName={(params) => {
                                    return typeof params.row !== "object" ? "": "theme--rows"
                                }}
                                slots={{
                                   toolbar: CustomToolbar
                                }}
                                slotsProps={{
                                    panel: {
                                        placement: "bottom-end",
                                    }
                                }}
                                disableColumnMenu />
                        </div>
                    </Box>
                </Box>
                <LoadingWithMsg isLoading={isCSV} handleStopLoading={() => setIsCSV(false)} msg={"Gathering data for CSV"}/>
            </Container></> :
            <Container disableGutters maxWidth="100%" component="main" sx={{alignItems: "center", justifyContent: 'center',  pl: `calc(${theme.spacing(7)} + 5px)`}}>
                <Box p={2}>
                    <Typography variant='h6'>Finding Event</Typography> <CircularProgress/>
                </Box>
            </Container>}
            <ViewCandidate isDialogOpen={isViewDialogOpen} handleCloseDialog={()=>setIsViewDialogOpen(false)} id={selectedRows.id}/>
            <Footer/>
        </div>
    )
}

export default EventDetail