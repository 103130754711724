import * as React from "react";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    TextField,
    Typography,
} from "@mui/material";
import { useEffect } from "react";
import { useState } from "react";
import { overwriteCandidateBlacklistStatus, overwriteCandidateCoolingPeriod } from "../../lib/page";

//onChange={handleChange} error={formError.name.error} helperText={formError.name.msg}

function BlacklistCandidate({ isDialogOpen, handleCloseDialog, candidate, isUplift }){
    const [error, setError] = useState({
        err: false,
        msg: ""
    })
    const [remarks, setRemarks] = useState("")
    const [exception, setException] = useState("")
    useEffect(()=>{
        if(candidate.exception_status === "Blacklisted"){
            setException("Blacklist")
        } else if (candidate.exception_status === "Under Cooling Period"){
            setException("Cooling Period")
        } else {
            setException("")
        }
    },[candidate.exception_status])

    function getTodayDate() {
        const today = new Date()
        const year = today.getFullYear()
        const month = String(today.getMonth() + 1).padStart(2, '0')
        const day = String(today.getDate()).padStart(2, '0')
      
        return `${year}-${month}-${day}`
      }

    function handleChange(e){
        if(e.target.value.length > 1){
            setError({
                err: false,
                msg: ""
            })
        }
        setRemarks(e.target.value)
    }
    
    function handleClose(){
        handleCloseDialog(false)
    }

    async function handleSubmit(e){
        e.preventDefault()
        if (remarks.length === 0) {
            setError({
                err: true,
                msg: "Required"
            })
            return
        }
        if(candidate.exception_status === "Blacklisted"){
            await overwriteCandidateBlacklistStatus(candidate.id, { is_blacklist: false, comment: remarks})
        } else if (candidate.exception_status === "Under Cooling Period"){
            await overwriteCandidateCoolingPeriod(candidate.id, { cooling_period: getTodayDate(), comment: remarks})
        } else if(candidate.is_blacklist === false){
            await overwriteCandidateBlacklistStatus(candidate.id, { is_blacklist: true, comment: remarks})
        }
        window.location.reload()
    }

    return(
        <div>
            <Dialog
            open={isDialogOpen}
            onClose={handleClose}
            fullWidth={true}
            maxWidth={"sm"}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description" >
                <DialogTitle sx={{ m: 1 }}>{isUplift === true ? `Uplift ${exception}` : "Blacklist a candidate"}</DialogTitle>
                <DialogContent sx={{m:1}}>
                    <Box width="100%">
                        <Typography color="#75767A">{isUplift === true ? "Please provide the reasons on overwrite the exception status": "Please provide the reasons for blacklist this candidate."}</Typography>
                        <FormControl variant="standard" sx={{width: "100%", pt:1}}>
                            <TextField onChange={handleChange} required size='small' id="reason" name="reason" label="Reasons" error={error.err} helperText={error.msg}  />
                        </FormControl>
                    </Box>
                </DialogContent>
                <DialogActions sx={{ width: "100%", " .MuiDialogActions-root": {
                    width: "100%"
                } }}>
                    <Button onClick={handleClose}> Cancel </Button>
                    <Button autoFocus type="submit" onClick={handleSubmit} variant="contained"> Submit </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default BlacklistCandidate

