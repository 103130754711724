/**
 * Convert date string into DD/MM/YYYY format
 * @param {string} dateString date string with valid date format
 * @return {string} date string with DD/MM/YYYY format
 */
const dateStringToDDMMYYYY = (dateString) => {
  if (!dateString) {
    console.log("[dateStringToDDMMYYY] Invalid date string");
    return "Invalid Date";
  }

  // Parse the input date string into a Date object
  const dateObj = new Date(dateString);

  // Check if the parsed date is valid
  if (isNaN(dateObj)) {
    console.log(
      `[dateStringToDDMMYYY] ${dateString} not a parsable date string`
    );
    return "Invalid Date";
  }

  // Extract the year, month, and day from the date object
  const year = dateObj.getFullYear();
  const month = String(dateObj.getMonth() + 1).padStart(2, "0");
  const day = String(dateObj.getDate()).padStart(2, "0");

  // Concatenate the date in the "dd/mm/yyyy" format
  const convertedDate = `${day}/${month}/${year}`;

  return convertedDate;
};

/**
 * Add days to current date
 * @param {number} days number of days to be added
 * @return {Date} current date plus [days]
 */
const todayPlusDays = (days) => {
  // Get the current date
  const currentDate = new Date();

  // Add 365 days (1 year) to the current date
  const futureDate = new Date(currentDate);
  futureDate.setDate(futureDate.getDate() + days);

  return futureDate;
};

/**
 * Add days to current date
 * @param {number} days number of days to be added
 * @return {Date} current date plus [days]
 */
const todayMinusDays = (days) => {
  // Get the current date
  const currentDate = new Date();

  // Add 365 days (1 year) to the current date
  const futureDate = new Date(currentDate);
  futureDate.setDate(futureDate.getDate() - days);

  return futureDate;
};

/**
 * Convert date into date string with yyyy-MM-dd format
 * @param {Date} date date to be formatted
 * @return {string} date string with yyyy-MM-dd format
 */
const dateToYYYYMMDD = (date) => {
  if (!date) {
    console.log("[dateToYYYYMMDD] Invalid date");
    return "Invalid Date";
  }

  const isDate = date instanceof Date;

  if (!isDate) {
    console.log("[dateToYYYYMMDD] Not instance of Date");
    return "Invalid Date";
  }

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");

  const formattedDate = `${year}-${month}-${day}`;

  return formattedDate;
};

const dateToDDMMYYYHHmm = (date) => {
  if (!date) {
    console.log("[dateToDDMMYYYHHmm] Invalid date");
    return "Invalid Date";
  }

  const isDate = date instanceof Date;

  if (!isDate) {
    console.log("[dateToDDMMYYYHHmm] Not instance of Date");
    return "Invalid Date";
  }

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");

  const formattedDate = `${day}-${month}-${year} ${hours}:${minutes}`;

  return formattedDate;
}

const yyyyMMddToISO = (dateString) => {
  if (!dateString) {
    return "Invalid Date";
  }

  const parts = dateString.split("-");
  if (parts.length !== 3) {
    throw new Error("Invalid date format. Expected yyyy-mm-dd.");
  }

  const year = parseInt(parts[0]);
  const month = parseInt(parts[1]);
  const day = parseInt(parts[2]);

  if (isNaN(year) || isNaN(month) || isNaN(day)) {
    return "Invalid Date";
  }

  const isoDate = new Date(Date.UTC(year, month - 1, day)).toISOString();

  return isoDate;
};

/**
 * Checks if national id exists on candidate list
 * @param {boolean} includeTime
 * @return {string}
 */
const getFormattedDate = (includeTime) => {
  const today = new Date();
  const year = today.getFullYear();
  const month = today.getMonth() + 1;
  const day = today.getDate();
  const hours = today.getHours();
  const minutes = today.getMinutes();
  const formattedHours = hours.toString().padStart(2, "0");
  const formattedMinutes = minutes.toString().padStart(2, "0");
  const formatMonth = month.toString().padStart(2, "0");
  const formatDay = day.toString().padStart(2, "0");

  if(includeTime === true) return `${formatDay}-${formatMonth}-${year} ${formattedHours}:${formattedMinutes}`

  return `${formatDay}-${formatMonth}-${year}`
};

function getTotalYearsYYYYMM(startDate, endDate) {
  if (endDate === "") {
    endDate = getFormattedDate(false)
  }
  const startYear = parseInt(startDate.split("-")[0]);
  const startMonth = parseInt(startDate.split("-")[1]);

  const endYear = parseInt(endDate.split("-")[0]);
  const endMonth = parseInt(endDate.split("-")[1]);

  const yearsDiff = endYear - startYear;
  const monthsDiff = endMonth - startMonth;

  const totalYears = yearsDiff + (monthsDiff >= 0 ? monthsDiff / 12 : (monthsDiff + 12) / 12);

  return Math.round(totalYears);
}

module.exports = {
  dateStringToDDMMYYYY,
  todayPlusDays,
  dateToYYYYMMDD,
  yyyyMMddToISO,
  todayMinusDays,
  getTotalYearsYYYYMM,
  getFormattedDate,
  dateToDDMMYYYHHmm
};
