import { Box } from '@mui/material'
import React from 'react'
import useAppBarHeight from '../../../utils/appBarHeight'

export default function DashboardMainLayout(props) {
    const {children} = props
    const appBarHeight = useAppBarHeight()
  return (
    <Box minHeight={`calc(100vh - ${appBarHeight}px - 56px)`} sx={{ backgroundColor: "#F3F6FF", flex: 3.5 }}>
        {children}
    </Box>
  )
}
