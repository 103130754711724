import apiService from "../lib/apiService";

export const decryptData =  async (text) => {
  const result = await apiService.decrypt({
      encryptedText: text
  });

  return result?.data ?? "";
}

export const encryptData = async ( text ) => {
  const result = await apiService.encrypt({
      text
  });

  return result?.data ?? "";
}



