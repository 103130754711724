function getTime(){
    const today = new Date()
    const year = today.getFullYear()
    const month = today.getMonth() + 1
    const day = today.getDate()
    const hours = today.getHours();
    const minutes = today.getMinutes();
    const formattedHours = hours.toString().padStart(2, '0');
    const formattedMinutes = minutes.toString().padStart(2, '0');
    const formatMonth = month.toString().padStart(2, '0')
    const formatDay = day.toString().padStart(2, '0')
    return `${formatDay}-${formatMonth}-${year} ${formattedHours}:${formattedMinutes}`
}

export default getTime

