import { Check, Close, Delete, Edit, InsertPhoto } from "@mui/icons-material";
import { Box, Button, TextField, Typography } from "@mui/material";
import { EditorState, convertToRaw, ContentState, convertFromHTML, Modifier} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { useEffect, useRef, useState } from "react";
import { Editor } from 'react-draft-wysiwyg';
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { emailTemplateContainer, emailTemplateFooter, emailTemplatePreFooter } from "../../utils/emailTemplate";
import './EmailTemplateEditor.css';
import { getEventEmailTemplateById } from "../../lib/db_event";

function EmailTemplateEditor( props ) {
  const { onUpdate, event, isSaving, editorErrors } = props;
  
  // previous template content
  const [previousContentTemplate, setPreviousContentTemplate] = useState("");
  // updates on editorState change
  const [draftContentTemplate, setDraftContentTemplate] = useState("");
  // updates on save
  const [templateContent, setTemplateContent] = useState("");
  const [editModeOn, setEditModeOn] = useState(false);
  const [headerImageUrl, setHeaderImageUrl] = useState("");
  const [headerImageFile, setHeaderImageFile] = useState(null);
  const [headerImageFileKey, setHeaderImageFileKey] = useState(Date.now());
  const [imagePreview, setImagePreview] = useState("");
  const [imageWarning, setImageWarning] = useState("");
  const [emailSubject, setEmailSubject] = useState("");

  const displayRef = useRef(null);
  const footerRef = useRef(null);

  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  const handleOnEmailSubjectChange = (e) => {
    const value = e.target.value ?? "";
    setEmailSubject(value);
  }

  const handleEditorChange = (state) => {  
    setEditorState(state);
  };

  const handleEditModeOn = ( isOn ) => {
    setEditModeOn( isOn );
  }

  const convertHtmlToContentState = (html) => {
    const newHtml = html.replaceAll(`&nbsp;`, `spacing`);

    const blocksFromHTML = convertFromHTML(newHtml);
    const contentBlocks = blocksFromHTML.contentBlocks.map((block) => {
      if (block.text === 'spacing') {
        return block.merge({
          text: '', // Add a newline character for empty blocks
        });
      }
      return block;
    });

    const contentState = ContentState.createFromBlockArray(
      contentBlocks,
      blocksFromHTML.entityMap
    );

    return EditorState.createWithContent(contentState);
  };

  const replaceKeys = {
    "<p></p>" : `<p>&nbsp;</p>`,
    "<p>" : `<p style="margin: 0;">`,
    "<li>" : `<li style="margin-left: 1.5em; font-size: 13px">`
  };

  const handleSaveContentTemplate = () => {
    setTemplateContent("");
    setTimeout(() => {
      setTemplateContent(draftContentTemplate);
      setPreviousContentTemplate(draftContentTemplate);
    }, 50)
    handleEditModeOn(false);
  }

  const handleCancelEditTemplate = () => {
    setTemplateContent("");
    setTimeout(() => {
      setTemplateContent(previousContentTemplate);
      setEditorState(convertHtmlToContentState(previousContentTemplate))
    }, 50)
    handleEditModeOn(false);
  }


  const handleFileChange = (e) => {
    const file = e.target.files[0];

    const allowedTypes = ['image/jpeg', 'image/png'];
    const maxSize = 2 * 1024 * 1024; // 2MB

    const allowed = file && allowedTypes.includes(file.type) && file.size <= maxSize;

    if ( allowed ) {
      setHeaderImageFile( file )
      setImageWarning("");
    } else {
      setHeaderImageFile(null)
      if (!file) {
        setImageWarning("Please select an image.")
      } else if ( !allowedTypes.includes(file?.type) ) {
        setImageWarning("File should be .jpg or .png.");
      } else if ( file.size > maxSize ) {
        setImageWarning("Maximum file size is only 2mb.")
      } else {
        setImageWarning("Image is not allowed.")
      }
    }
  };

  const [removeIsClicked, setRemoveIsClicked] = useState(false);

  const removeImage = () => {
    setHeaderImageFile(null);
    setImagePreview("");
    setImageWarning("");
    setHeaderImageFileKey(Date.now())
    setRemoveIsClicked(true);
  }

  const handlePastedText = (text, _, editorState) => {
    // Remove any HTML formatting from the pasted text
    const contentState = Modifier.replaceText(
      editorState.getCurrentContent(),
      editorState.getSelection(),
      text
    );
    const newEditorState = EditorState.push(
      editorState,
      contentState,
      'insert-characters'
    );
    
    setEditorState(newEditorState)
    return 'handled';
  };

  useEffect(() => {
    const contentState = editorState.getCurrentContent();
    const htmlText = draftToHtml(convertToRaw(contentState));

    let _newHtmlText = htmlText;
    for ( const key in replaceKeys ) {
      const value = replaceKeys[key];

      _newHtmlText = _newHtmlText.replaceAll(key, value);
    }

    // replace with format [[dynamic_name]]
    _newHtmlText = `<tr>
      <td style="padding: 20px; color: #4b6479">
        ${_newHtmlText.replaceAll(/\[\[([\w\s]+)\]\]/g, `<span style="color: #2F73D2;">[[$1]]</span>`)}\
      </td>
    </tr>`;
    
    setDraftContentTemplate(_newHtmlText);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editorState])

  useEffect(() => {
    if ( headerImageFile ) {
      const imageUrl = URL.createObjectURL(headerImageFile);
      setImagePreview(imageUrl);
    } else {
      setImagePreview( removeIsClicked ? "" : headerImageUrl);
    }

  }, [headerImageFile, headerImageUrl, removeIsClicked ])

  useEffect(() => {
    if( displayRef.current ) {
      displayRef.current.innerHTML = emailTemplateContainer(templateContent);
    }
  }, [templateContent])

  useEffect(() => {

    onUpdate({
      image: headerImageFile,
      imageUrl: headerImageUrl,
      editModeOn,
      templateContent,
      removeIsClicked,
      emailSubject
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templateContent, headerImageFile, headerImageUrl, editModeOn, removeIsClicked, emailSubject ]);

  useEffect(() => {
    const loadEditData = async () => {
      try {
        if ( event ) {
          const template = await getEventEmailTemplateById(event.id);
          const contentTemplate = template.content ?? "";
          const imageHeader = template.image_url ?? "";
          const emailSubject = template.subject ?? "";

          setImagePreview(imageHeader);
          setHeaderImageUrl(imageHeader);
          setTemplateContent(contentTemplate);
          setEmailSubject(emailSubject);
          setPreviousContentTemplate(contentTemplate);
          setEditorState( convertHtmlToContentState(contentTemplate) )
        }
      } catch(error) {
        console.log(error);
      }
    }

    loadEditData();
    return () => {}
  }, [event])

  useEffect(() => {
    const loadFooterHTML = () => {
      const footerHtml = emailTemplateContainer(`
        ${emailTemplatePreFooter()}
        ${emailTemplateFooter()}`
      );
  
      footerRef.current.innerHTML = footerHtml;
    }

    if( footerRef.current ) {
      loadFooterHTML();
    }
  });

  return(
    <>
      <Box mb={3} mt={2} display="flex" alignItems="start">
        <Typography fontSize="16px" color="#000" mr={2} py={1}>Email Subject: </Typography>
        <TextField 
          size="small" 
          placeholder="You are invited for Cabin Crew Interview: May 6th & 7th *"
          value={emailSubject}
          onChange={handleOnEmailSubjectChange}
          sx={{ maxWidth: "600px" }}
          error={editorErrors?.emailSubject ? true : false} 
          helperText={editorErrors?.emailSubject ? `${editorErrors?.emailSubject}` : ""}
          fullWidth
        />
      </Box>
      <Box sx={{ border: 1, borderColor: "#ebebeb", width: "580px", backgroundColor: "white" }}>
        <Box sx={{ p: "20px", borderBottom: 1, borderColor: "#ebebeb" }}>
          <img alt="AirAsia Logo" src="images/logoHeader.png" style={{ marginBottom: "-8px", width: "88px" }} />
        </Box>
        <Box sx={{ borderBottom: 1, borderColor: "#ebebeb", display: "flex", justifyContent: "space-between", alignItems: "center", gap: 4, py: "4px", px: "20px" }}>
          <Typography fontWeight="medium">Image</Typography>
          <div>
            <Button
              disabled={isSaving}
              component="label"
              >
              <InsertPhoto />
              <span>
                {imagePreview ? "Change" : "Upload Image"}
              </span>
              <input
                key={headerImageFileKey}
                accept=".jpg, .png"
                onChange={handleFileChange}
                type="file"
                hidden
              />
            </Button>
            { (headerImageFile || imagePreview ) && (
              <Button disabled={isSaving} onClick={removeImage}>
                <Delete />
                <span>Remove</span>
              </Button>
            )}
          </div>
        </Box>
        {(imagePreview || imageWarning ) && (
          <Box sx={{ padding: "20px", borderBottom: 1, borderColor: "#ebebeb" }}>
            {( imagePreview ) && (<img style={{ width: "100%", height: "auto" }}  src={imagePreview } alt="preview" />)}
            <Typography color="error" textAlign="center">{imageWarning}</Typography>
          </Box>
        )}
        <Box sx={{ position: "relative", borderBottom: 1, borderColor: "#ebebeb" }}>
          { editModeOn ? (
            <Editor
              handlePastedText={handlePastedText}
              wrapperClassName="emailTemplateEditor"
              editorState={editorState}
              onEditorStateChange={handleEditorChange}
              toolbarStyle={{ border: 0 }}
              editorStyle={{ padding: "16px", paddingTop: "16px", fontSize: "12px", minHeight: "200px" }}
              toolbar={{
                options: ['fontSize', 'inline', 'blockType', 'list', 'link', 'history'],
                fontSize: {
                  defaultValue: 16
                },
                inline: { options: ['bold', 'italic', 'underline'] },
                blockType: { options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'Blockquote'] },
                list: { options: ['unordered', 'ordered'] },
              }}
            />
          ) : (
            <div id="contentTemplateStatic" ref={displayRef}  />
          )}
          { editModeOn && (
            <Button
              variant="contained"
              color="success"
              onClick={handleSaveContentTemplate}
              sx={{ 
                borderRadius: 0, position: "absolute", top: "-32px", right: "-32px",
                p: "0", lineHeight: "32px", height: "32px", minWidth: "32px"
              }}>
              <Check fontSize="small" sx={{ color: "white"}} />
            </Button>
          )}
          { editModeOn ? (
            <Button
              variant="contained"
              color="primary"
              onClick={handleCancelEditTemplate}
              sx={{ 
                borderRadius: 0, position: "absolute", top: "0px", right: "-32px",
                p: "0", lineHeight: "32px", height: "32px", minWidth: "32px"
              }}>
              <Close fontSize="small" sx={{ color: "white"}} />
            </Button>
          ) : (
            <Button
            disabled={isSaving}
              onClick={() => handleEditModeOn(true)}
              sx={{ 
                borderRadius: 0, position: "absolute", border: 1, 
                borderColor: "#ebebeb", top: "0px", right: "-32px",
                p: "0", lineHeight: "32px", height: "32px", minWidth: "32px"
              }}>
              <Edit fontSize="small" sx={{ color: "#777"}}/>
            </Button>
          )}
        </Box>
        <div id="footerTemplateStatic" ref={footerRef} />
        {/* <Box sx={{ p: "16px" }}>
            <div id="templateSubFooter">
              <div style={{ textAlign: "center", fontFamily: "DM Sans" }}>
                <p style={{ fontWeight: "normal", fontSize: "12px", lineHeight: 1.5, margin: "0 0 8px 0" }}>
                  Click the <b>I am attending</b> button below to confirm your attendance.
                </p>
                <a 
                  href="/"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    display: "inline-block",
                    lineHeight: "26px",
                    fontSize: "12px",
                    backgroundColor: "#DC3224",
                    minWidth: "103px",
                    border: 0,
                    outline: 0,
                    color: "white",
                    textDecoration: "none"
                  }}>
                  I am attending
                </a>
              </div>
              <div style={{ textAlign: "left", marginTop: "18px" }}>
                <p style={{ fontSize: "12px", margin: 0, lineHeight: 1.5 }}>Cheers,</p>
                <p style={{ fontSize: "12px", margin: 0, lineHeight: 1.5 }}>AirAsia’s Recruitment Team</p>
              </div>
            </div>
        </Box> */}
        {/* <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "#D9D9D9", minHeight: "124px" }}>
            <Typography fontSize="small" fontWeight="bold">FOOTER</Typography>
        </Box> */}
      </Box>
    </>
  );
}

export default EmailTemplateEditor;