import { Box, Container, Typography } from "@mui/material";

const DownTimePlaceholder = (props) => {
  const {
    imageUrl = "/images/sessionExpired.png", 
    title = "Page not found!", 
    maxWidth = "sm",
    description
  } = props;

  return(
    <Container maxWidth={maxWidth}>
      <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" minHeight="80vh" px={2} py={10} textAlign="center">
        <Box maxWidth="288px">
          <img src={imageUrl} alt="Session Expired" style={{ width: "100%" }} />
        </Box>
        <Typography fontSize="24px" color="#000" fontWeight="500" my={2}>{title ?? "Page not found!"}</Typography>
        <Typography fontSize="16px" color="#000">
          {description ?? "The page you are looking for could not be found. Please make sure you've entered the correct URL."}
        </Typography>
      </Box>
    </Container>
  )
}

export default DownTimePlaceholder;