import { useContext } from "react";
import { AuthContext } from "../utils/AuthProvider";
import PublicPageLayout from "./layout/PublicPageLayout";
import DownTimePlaceholder from "./shared/DownTimePlaceholder";


const NotFoundPage = (props) => {
  const { title, description } = props;

  const { user } = useContext(AuthContext);

  return(
    <PublicPageLayout contentWidth="xl">
      <DownTimePlaceholder
        title={title}
        description={
          <>
            {description ?? "The page you are looking for could not be found. Please make sure you've entered the correct URL."} { !user && (<>Alternatively, this content may require you to <a href="/" style={{ textDecoration: "underline", cursor: "pointer" }}>log in</a> to access it</>)}
          </>
        }
        maxWidth="sm"
      />
    </PublicPageLayout>
  );
}

export default NotFoundPage;