import { CheckCircleOutline, ErrorOutline } from '@mui/icons-material';
import { TabContext, TabPanel } from '@mui/lab';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Tab,
    Tabs,
    Typography,
} from "@mui/material";
import * as React from 'react';
import { useEffect, useState } from "react";
import { saveEventEmailImage, setEventEmailTemplateById, updateEventById, updateEventEmailTemplateFinalStatus } from "../../lib/db_event";
import { triggerAutoSendInvitationEmail } from "../../lib/page";
import { emailTemplateContainer, emailTemplateFooter, emailTemplateHeader, emailTemplateHeaderImage, emailTemplatePreFooter } from '../../utils/emailTemplate';
import SuccessMsg from '../SuccessMsg';
import ViewCandidate from '../candidate/ViewCandidate';
import EmailTemplateEditor from './EmailTemplateEditor';
import AddCandidates from './editEvent/AddCandidates';
import DetailsForm from './editEvent/DetailsForm';
import Loading from '../shared/Loading';

function EditEvent({ isDialogOpen, handleCloseDialog, selectedRows, username, tab, color }) {
    const [formPayload, setFormPayload] = useState({
        name: undefined,
        jrNumber: undefined,
        companyCountry: undefined,
        aoc: undefined,
        venue: undefined,
        dateTime: undefined,
        date: undefined,
        assessment: []
    })
    const [assessmentList, setAssessmentList] = useState([]);
    const formErrorObj = { error: false, msg: '' };
    const [formError, setFormError] = useState({
        name: formErrorObj,
        jrNumber: formErrorObj,
        companyCountry: formErrorObj,
        aoc: formErrorObj,
        venue: formErrorObj,
        dateTime: formErrorObj,
        time: formErrorObj,
        assessment: formErrorObj,
    })
    const [tabIndex, setTabIndex] = useState("1");
    const [selectedCandidate, setSelectedCandidate] = useState([])
    const [addedCandidate, setAddedCandidate] = useState(false)
    const [isMsgOpen, setIsMsgOpen] = useState(false)
    const [isViewDialogOpen, setIsViewDialogOpen] = useState(false)
    const [id, setId] = useState("")
    const [listCountry, setListCountry] = useState([])
    const [addToEventModalIsOpen, setAddToEventModalIsOpen] = useState(false);
    const [templateIsFinal, setTemplateIsFinal] = useState(selectedRows?.is_email_template_final ?? false);
    const [isFinalizeLoading, setIsFinalizeLoading] = useState(false)

    const handleAddToEventModalOpen = () => {
        setAddToEventModalIsOpen(true);
    }

    useEffect(() => {
        setCountry()
    }, [])

    useEffect(() => {
        setTabIndex(tab)
    }, [tab])

    useEffect(() => {
        function fetchDoc() {
            const arr = [
                { name: "Measurements", checked: false },
                { name: "Document Check", checked: false },
                { name: "Catwalk", checked: false },
                { name: "English Examinations", checked: false },
                { name: "Group Dynamics", checked: false },
                { name: "1:1 Interview", checked: false },]
            if (selectedRows) {
                if (selectedRows.event_assessment_module) {
                    for (var i in arr) {
                        if (selectedRows.event_assessment_module?.includes(arr[i].name)) {
                            arr[i].checked = true
                        }
                    }
                    setFormPayload(formPayload => ({
                        ...formPayload,
                        "assessment": selectedRows.event_assessment_module
                    }))
                    if(selectedRows.is_email_template_final){
                        setTemplateIsFinal(selectedRows.is_email_template_final)
                    } else {
                        setTemplateIsFinal(false)
                    }
                    setAssessmentList(arr)
                } else {
                    setAssessmentList(arr)
                }
            }
        }
        fetchDoc()
    }, [selectedRows])

    const setCountry = () => {
        setListCountry(["", "Malaysia", "Indonesia", "Philippines", "Thailand"])
    }

    function OpenFirstName(props) {
        const handleClose = (e) => {
            setId(e.target.id)
            setIsViewDialogOpen(!isViewDialogOpen)
        }
        return (
            <Button disableRipple id={props.row.id} variant="text" onClick={handleClose} sx={{ color: "black", textDecoration: "none" }}>{props.row.first_name}</Button>
        )
    }
    function OpenLastName(props) {
        const handleClose = (e) => {
            setId(e.target.id)
            setIsViewDialogOpen(!isViewDialogOpen)
        }
        return (
            <Button disableRipple id={props.row.id} variant="text" onClick={handleClose} sx={{ color: "black", textDecoration: "none" }}>{props.row.last_name}</Button>
        )
    }

    const handleChangeEditTabs = (e, activeTab) => {
        setTabIndex(activeTab)
    }

    function handleAssessmentChange(event) {
        const arrayAssessment = [];
        for (var i in assessmentList) {
            if (assessmentList[i].name === event.target.name) {
                assessmentList[i].checked = event.target.checked
            }
        }

        for (var k in assessmentList) {
            if (assessmentList[k].checked === true) {
                arrayAssessment.push(assessmentList[k].name)
            }
        }
        if (arrayAssessment.length > 0) {
            setFormError({
                ...formError,
                "assessment": formErrorObj
            })
        }
        setFormPayload({
            ...formPayload,
            "assessment": arrayAssessment
        })
        setAssessmentList([...assessmentList])
    }

    function handleChange(e, val) {
        setFormPayload({
            ...formPayload,
            [e.target.name]: e.target.value
        })

        setFormError({
            ...formError,
            [e.target.name]: formErrorObj
        })
    }

    function handleDateChange(e, val) {
        let months
        if (e !== null && !isNaN(e.$D) && !isNaN(e.$M) && !isNaN(e.$y) && !isNaN(e.$H) && !isNaN(e.$m)) {
            const formatTwoDigits = (value) => (value < 10 ? `0${value}` : value);
            const days = formatTwoDigits(e.$D);
            e.$M < 9 ? months = `0${e.$M + 1}` : months = e.$M + 1
            const hours = formatTwoDigits(e.$H);
            const minutes = formatTwoDigits(e.$m);

            setFormPayload({
                ...formPayload,
                "dateTime": `${days}-${months}-${e.$y} ${hours}${minutes}`,
                "date": `${e.$y}-${months}-${days}`
            })
            setFormError({
                ...formError,
                "date": formErrorObj
            })
        } else {
            setFormPayload({
                ...formPayload,
                "date": ""
            })
        }
    }

    function formValidator(form) {
        let formValid = true
        let nameErr = formErrorObj
        let jrNumberErr = formErrorObj
        let companyCountryErr = formErrorObj
        let aocErr = formErrorObj
        let venueErr = formErrorObj
        let dateErr = formErrorObj
        let timeErr = formErrorObj
        let assessmentErr = formErrorObj

        const today = new Date()
        const [year, month, day] = form.event_date.split('-').map(Number)
        const convertDate = new Date(year, month - 1, day)
        const stamp = convertDate.getTime()


        if (form.event_aoc.length === 0 || form.event_aoc === undefined) {
            aocErr = { error: true, msg: 'Require' }
            formValid = false
        }
        if (form.event_name.length === 0 || form.event_name === undefined) {
            nameErr = { error: true, msg: 'Require' }
            formValid = false
        }
        if (form.jr_number.length === 0 || form.jr_number === undefined) {
            jrNumberErr = { error: true, msg: 'Require' }
            formValid = false
        }
        if (form.hiring_country.length === 0 || form.hiring_country === undefined) {
            companyCountryErr = { error: true, msg: 'Require' }
            formValid = false
        }
        if (form.event_venue.length === 0 || form.event_venue === undefined) {
            venueErr = { error: true, msg: 'Require' }
            formValid = false
        }
        if (form.event_date_time.length === 0 || form.event_date_time === undefined) {
            dateErr = { error: true, msg: 'Require' }
            formValid = false
        }
        if (form.event_assessment_module.length === 0 || form.event_assessment_module === undefined) {
            assessmentErr = { error: true, msg: 'Require' }
            formValid = false
        }
        if (stamp < today) {
            dateErr = { error: true, msg: 'Date has already passed' }
            formValid = false
        }

        setFormError({
            name: nameErr,
            jrNumber: jrNumberErr,
            companyCountry: companyCountryErr,
            aoc: aocErr,
            venue: venueErr,
            dateTime: dateErr,
            time: timeErr,
            assessment: assessmentErr
        })

        return formValid
    }

    function handleClose() {
        handleCloseDialog(false);

        setEditorErrors({});

        if (addedCandidate === true) {
            window.location.reload();
        }
    }

    const [templateMsg, setTemplateMsg] = useState({});
    const [templateIsSaving, setTemplateIsSaving] = useState(false);
    const [emailEditorCallback, setEmailEditorCallback] = useState({});
    const [editorErrors, setEditorErrors] = useState({});

    const handleSetEmailTemplateFinal = async (e) => {
        e.preventDefault();

        if (templateIsSaving) {
            return;
        }

        if (!emailEditorCallback.emailSubject) {
            setEditorErrors({
                ...editorErrors,
                emailSubject: "Email Subject is required!"
            });
            return;
        }


        try {
            const eventId = selectedRows.id;

            setIsFinalizeLoading(true)

            await saveEmailTemplate();

            await updateEventEmailTemplateFinalStatus(eventId, !templateIsFinal);

            if (!templateIsFinal) {
                await triggerAutoSendInvitationEmail(eventId)
                setIsFinalizeLoading(false)
                setTemplateMsg({
                    type: "success",
                    title: "Successful",
                    message: "The email template has been set to final."
                });
            } else {
                setIsFinalizeLoading(false)
                setTemplateMsg({
                    type: "success",
                    title: "Successful",
                    message: "The email template has been unfinalized."
                });
            }

            setTemplateIsFinal(!templateIsFinal);
        } catch (_) {
            console.log(_);
            setTemplateMsg({
                type: "error",
                title: "Failed",
                message: "Unable to save changes!"
            });
        }
        setTemplateIsSaving(false);

        handleClose();
    }

    const handleCloseTemplateMsgModal = () => {
        setTemplateMsg({});
        window.location.reload();
    }

    const saveEmailTemplate = async () => {
        const eventId = selectedRows.id;

        const emailTemplateContent = emailEditorCallback.templateContent ?? "";
        const emailSubject = emailEditorCallback.emailSubject ?? "";

        let url = emailEditorCallback.imageUrl ?? "";

        if (emailEditorCallback.image) {
            const imageUrl = await saveEventEmailImage(eventId, emailEditorCallback.image);
            url = imageUrl;
        }

        // if removed
        if (!emailEditorCallback.image && emailEditorCallback.removeIsClicked) {
            url = "";
        }

        const emailTemplate = emailTemplateContainer(`
            ${emailTemplateHeader()}
            ${emailTemplateHeaderImage(url)}
            ${emailTemplateContent}
            ${emailTemplatePreFooter(
            // TODO: hash query
            `${process.env.REACT_APP_BASE_URL}/confirm-attendance/[[token]]`
            )}
            ${emailTemplateFooter()}
        `);

        await setEventEmailTemplateById(eventId, {
            image_url: url,
            content: emailTemplateContent,
            email_template: emailTemplate,
            subject: emailSubject
        });
    }

    const handleSubmitEmailTemplate = async (e) => {
        e.preventDefault();

        if (templateIsSaving) {
            return;
        }

        if (!emailEditorCallback.emailSubject) {
            setEditorErrors({
                ...editorErrors,
                emailSubject: "Email Subject is required!"
            });
            return;
        }

        setTemplateIsSaving(true);

        try {
            await saveEmailTemplate();

            setTemplateMsg({
                type: "success",
                title: "Successful",
                message: "Email template saved successfully."
            });
        } catch (error) {
            console.log("handleSubmitEmailTemplate:", error);
            setTemplateMsg({
                type: "error",
                title: "Failed",
                message: "Email template saving failed!"
            });
        }

        setTemplateIsSaving(false);

        handleClose();

    }

    const getRegistrationUrl = () => {
        return `${window.location.origin}/registration/${selectedRows.id}`;
    }

    async function handleSubmit(e) {
        e.preventDefault()

        const form = {
            event_aoc: formPayload.aoc ?? selectedRows.event_aoc,
            event_assessment_module: formPayload.assessment ?? selectedRows.event_assessment_module,
            event_date: formPayload.date ?? selectedRows.event_date,
            event_name: formPayload.name ?? selectedRows.event_name,
            event_status: "Active",
            event_date_time: formPayload.dateTime ?? selectedRows.event_date_time,
            event_venue: formPayload.venue ?? selectedRows.event_venue,
            jr_number: selectedRows.jr_number,
            hiring_country: formPayload.companyCountry ?? selectedRows.hiring_country,
            posting_date: selectedRows.posting_date
        }
        const isFormValid = formValidator(form)
        if (isFormValid) {
            const eventId = selectedRows.id;
            await updateEventById(eventId, form)
            handleCloseDialog(false);
            window.location.reload();
        }
    }

    return (
        <div>
            <Dialog
                open={isDialogOpen}
                onClose={handleClose}
                fullWidth={true}
                maxWidth={"lg"}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle sx={{ backgroundColor: "#E9798E" }}>
                    <div>
                        <Typography variant="h6" color="white">{`EVENT #: ${selectedRows ? selectedRows.event_code : ""}`}{" "}<span style={{ color: color }} >{`(${selectedRows ? selectedRows.event_status : ""})`}</span></Typography>
                        <Typography variant='subtitle1' color="white" sx={{ opacity: 0.8 }}>{selectedRows ? selectedRows.event_name : ""}</Typography>
                    </div>
                </DialogTitle>
                <TabContext value={tabIndex}>
                    <Box p={1}>
                        <Tabs value={tabIndex} onChange={handleChangeEditTabs}>
                            <Tab disableRipple label={"DETAILS"} value="1" />
                            <Tab disableRipple label={"EMAIL TEMPLATE"} value="2" />
                            <Tab disableRipple label={"ADD CANDIDATES"} value="3" />
                        </Tabs>
                    </Box>
                    <DialogContent sx={{ border: 1, borderColor: "#EBEBEB", m: 1 }}>
                        <TabPanel value='1'>
                            <DetailsForm selectedRows={selectedRows} formError={formError} handleChange={handleChange} handleDateChange={handleDateChange} listCountry={listCountry} assessmentList={assessmentList} handleAssessmentChange={handleAssessmentChange} />
                            <Box py={2} px={3}>
                                <Typography color="black" fontWeight="medium">
                                    Walk-in Registration:&nbsp;
                                    <a href={getRegistrationUrl()} target="_blank"
                                        rel="noreferrer noopener"
                                        style={{ fontWeight: "normal", color: "black", textDecoration: "none" }}>
                                        {getRegistrationUrl()}
                                    </a>
                                </Typography>
                            </Box>
                        </TabPanel>
                        <TabPanel value='2'>
                            <Box sx={{ m: -4 }}>
                                <EmailTemplateEditor
                                    editorErrors={editorErrors}
                                    isSaving={templateIsSaving}
                                    event={selectedRows}
                                    onUpdate={(callback) => {
                                        setEmailEditorCallback(callback);
                                    }}
                                />
                            </Box>
                        </TabPanel>
                        <TabPanel value='3'>
                            <AddCandidates 
                                OpenFirstName={OpenFirstName} 
                                OpenLastName={OpenLastName} 
                                selectedRows={selectedRows} 
                                setSelectedCandidate={setSelectedCandidate} 
                                setAddedCandidate={setAddedCandidate}
                                addToEventModalIsOpen={addToEventModalIsOpen}
                                setAddToEventModalIsOpen={setAddToEventModalIsOpen}
                                selectedCandidate={selectedCandidate}
                                setIsMsgOpen={setIsMsgOpen}
                            />
                        </TabPanel>
                    </DialogContent>
                </TabContext>
                <DialogActions sx={{ m: 1 }}>
                    <Button onClick={handleClose}> {tabIndex === "3" ? "Close" : "Cancel"} </Button>
                    {tabIndex === "1" && (<Button autoFocus type="submit" onClick={handleSubmit} variant="contained"> Save </Button>)}
                    {tabIndex === "2" && (
                        <Button
                            onClick={handleSetEmailTemplateFinal}
                            color='primary'
                            variant='outlined'
                            disabled={emailEditorCallback.editModeOn || templateIsSaving}>
                            {templateIsFinal ? "Unfinalize" : "Finalize"} 
                        </Button>
                    )}
                    {tabIndex === "2" && (
                        <Button
                            autoFocus
                            disabled={emailEditorCallback.editModeOn || templateIsSaving}
                            type="submit"
                            onClick={handleSubmitEmailTemplate} variant="contained">
                            {templateIsSaving ? "Saving..." : "Save"}
                        </Button>
                    )}
                    {tabIndex === "3" && (<Button autoFocus type="submit" disabled={selectedCandidate.length === 0} onClick={handleAddToEventModalOpen} variant="contained"> Add to Events </Button>)}
                </DialogActions>
                <Loading isLoading={isFinalizeLoading} handleStopLoading={() => setIsFinalizeLoading(false)} state={!templateIsFinal} />
            </Dialog>
            <ViewCandidate isDialogOpen={isViewDialogOpen} handleCloseDialog={() => setIsViewDialogOpen(false)} id={id} />
            <SuccessMsg handleCloseDialog={() => setIsMsgOpen(false)} isDialogOpen={isMsgOpen} title={"Success"} message={"Successfully added candidates to the events"} />
            <SuccessMsg
                handleCloseDialog={handleCloseTemplateMsgModal}
                isDialogOpen={templateMsg?.message}
                title={
                    <Box display="flex"
                        alignItems="center">
                        {templateMsg?.type === "success" && (
                            <CheckCircleOutline color="success" />
                        )}
                        {templateMsg?.type === "error" && (
                            <ErrorOutline color="primary" />
                        )}
                        <Box ml={1}>{templateMsg?.title ?? ""}</Box>
                    </Box>
                }
                message={templateMsg?.message ?? ""} />
        </div>
    )
}

export default EditEvent