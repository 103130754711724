import { useEffect, useState } from "react"
import { Box, FormControl, MenuItem, Select, TextField, Typography } from "@mui/material"
import { getCandidateEventAssessmentOverall } from "../../lib/page"
import { AssessmentLayout } from "../layout/AssessmentLayout"


function OverallResult({ candidateId, eventId, eventModule }) {
    const [result, setResult] = useState({
        "Measurements": "",
        "Document Check": "",
        "Catwalk": "",
        "English Examinations": "",
        "Group Dynamics": "",
        "1:1 Interview": ""
    })
    const [score, setScore] = useState({
        status: 'Failed',
        comment: ''
    })

    useEffect(() => {
        async function getResult() {
            const res = await getCandidateEventAssessmentOverall(candidateId, eventId)
            if (res !== undefined) {
                setResult({
                    "Measurements": res.measurement,
                    "Document Check": res.document_check,
                    "Catwalk": res.catwalk,
                    "English Examinations": res.english_exam ? res.english_exam : "Pending",
                    "Group Dynamics": res.group_dynamic ? res.group_dynamic : "Pending",
                    "1:1 Interview": res.one_to_one_interview
                })
                setScore({
                    status: res.final_result,
                    comment: ''
                })

                if(res.measurement === "Failed" || res.document_check === "Failed" || res.catwalk === "Failed" || res.one_to_one_interview === "Failed" || res.group_dynamic_score === "Failed" || res.english_exam_score === "Failed" ){
                    setScore({
                        status: "Failed",
                        comment: ''
                    })
                }
            }
        }
        if (candidateId !== undefined) {
            getResult();
        } else {
            setResult({
                "Measurements": "",
                "Document Check": "",
                "Catwalk": "",
                "English Examinations": "",
                "Group Dynamics": "",
                "1:1 Interview": ""
            })

            setScore({
                status: 'Failed',
                comment: ''
            })
        }
    }, [candidateId, eventId])

    const renderBox = []

    for (var i in result) {
        if (result.hasOwnProperty(i)) {
            if (eventModule.includes(i)) {
                renderBox.push(
                    <Box
                        sx={{
                            mt: 2,
                            mb: 2,
                            boxShadow: "0px 1px 4px 0px #00000029",
                            width: "40%",
                            p: 3,
                        }}
                        key={i}
                    >
                        <Typography>{i === "1:1 Interview" ? "1-1 Interview" : i}</Typography>
                        <TextField
                            value={result[i]}
                            disabled
                            size="small"
                            style={{ width: "100%", marginTop: "12px" }}
                        />
                    </Box>
                )
            }
        }
    }
    return (
        <>
            <AssessmentLayout title={"OVERALL RESULT"}>
                {renderBox}
            </AssessmentLayout>
            <Box
                sx={{
                    mt: 2,
                    mr: -3,
                    ml: -3,
                    mb: 2,
                    p: 3,
                    textAlign: "left",
                    boxShadow: "0px 1px 4px 0px #00000029",
                    width: "70%"
                }}
            >
                <Typography>Overall Score</Typography>
                <FormControl sx={{ minWidth: 250 }}>
                    <Select
                        name='score'
                        value={score.status}
                        disabled
                    >
                        <MenuItem value={"Passed"}>Passed</MenuItem>
                        <MenuItem value={"KIV"}>KIV</MenuItem>
                        <MenuItem value={"Failed"}>Failed</MenuItem>
                        <MenuItem value={"Pending"}>Pending</MenuItem>
                    </Select>
                </FormControl>
            </Box>
        </>
    )
}

export default OverallResult