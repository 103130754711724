import * as React from 'react';
import { useState, useEffect, memo, useRef } from "react";
import { Box } from '@mui/system';
import PropTypes from 'prop-types';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import Typography from '@mui/material/Typography';

function isOverflown(element) {
    return (
        element.scrollHeight > element.clientHeight ||
        element.scrollWidth > element.clientWidth
    );
}
  
const GridCellExpand = memo(function GridCellExpand(props) {
    const { width, value } = props;
    const wrapper = useRef(null);
    const cellDiv = useRef(null);
    const cellValue = useRef(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const [showFullCell, setShowFullCell] = useState(false);
    const [showPopper, setShowPopper] = useState(false);

    const handleMouseEnter = () => {
        const isCurrentlyOverflown = isOverflown(cellValue.current);
        setShowPopper(isCurrentlyOverflown);
        setAnchorEl(cellDiv.current);
        setShowFullCell(true);
    };

    const handleMouseLeave = () => {
        setShowFullCell(false);
    };

    useEffect(() => {
        if (!showFullCell) {
            return undefined;
        }

        function handleKeyDown(nativeEvent) {
            // IE11, Edge (prior to using Bink?) use 'Esc'
            if (nativeEvent.key === 'Escape' || nativeEvent.key === 'Esc') {
                setShowFullCell(false);
            }
        }

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [setShowFullCell, showFullCell]);

    return (
        <Box
        ref={wrapper}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        sx={{
            alignItems: 'center',
            lineHeight: '24px',
            width: 1,
            height: 1,
            position: 'relative',
            display: 'flex',
        }}
        >
        <Box
            ref={cellDiv}
            sx={{
            height: 1,
            width,
            display: 'block',
            position: 'absolute',
            top: 0,
            }}
        />
        <Box
            ref={cellValue}
            sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
        >
            {value}
        </Box>
        {showPopper && (
            <Popper
            open={showFullCell && anchorEl !== null}
            anchorEl={anchorEl}
            style={{ width, marginLeft: -17 }}
            >
            <Paper
                elevation={1}
                style={{ minHeight: wrapper.current.offsetHeight - 3 }}
            >
                <Typography variant="body2" style={{ padding: 8 }}>
                {value}
                </Typography>
            </Paper>
            </Popper>
        )}
        </Box>
    );
});
  
GridCellExpand.propTypes = {
    value: PropTypes.string.isRequired,
    width: PropTypes.number.isRequired,
};
  
function renderCellExpand(params) {
    return (
        <GridCellExpand value={params.value || ''} width={params.colDef.computedWidth} />
    );
}

renderCellExpand.propTypes = {

    //The column of the row that the current cell belongs to.
    colDef: PropTypes.object.isRequired,

    // The cell value, but if the column has valueGetter, use getValue.
    value: PropTypes.string.isRequired,
};

export default function RenderCellExpand(props) {
    return (
        <GridCellExpand value={String(props.value) || ''} width={props.colDef.computedWidth + 200} />
    );
}