
export const emailTemplateFooter = () => {
  return `
    <tr>
      <td style="padding-top:20px;padding-bottom:20px;padding-left:20px;padding-right:20px;background-color:#ffffff">
        <table border="0" cellspacing="0" cellpadding="0" width="100%">
          <tbody>
            <tr>
              <td
                style="border-top:1px solid #000000;border-color:#ff0000;border-top-style:solid;border-top-width:1px;border-bottom:none;border-right:none;border-width:2px">
              </td>
            </tr>
          </tbody>
        </table>
      </td>
    </tr>
    <tr>
      <td style="background-color:#ffffff;padding-top:0;padding-bottom:0;padding-left:0;padding-right:0">
        <table cellpadding="0" cellspacing="0" width="100%">
          <tbody>
            <tr>
              <td width="50%" style="padding-top:0px;padding-bottom:0px;padding-left:20px;padding-right:20px"
                align="left">
                <div>
                  <div
                    style="margin-top:10px;margin-left:0;margin-bottom:10px;margin-right:0;font-size:16px;color:#00a9b7;font-family:Arial,Helvetica,sans-serif;letter-spacing:normal;display:block;line-height:150%">
                    <div><strong><span style="color:#e74c3c">Tips &amp; Tricks: Acing Cabin Crew
                      Interviews</span></strong>
                    </div>
                  </div>
                  <div
                    style="margin-top:10px;margin-right:0;margin-bottom:10px;margin-left:0;font-size:16px;color:#f5f5f5;font-family:Arial;letter-spacing:normal;display:block;line-height:150%">
                    <div><span style="font-size:11px"><span style="color:#4e5f70">Ever dreamed of a career in the skies?
                      This video goes behind the scenes of our cabin crew interviews and shares some tips on how you
                      can score the job of your dreams!</span></span>
                    </div>
                  </div>
                  <table style="border-collapse:separate">
                    <tbody>
                      <tr>
                        <td
                          style="color:#ffffff;display:block;padding-top:5px;padding-right:10px;padding-bottom:5px;padding-left:10px;margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;font-size:14px;font-family:Arial,Helvetica,sans-serif;background-color:#ff0000;border-radius:0px;border-width:0px;border-style:solid;border-color:#00a9b7;white-space:nowrap">
                          <table border="0" cellspacing="0" cellpadding="0">
                            <tbody>
                              <tr>
                                <td
                                  style="padding-top:0px;padding-right:0px;padding-bottom:0px;padding-left:0px;margin-top:0px;margin-right:0px;margin-bottom:0px;margin-left:0px;font-size:14px;font-family:Arial,Helvetica,sans-serif;background-color:transparent;border-radius:0px;border-width:0px;border-style:solid;border-color:transparent;white-space:nowrap;color:#ffffff;border:none">
                                  <a style="font-size:14px;font-family:Arial,Helvetica,sans-serif;color:#ffffff;text-decoration:none"
                                    href="https://u27556205.ct.sendgrid.net/ls/click?upn=LiOqRNUisYZ1bgUaTX4J90NXxLwbJJmncfY-2FALiE23ylmrL50duXFLZLFMg0BsjULkgZOpZueHnTSHIhGVA6jw-3D-3Drnrq_Zf9noBSXp6zmd8gcAmse0Aoz-2B3AvqIe2FFccggoWomsNvlieRIKtGB1Wb7DdoZuu8-2FR2QH3a2YivVbHlW-2BYig5mtUOfeWIE8rGp7MdYs56D0PErFEUAgP6CLjMeuON07bf1IoHHsCBVC6i1WmW7huVytu-2B6lSfjPfe0-2F5QWqC8yH3ftIwj92rvWe-2FCEsXuKrRukj0sHFmWqnY16SRhCMP-2FOS-2BtZ-2BwrnmjkcRlrVXZQb6JvpJBpPyU0pkMfpN88EGAVD5-2B48b3t4kRVF31qREnWJaY2Ez3DWjS0-2FWxcj8caVWy59VcRhvPxgMnHuEownNoM4PotzKfHRwTv1vcCjnuLiLEGo6QuMzFV5aaNLw1M79WHYV5q5TZ2froiTvSmmGkmYDH2fEfI2jXtc1-2Fru-2B5boXPBn5f0KtxO0POdcfBP-2BzR0RyaXTpRSUoa9isOAjrfGnrQNlqXw6UMudnEKo3dU-2BMqHasPuofbnlaVfFQkZid5ULJjoCFCWnWs7kpuQn17-2BHvntajHwnjcBImo0yROqxVsejkp-2Bx7P9S0eEw4r-2FWgYwLkGHPAMJB-2BitYkfBUnQB8qXIE1of5PVN5cjE1JheEFrosOCq124eilD6d3SXU-3D"
                                    target="_blank"
                                    data-saferedirecturl="https://www.google.com/url?q=https://u27556205.ct.sendgrid.net/ls/click?upn%3DLiOqRNUisYZ1bgUaTX4J90NXxLwbJJmncfY-2FALiE23ylmrL50duXFLZLFMg0BsjULkgZOpZueHnTSHIhGVA6jw-3D-3Drnrq_Zf9noBSXp6zmd8gcAmse0Aoz-2B3AvqIe2FFccggoWomsNvlieRIKtGB1Wb7DdoZuu8-2FR2QH3a2YivVbHlW-2BYig5mtUOfeWIE8rGp7MdYs56D0PErFEUAgP6CLjMeuON07bf1IoHHsCBVC6i1WmW7huVytu-2B6lSfjPfe0-2F5QWqC8yH3ftIwj92rvWe-2FCEsXuKrRukj0sHFmWqnY16SRhCMP-2FOS-2BtZ-2BwrnmjkcRlrVXZQb6JvpJBpPyU0pkMfpN88EGAVD5-2B48b3t4kRVF31qREnWJaY2Ez3DWjS0-2FWxcj8caVWy59VcRhvPxgMnHuEownNoM4PotzKfHRwTv1vcCjnuLiLEGo6QuMzFV5aaNLw1M79WHYV5q5TZ2froiTvSmmGkmYDH2fEfI2jXtc1-2Fru-2B5boXPBn5f0KtxO0POdcfBP-2BzR0RyaXTpRSUoa9isOAjrfGnrQNlqXw6UMudnEKo3dU-2BMqHasPuofbnlaVfFQkZid5ULJjoCFCWnWs7kpuQn17-2BHvntajHwnjcBImo0yROqxVsejkp-2Bx7P9S0eEw4r-2FWgYwLkGHPAMJB-2BitYkfBUnQB8qXIE1of5PVN5cjE1JheEFrosOCq124eilD6d3SXU-3D&amp;source=gmail&amp;ust=1689027781599000&amp;usg=AOvVaw3DlRgOUTYXd9s2LQQSAdmQ">Check
                                  it out</a>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </td>
              <td width="50%" style="padding-top:0px;padding-bottom:0px;padding-left:0px;padding-right:0px">
                <div><a style="text-decoration:none"
                  href="https://u27556205.ct.sendgrid.net/ls/click?upn=LiOqRNUisYZ1bgUaTX4J90NXxLwbJJmncfY-2FALiE23ylmrL50duXFLZLFMg0BsjULkgZOpZueHnTSHIhGVA6jw-3D-3Dcrqy_Zf9noBSXp6zmd8gcAmse0Aoz-2B3AvqIe2FFccggoWomsNvlieRIKtGB1Wb7DdoZuudtc80-2BUub7uV3b8KS0UI0sBbb6Lmpp9WKIz1S8xBdGwC9sPNZrGAs9n-2FkkI5gJlqocBK0skDcJbmQRDKveIgLSSu8oYAr0b2PrIw5yGmFfRpfUcGCHXNIWFfXNRe4IM0lbrLRHyA1r4-2FJ3jVi1UNjX-2FH-2BJeqKBw-2BsYHj-2FwX5DkM4-2F2kuZaqRmn12unw0-2FQYCj30GzLBPQ87zJmfEeDgqnflLNY-2FjOqP41nHLYbHpEfydFpltEwutItIXFGT45L1uIPGA8GKM5M-2Fa5fA-2BlCxJYLOSST6mXghhgwDFlMNW1KK4DR8BoRBsvI2QZUhuWF6WrrUcuEvpFr6YdJvNAbIZqpwYtuv9LRrnJfzO-2BkscS6BNivKr-2BfzoS5chqxD7GklDMKTzOyg-2Bk-2FHleIcIiRICAhL-2Fs0Kj5VY8JWYDH1fLK7F0LYfiHsQ4fYxm24ntCYHfRzPpr03uDaicymzq8afM3Q1XTZ6gbeK2ARzy9loIaqYxV6utc2ryv5LMcmTnbT1ebKpG7imwQTkZM2WVdpnpR8tZSfD3gQacxNSQtILhuTQ-3D"
                  target="_blank"
                  data-saferedirecturl="https://www.google.com/url?q=https://u27556205.ct.sendgrid.net/ls/click?upn%3DLiOqRNUisYZ1bgUaTX4J90NXxLwbJJmncfY-2FALiE23ylmrL50duXFLZLFMg0BsjULkgZOpZueHnTSHIhGVA6jw-3D-3Dcrqy_Zf9noBSXp6zmd8gcAmse0Aoz-2B3AvqIe2FFccggoWomsNvlieRIKtGB1Wb7DdoZuudtc80-2BUub7uV3b8KS0UI0sBbb6Lmpp9WKIz1S8xBdGwC9sPNZrGAs9n-2FkkI5gJlqocBK0skDcJbmQRDKveIgLSSu8oYAr0b2PrIw5yGmFfRpfUcGCHXNIWFfXNRe4IM0lbrLRHyA1r4-2FJ3jVi1UNjX-2FH-2BJeqKBw-2BsYHj-2FwX5DkM4-2F2kuZaqRmn12unw0-2FQYCj30GzLBPQ87zJmfEeDgqnflLNY-2FjOqP41nHLYbHpEfydFpltEwutItIXFGT45L1uIPGA8GKM5M-2Fa5fA-2BlCxJYLOSST6mXghhgwDFlMNW1KK4DR8BoRBsvI2QZUhuWF6WrrUcuEvpFr6YdJvNAbIZqpwYtuv9LRrnJfzO-2BkscS6BNivKr-2BfzoS5chqxD7GklDMKTzOyg-2Bk-2FHleIcIiRICAhL-2Fs0Kj5VY8JWYDH1fLK7F0LYfiHsQ4fYxm24ntCYHfRzPpr03uDaicymzq8afM3Q1XTZ6gbeK2ARzy9loIaqYxV6utc2ryv5LMcmTnbT1ebKpG7imwQTkZM2WVdpnpR8tZSfD3gQacxNSQtILhuTQ-3D&amp;source=gmail&amp;ust=1689027781600000&amp;usg=AOvVaw3hJRcoBXYXx4HiM8vw7y98"><img
                  src="https://firebasestorage.googleapis.com/v0/b/airasia-incredible-dev.appspot.com/o/email%2Fcommon%2Ffooter%2Ffooter-img-tips-and-tricks.png?alt=media&token=b68236d2-5c13-4b07-8fe4-1bd20c976870"
                  width="290" height="auto" alt="Image" style="display:block" class="CToWUd" data-bit="iit"></a>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </td>
    </tr>
    <tr>
      <td style="padding-top:20px;padding-bottom:20px;padding-left:20px;padding-right:20px;background-color:#ffffff">
        <table border="0" cellspacing="0" cellpadding="0" width="100%">
          <tbody>
            <tr>
              <td
                style="border-top:1px solid #000000;border-color:#ff0000;border-top-style:solid;border-top-width:1px;border-bottom:none;border-right:none;border-width:2px">
              </td>
            </tr>
          </tbody>
        </table>
      </td>
    </tr>
    <tr>
      <td style="background-color:#ffffff;padding-top:0;padding-bottom:0;padding-left:0;padding-right:0">
        <table cellpadding="0" cellspacing="0" width="100%">
          <tbody>
            <tr>
              <td width="50%" style="padding-top:0px;padding-bottom:0px;padding-left:0px;padding-right:0px">
                <div><a style="text-decoration:none"
                  href="https://u27556205.ct.sendgrid.net/ls/click?upn=LiOqRNUisYZ1bgUaTX4J92RgM5o8xMhJ89L8ZidCPlu4EwJi448RcnPtkvRUZbLwuBwrYH-2FPAawqf8ZdVsG32TSqQ0XrQkPBee5SU0mUgFccQ6P95MfH2EAA-2FhTM86u-2BUGA7_Zf9noBSXp6zmd8gcAmse0Aoz-2B3AvqIe2FFccggoWomsNvlieRIKtGB1Wb7DdoZuurKpKKwg1SYWBYdBPEsJArbxiYult8vufD4KuXafi-2Fm9ejmiIao32cLblPTwKI7-2FChSDcS0RO6-2B8dldH4-2FAnTyO42fLwt7gcRJ6HFqGEmw5k0BE8MT7Wm44NlSeDNtOQoAIdoQuLlkh8tJGhHwaKPe6gzc-2BL7BAYXxEmXPkh6tQjAQuTNq-2BDVt-2Bb7Ee0S4-2BnoIIRUWBFB0q4refHA28Oy9n8es2TSsWiXfZpPGl88rJuMNSSALi2-2FZ0Dvff-2FGVudS7ve-2F1Ov-2BHCMB-2B14LiWEow8S75IJyWbNCO7KFen-2F1P2cAga8YM0BLPebGlwHPhBJ1wg76-2FQdgTeZepZOxUbeCOsu3TZb5xlvNtbUEoSZ2k00f1evbINc8TViGH01N994JPjWSXp-2FXJ8b2Q-2F6J58pPsP9-2F-2BFHCVY5clWhaFhWjmpcc2DE98xbyKwN0RdGiP2zcC60SnXk7WoeZVWldaHHddFs7Z49uYzmia9V6qBEH2bjugJKtelVLxSD22KYHuWwSNwRC2O-2BRaIcdc1LPl2KJH3Y0uxGOeqWDZx2vHQIB-2BVw-3D"
                  target="_blank"
                  data-saferedirecturl="https://www.google.com/url?q=https://u27556205.ct.sendgrid.net/ls/click?upn%3DLiOqRNUisYZ1bgUaTX4J92RgM5o8xMhJ89L8ZidCPlu4EwJi448RcnPtkvRUZbLwuBwrYH-2FPAawqf8ZdVsG32TSqQ0XrQkPBee5SU0mUgFccQ6P95MfH2EAA-2FhTM86u-2BUGA7_Zf9noBSXp6zmd8gcAmse0Aoz-2B3AvqIe2FFccggoWomsNvlieRIKtGB1Wb7DdoZuurKpKKwg1SYWBYdBPEsJArbxiYult8vufD4KuXafi-2Fm9ejmiIao32cLblPTwKI7-2FChSDcS0RO6-2B8dldH4-2FAnTyO42fLwt7gcRJ6HFqGEmw5k0BE8MT7Wm44NlSeDNtOQoAIdoQuLlkh8tJGhHwaKPe6gzc-2BL7BAYXxEmXPkh6tQjAQuTNq-2BDVt-2Bb7Ee0S4-2BnoIIRUWBFB0q4refHA28Oy9n8es2TSsWiXfZpPGl88rJuMNSSALi2-2FZ0Dvff-2FGVudS7ve-2F1Ov-2BHCMB-2B14LiWEow8S75IJyWbNCO7KFen-2F1P2cAga8YM0BLPebGlwHPhBJ1wg76-2FQdgTeZepZOxUbeCOsu3TZb5xlvNtbUEoSZ2k00f1evbINc8TViGH01N994JPjWSXp-2FXJ8b2Q-2F6J58pPsP9-2F-2BFHCVY5clWhaFhWjmpcc2DE98xbyKwN0RdGiP2zcC60SnXk7WoeZVWldaHHddFs7Z49uYzmia9V6qBEH2bjugJKtelVLxSD22KYHuWwSNwRC2O-2BRaIcdc1LPl2KJH3Y0uxGOeqWDZx2vHQIB-2BVw-3D&amp;source=gmail&amp;ust=1689027781600000&amp;usg=AOvVaw0pugfMcn09UaOrM-HMmq7r"><img
                  src="https://firebasestorage.googleapis.com/v0/b/airasia-incredible-dev.appspot.com/o/email%2Fcommon%2Ffooter%2Ffooter-img-interview-tips.jpg?alt=media&token=6f3a51bc-ca17-48d5-91dd-e13708d61d7b"
                  width="290" height="auto" alt="Image" style="display:block" class="CToWUd" data-bit="iit"></a>
                </div>
              </td>
              <td width="50%" style="padding-top:0px;padding-bottom:0px;padding-left:20px;padding-right:20px"
                align="left">
                <div>
                  <div
                    style="margin-top:10px;margin-left:0;margin-bottom:10px;margin-right:0;font-size:16px;color:#00a9b7;font-family:Arial,Helvetica,sans-serif;letter-spacing:normal;display:block;line-height:150%">
                    <div><strong><span style="color:#e74c3c"><span
                      style="font-family:Arial,Helvetica,sans-serif"><span><span style="font-size:16px">More Cabin
                      Crew Interview Tips</span></span></span></span></strong>
                    </div>
                  </div>
                  <div
                    style="margin-top:10px;margin-right:0;margin-bottom:10px;margin-left:0;font-size:16px;color:#f5f5f5;font-family:Arial;letter-spacing:normal;display:block;line-height:150%">
                    <div><span style="font-size:12px"><span style="color:#4e5f70">Ama</span></span><span
                      style="color:#4e5f70"><span style="font-size:11px"></span></span><span
                      style="font-size:12px"><span style="color:#4e5f70">n Zakaria as a cabin crew expert in Airasia
                      is there to share the steps of the cabin crew interview, the selection stage, and also tips
                      for becoming a cabin crew in Airasia"</span></span>
                    </div>
                  </div>
                  <table style="border-collapse:separate">
                    <tbody>
                      <tr>
                        <td
                          style="color:#ffffff;display:block;padding-top:5px;padding-right:10px;padding-bottom:5px;padding-left:10px;margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;font-size:14px;font-family:Arial,Helvetica,sans-serif;background-color:#ff0000;border-radius:0px;border-width:0px;border-style:solid;border-color:#00a9b7;white-space:nowrap">
                          <table border="0" cellspacing="0" cellpadding="0">
                            <tbody>
                              <tr>
                                <td
                                  style="padding-top:0px;padding-right:0px;padding-bottom:0px;padding-left:0px;margin-top:0px;margin-right:0px;margin-bottom:0px;margin-left:0px;font-size:14px;font-family:Arial,Helvetica,sans-serif;background-color:transparent;border-radius:0px;border-width:0px;border-style:solid;border-color:transparent;white-space:nowrap;color:#ffffff;border:none">
                                  <a style="font-size:14px;font-family:Arial,Helvetica,sans-serif;color:#ffffff;text-decoration:none"
                                    href="https://u27556205.ct.sendgrid.net/ls/click?upn=LiOqRNUisYZ1bgUaTX4J92RgM5o8xMhJ89L8ZidCPlu4EwJi448RcnPtkvRUZbLwuBwrYH-2FPAawqf8ZdVsG32TSqQ0XrQkPBee5SU0mUgFccQ6P95MfH2EAA-2FhTM86u-2BWZqU_Zf9noBSXp6zmd8gcAmse0Aoz-2B3AvqIe2FFccggoWomsNvlieRIKtGB1Wb7DdoZuuDBpeB6Nwhjuq4MgtwPHvok1SjuuBGHnGm7f1J-2FLWpIuHl8XL59rPwMl38sYblly0ZdPrYRX4txOCvFYt5HqQhzc7p3JhtYjtgmJzT4m04gpWAbQAajt6ROKz6c5TPQ3v-2BWnvZ-2BW2ArfgXLaGZvrTwKs4xOztyu2COwEOV005-2FBjosNDhHDjiFKGuNL8LhJDCSpTkgdfM90g8cMN8-2F2eI9MNi7T-2Fn62EhX0ESVkGW2yjEvt-2Bq4FNI8tXVAz-2B4PXikUh2WpayVOIVklSZa5pPVtLg2-2Fv0a3IxQ-2FxcTgNtAF8nx6WeGNdrbSPbgsxr-2BeZuZAyIL27l4mP7RzBx3lZ7Lx1c-2FdO3hPMYOdZ9i3f52Q9AIsuXOBWu8SKph1CFCy972wzP-2B87xO0VA5Nf2Dzf5LItz4ttiLGS10KE5rz54lmcyM4WmSFGVlrpNC0UGwd7UfbmK4NB1dhG71bqmC7OhFobF55FUYXRn9HKspgrpMVkJktILqlXmQiny8OPhFwCD10EsuSiLyBoBq-2FsfMGoT9kUV-2Bjl9Ma3hkn1lSMOSobtQ-3D"
                                    target="_blank"
                                    data-saferedirecturl="https://www.google.com/url?q=https://u27556205.ct.sendgrid.net/ls/click?upn%3DLiOqRNUisYZ1bgUaTX4J92RgM5o8xMhJ89L8ZidCPlu4EwJi448RcnPtkvRUZbLwuBwrYH-2FPAawqf8ZdVsG32TSqQ0XrQkPBee5SU0mUgFccQ6P95MfH2EAA-2FhTM86u-2BWZqU_Zf9noBSXp6zmd8gcAmse0Aoz-2B3AvqIe2FFccggoWomsNvlieRIKtGB1Wb7DdoZuuDBpeB6Nwhjuq4MgtwPHvok1SjuuBGHnGm7f1J-2FLWpIuHl8XL59rPwMl38sYblly0ZdPrYRX4txOCvFYt5HqQhzc7p3JhtYjtgmJzT4m04gpWAbQAajt6ROKz6c5TPQ3v-2BWnvZ-2BW2ArfgXLaGZvrTwKs4xOztyu2COwEOV005-2FBjosNDhHDjiFKGuNL8LhJDCSpTkgdfM90g8cMN8-2F2eI9MNi7T-2Fn62EhX0ESVkGW2yjEvt-2Bq4FNI8tXVAz-2B4PXikUh2WpayVOIVklSZa5pPVtLg2-2Fv0a3IxQ-2FxcTgNtAF8nx6WeGNdrbSPbgsxr-2BeZuZAyIL27l4mP7RzBx3lZ7Lx1c-2FdO3hPMYOdZ9i3f52Q9AIsuXOBWu8SKph1CFCy972wzP-2B87xO0VA5Nf2Dzf5LItz4ttiLGS10KE5rz54lmcyM4WmSFGVlrpNC0UGwd7UfbmK4NB1dhG71bqmC7OhFobF55FUYXRn9HKspgrpMVkJktILqlXmQiny8OPhFwCD10EsuSiLyBoBq-2FsfMGoT9kUV-2Bjl9Ma3hkn1lSMOSobtQ-3D&amp;source=gmail&amp;ust=1689027781600000&amp;usg=AOvVaw3NV1ZvqqpEOwjX9EvYqpdn">Learn
                                  more</a>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </td>
    </tr>
    <tr>
      <td style="padding-top:20px;padding-bottom:20px;padding-left:20px;padding-right:20px;background-color:#ffffff">
        <table border="0" cellspacing="0" cellpadding="0" width="100%">
          <tbody>
            <tr>
              <td
                style="border-top:1px solid #000000;border-color:#ff0000;border-top-style:solid;border-top-width:1px;border-bottom:none;border-right:none;border-width:2px">
              </td>
            </tr>
          </tbody>
        </table>
      </td>
    </tr>
    <tr>
      <td style="background-color:#ffffff;padding-top:0;padding-bottom:0;padding-left:0;padding-right:0">
        <table cellpadding="0" cellspacing="0" width="100%">
          <tbody>
            <tr>
              <td width="50%" style="padding-top:0px;padding-bottom:0px;padding-left:20px;padding-right:20px"
                align="left">
                <div>
                  <div
                    style="margin-top:10px;margin-left:0;margin-bottom:10px;margin-right:0;font-size:16px;color:#00a9b7;font-family:Arial,Helvetica,sans-serif;letter-spacing:normal;display:block;line-height:150%">
                    <div><span style="font-size:16px"><strong><span style="color:#e74c3c"><span
                      style="font-family:Arial,Helvetica,sans-serif"><span><span>Live the
                      Dream!</span></span></span></span></strong></span>
                    </div>
                  </div>
                  <div
                    style="margin-top:10px;margin-right:0;margin-bottom:10px;margin-left:0;font-size:16px;color:#f5f5f5;font-family:Arial;letter-spacing:normal;display:block;line-height:150%">
                    <div><span style="color:#4e5f70"><span style="font-size:12px">Join our next hiring drive and you
                      could be shaking hands with Tony himself! The chance to work under the leadership of the one
                      and only Big Boss is finally within reach. Are you ready for the opportunity of a
                      lifetime?</span></span>
                    </div>
                    <div><br></div>
                  </div>
                  <table style="border-collapse:separate">
                    <tbody>
                      <tr>
                        <td
                          style="color:#ffffff;display:block;padding-top:5px;padding-right:10px;padding-bottom:5px;padding-left:10px;margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;font-size:14px;font-family:Arial,Helvetica,sans-serif;background-color:#ff0000;border-radius:0px;border-width:0px;border-style:solid;border-color:#00a9b7;white-space:nowrap">
                          <table border="0" cellspacing="0" cellpadding="0">
                            <tbody>
                              <tr>
                                <td
                                  style="padding-top:0px;padding-right:0px;padding-bottom:0px;padding-left:0px;margin-top:0px;margin-right:0px;margin-bottom:0px;margin-left:0px;font-size:14px;font-family:Arial,Helvetica,sans-serif;background-color:transparent;border-radius:0px;border-width:0px;border-style:solid;border-color:transparent;white-space:nowrap;color:#ffffff;border:none">
                                  <a style="font-size:14px;font-family:Arial,Helvetica,sans-serif;color:#ffffff;text-decoration:none"
                                    href="https://u27556205.ct.sendgrid.net/ls/click?upn=LiOqRNUisYZ1bgUaTX4J94yoPT-2BV98KbtJESmQPa1PdHztGC2C0izola5SpTYAaid13W_Zf9noBSXp6zmd8gcAmse0Aoz-2B3AvqIe2FFccggoWomsNvlieRIKtGB1Wb7DdoZuu-2BEoXQVKwEjcDi2dYMgXpuE5qxpkDIFecV-2F2JXO9mtJ3rYH2WNx3SC97GxunW3FI8cf88pIEp-2FSFC3Axmi0tWTG6ifSVaY5egCBL6OfptoOLyXAgB-2Fn1GtxKWBU8BYL0iYJScu8-2BBqzkVTVsfh03FBZeVUqpsHN6OE3lGAoKqiH84PKQWf-2FFXDicVgVX-2Bx3GIGMGaHjFXmkXlbMCulUB4CfYBOVpw-2FnANGbVsCgkGIk-2BCACbwHdFEOjyo3-2BvBq6sMtKNLaa6e0OeHs53XXkMJrkf8PrT0gxs-2FNv1aC2t1RML53eP0i54GHRJI0x69UBv1QzJdyEbCYDBVbVArt69ruHulAQZl420gluZh15gkwUnLlZtD-2FYKJUCqAWJo-2FPLlZiu0C7svbqn5WWMiWdrfkzl-2F4yzjpHNAtzanlDY5PrYMMJwtVhBBtPqDbtlSk8KQq-2FrXZUttcOuuXontqO6w9r5Vbl1DOuqKNH9hoLhtS1-2BO5ghfyw2zvY9R7aJvyjx1fTSZvEl8U69GAOtPc6Mq9aUoTU1MSFXfjP-2FGD74-2BlJfY-3D"
                                    target="_blank"
                                    data-saferedirecturl="https://www.google.com/url?q=https://u27556205.ct.sendgrid.net/ls/click?upn%3DLiOqRNUisYZ1bgUaTX4J94yoPT-2BV98KbtJESmQPa1PdHztGC2C0izola5SpTYAaid13W_Zf9noBSXp6zmd8gcAmse0Aoz-2B3AvqIe2FFccggoWomsNvlieRIKtGB1Wb7DdoZuu-2BEoXQVKwEjcDi2dYMgXpuE5qxpkDIFecV-2F2JXO9mtJ3rYH2WNx3SC97GxunW3FI8cf88pIEp-2FSFC3Axmi0tWTG6ifSVaY5egCBL6OfptoOLyXAgB-2Fn1GtxKWBU8BYL0iYJScu8-2BBqzkVTVsfh03FBZeVUqpsHN6OE3lGAoKqiH84PKQWf-2FFXDicVgVX-2Bx3GIGMGaHjFXmkXlbMCulUB4CfYBOVpw-2FnANGbVsCgkGIk-2BCACbwHdFEOjyo3-2BvBq6sMtKNLaa6e0OeHs53XXkMJrkf8PrT0gxs-2FNv1aC2t1RML53eP0i54GHRJI0x69UBv1QzJdyEbCYDBVbVArt69ruHulAQZl420gluZh15gkwUnLlZtD-2FYKJUCqAWJo-2FPLlZiu0C7svbqn5WWMiWdrfkzl-2F4yzjpHNAtzanlDY5PrYMMJwtVhBBtPqDbtlSk8KQq-2FrXZUttcOuuXontqO6w9r5Vbl1DOuqKNH9hoLhtS1-2BO5ghfyw2zvY9R7aJvyjx1fTSZvEl8U69GAOtPc6Mq9aUoTU1MSFXfjP-2FGD74-2BlJfY-3D&amp;source=gmail&amp;ust=1689027781600000&amp;usg=AOvVaw2-v2VWc7G1X8mQMp0GOk-c">Watch
                                  the video</a>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </td>
              <td width="50%" style="padding-top:0px;padding-bottom:0px;padding-left:0px;padding-right:0px">
                <div><a style="text-decoration:none"
                  href="https://u27556205.ct.sendgrid.net/ls/click?upn=LiOqRNUisYZ1bgUaTX4J94yoPT-2BV98KbtJESmQPa1PdHztGC2C0izola5SpTYAaiz465_Zf9noBSXp6zmd8gcAmse0Aoz-2B3AvqIe2FFccggoWomsNvlieRIKtGB1Wb7DdoZuu8wCd3Sx2DuWZ0xKIdoZVbgsDkfyUID8xiNK9elPJsrTYFoxEOw6O-2Fg31l9BIv7azvx-2F-2BAtPIEYOJ7pzBC-2Bq7OTo79lEwPzpBuhrWaPoSPTa6cp3nBjou7GdV-2FwOuKb7tXGHVd9unNIviosFQ-2FarLa2LOdasDhMubPnD6t5-2FoiJDEjZUt9513IS73wS05gnRNsdc946Qf0w9kkERjs-2B-2BKaLd5iah2FNKVUuiZ6-2FHctJ3-2BNFnR3XlA5eOmukpqyLl5g-2BF-2FzV3L490DGvk2IDnbx23kcP-2F-2F4qA1TdQF2w4ohGfqm6IwbEfK9jZ5BI-2BP-2FIl1vUvjTMgbDopZTfDdRj44-2BFReezXb8OHTSa23-2BrzX48k-2FgEW5DIwYSe-2Fp5-2BqDCAitNyrgqurFtD4yd-2BbWp6xzfKeLMpUbFV37B0oxqcooGPzLNfzzVBx2YkSjBwr5nVMAhAWRmxDNOwb-2FFqz2o-2F8yxOwtT3y8gUoTjI-2BautGCAqEC-2FgPTWZH-2BVFFDJhA5s4ZctDRGnpi6Z3PhmYj6jmenXJJNCwDvYxjmXJuKC0sn5r8-3D"
                  target="_blank"
                  data-saferedirecturl="https://www.google.com/url?q=https://u27556205.ct.sendgrid.net/ls/click?upn%3DLiOqRNUisYZ1bgUaTX4J94yoPT-2BV98KbtJESmQPa1PdHztGC2C0izola5SpTYAaiz465_Zf9noBSXp6zmd8gcAmse0Aoz-2B3AvqIe2FFccggoWomsNvlieRIKtGB1Wb7DdoZuu8wCd3Sx2DuWZ0xKIdoZVbgsDkfyUID8xiNK9elPJsrTYFoxEOw6O-2Fg31l9BIv7azvx-2F-2BAtPIEYOJ7pzBC-2Bq7OTo79lEwPzpBuhrWaPoSPTa6cp3nBjou7GdV-2FwOuKb7tXGHVd9unNIviosFQ-2FarLa2LOdasDhMubPnD6t5-2FoiJDEjZUt9513IS73wS05gnRNsdc946Qf0w9kkERjs-2B-2BKaLd5iah2FNKVUuiZ6-2FHctJ3-2BNFnR3XlA5eOmukpqyLl5g-2BF-2FzV3L490DGvk2IDnbx23kcP-2F-2F4qA1TdQF2w4ohGfqm6IwbEfK9jZ5BI-2BP-2FIl1vUvjTMgbDopZTfDdRj44-2BFReezXb8OHTSa23-2BrzX48k-2FgEW5DIwYSe-2Fp5-2BqDCAitNyrgqurFtD4yd-2BbWp6xzfKeLMpUbFV37B0oxqcooGPzLNfzzVBx2YkSjBwr5nVMAhAWRmxDNOwb-2FFqz2o-2F8yxOwtT3y8gUoTjI-2BautGCAqEC-2FgPTWZH-2BVFFDJhA5s4ZctDRGnpi6Z3PhmYj6jmenXJJNCwDvYxjmXJuKC0sn5r8-3D&amp;source=gmail&amp;ust=1689027781600000&amp;usg=AOvVaw1Jydo01X6dlh8ePnG7l0W7"><img
                  src="https://firebasestorage.googleapis.com/v0/b/airasia-incredible-dev.appspot.com/o/email%2Fcommon%2Ffooter%2Ffooter-img-live-the-dream.jpg?alt=media&token=934b961c-f3e9-4642-8229-66219b6e723a"
                  width="290" height="auto" alt="Image" style="display:block" class="CToWUd" data-bit="iit"></a>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </td>
    </tr>
    <tr>
      <td style="padding-top:20px;padding-bottom:20px;padding-left:20px;padding-right:20px;background-color:#ffffff">
        <table border="0" cellspacing="0" cellpadding="0" width="100%">
          <tbody>
            <tr>
              <td
                style="border-top:1px solid #000000;border-color:#ff0000;border-top-style:solid;border-top-width:1px;border-bottom:none;border-right:none;border-width:2px">
              </td>
            </tr>
          </tbody>
        </table>
      </td>
    </tr>
    <tr>
      <td style="background-color:#ffffff;padding-top:0;padding-bottom:0;padding-left:0;padding-right:0">
        <table cellpadding="0" cellspacing="0" width="100%">
          <tbody>
            <tr>
              <td width="50%" style="padding-top:0px;padding-bottom:0px;padding-left:0px;padding-right:0px">
                <div><a style="text-decoration:none"
                  href="https://u27556205.ct.sendgrid.net/ls/click?upn=LiOqRNUisYZ1bgUaTX4J9yFnCV-2FuzRQlgGymtJs9eInrg7DarzWEfpkSTg-2BcV3xMWN36e80XeQBnA2Op2zuMYvSpADFAhC9RlWo3Z4W7ZpY-3DS-G0_Zf9noBSXp6zmd8gcAmse0Aoz-2B3AvqIe2FFccggoWomsNvlieRIKtGB1Wb7DdoZuuk3OCjfTQ0u8tsI3Ix2DaRYgFkXgTNl4eJOrE3HAzzZbhy5RiFpyq4h14ydJrltkZ58E4eY2z-2BFYxufuHw0HfkzFjlCGFXT6qjtU34j1jp5MyJc7RfXyB2muXLKxaxkQdTxF7Lly9OtwzuQfpf40s19axB22Iy9-2F3JaXTkaRvC4krDG7m5zsFDIIXFVFps8alaYqgh6lLlxKaZxzbU2mlUbuSbPhWxIbZbGdmHvKOfVOQitbfE08QNsPzzBL0-2Fs-2BPAp2QJe8-2Bp6K9SCIFLEaUORz5u7WeSdV1JQHZYGsPxjaSd6AAQe9ZnidnYkoAuBID-2FROC2Byq6RHj9hucLhZBYYlS-2BSN-2Bkl8v67fyf9vUuzMbvWnIG6DAtjfQ-2ByrbmRPepWY7XA2puyNYt02DOgRVDonPyb2aSazNuqbwTNIGDr9kXVqvPn2uiSQ6aghVe30nYqp0pRxpbXIdpRXWyOawNB2kRJ0G9NcSwOKPSyje59Hca7qS0LQMnOT5OCSDH-2B8SnRDZMIgXMSJIJWmWpGlJjZtq80vJCx7H-2FcunDCNIr3w-3D"
                  target="_blank"
                  data-saferedirecturl="https://www.google.com/url?q=https://u27556205.ct.sendgrid.net/ls/click?upn%3DLiOqRNUisYZ1bgUaTX4J9yFnCV-2FuzRQlgGymtJs9eInrg7DarzWEfpkSTg-2BcV3xMWN36e80XeQBnA2Op2zuMYvSpADFAhC9RlWo3Z4W7ZpY-3DS-G0_Zf9noBSXp6zmd8gcAmse0Aoz-2B3AvqIe2FFccggoWomsNvlieRIKtGB1Wb7DdoZuuk3OCjfTQ0u8tsI3Ix2DaRYgFkXgTNl4eJOrE3HAzzZbhy5RiFpyq4h14ydJrltkZ58E4eY2z-2BFYxufuHw0HfkzFjlCGFXT6qjtU34j1jp5MyJc7RfXyB2muXLKxaxkQdTxF7Lly9OtwzuQfpf40s19axB22Iy9-2F3JaXTkaRvC4krDG7m5zsFDIIXFVFps8alaYqgh6lLlxKaZxzbU2mlUbuSbPhWxIbZbGdmHvKOfVOQitbfE08QNsPzzBL0-2Fs-2BPAp2QJe8-2Bp6K9SCIFLEaUORz5u7WeSdV1JQHZYGsPxjaSd6AAQe9ZnidnYkoAuBID-2FROC2Byq6RHj9hucLhZBYYlS-2BSN-2Bkl8v67fyf9vUuzMbvWnIG6DAtjfQ-2ByrbmRPepWY7XA2puyNYt02DOgRVDonPyb2aSazNuqbwTNIGDr9kXVqvPn2uiSQ6aghVe30nYqp0pRxpbXIdpRXWyOawNB2kRJ0G9NcSwOKPSyje59Hca7qS0LQMnOT5OCSDH-2B8SnRDZMIgXMSJIJWmWpGlJjZtq80vJCx7H-2FcunDCNIr3w-3D&amp;source=gmail&amp;ust=1689027781600000&amp;usg=AOvVaw2KeMXzJUHeUjVOre0Vpg_G"><img
                  src="https://firebasestorage.googleapis.com/v0/b/airasia-incredible-dev.appspot.com/o/email%2Fcommon%2Ffooter%2Ffooter-img-join-community.png?alt=media&token=bc950280-0df6-469e-9234-b56f76c4e827"
                  width="290" height="auto" alt="Image" style="display:block" class="CToWUd" data-bit="iit"></a>
                </div>
              </td>
              <td width="50%" style="padding-top:0px;padding-bottom:0px;padding-left:20px;padding-right:20px"
                align="left">
                <div>
                  <div
                    style="margin-top:10px;margin-left:0;margin-bottom:10px;margin-right:0;font-size:16px;color:#00a9b7;font-family:Arial,Helvetica,sans-serif;letter-spacing:normal;display:block;line-height:150%">
                    <div><span style="color:#e74c3c"><strong>Join our Talent Community</strong></span></div>
                  </div>
                  <div
                    style="margin-top:10px;margin-right:0;margin-bottom:10px;margin-left:0;font-size:16px;color:#f5f5f5;font-family:Arial;letter-spacing:normal;display:block;line-height:150%">
                    <div><span style="font-size:11px"><span style="color:#4e5f70">Join our Talent Community and stay
                      connected with us - we will share content that adds value and supports you with your career
                      goals</span></span>
                    </div>
                  </div>
                  <table style="border-collapse:separate">
                    <tbody>
                      <tr>
                        <td
                          style="color:#ffffff;display:block;padding-top:5px;padding-right:10px;padding-bottom:5px;padding-left:10px;margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;font-size:14px;font-family:Arial,Helvetica,sans-serif;background-color:#ff0000;border-radius:0px;border-width:0px;border-style:solid;border-color:#00a9b7;white-space:nowrap">
                          <table border="0" cellspacing="0" cellpadding="0">
                            <tbody>
                              <tr>
                                <td
                                  style="padding-top:0px;padding-right:0px;padding-bottom:0px;padding-left:0px;margin-top:0px;margin-right:0px;margin-bottom:0px;margin-left:0px;font-size:14px;font-family:Arial,Helvetica,sans-serif;background-color:transparent;border-radius:0px;border-width:0px;border-style:solid;border-color:transparent;white-space:nowrap;color:#ffffff;border:none">
                                  <a style="font-size:14px;font-family:Arial,Helvetica,sans-serif;color:#ffffff;text-decoration:none"
                                    href="https://u27556205.ct.sendgrid.net/ls/click?upn=LiOqRNUisYZ1bgUaTX4J9yFnCV-2FuzRQlgGymtJs9eInrg7DarzWEfpkSTg-2BcV3xMWN36e80XeQBnA2Op2zuMYvSpADFAhC9RlWo3Z4W7ZpY-3DZGLq_Zf9noBSXp6zmd8gcAmse0Aoz-2B3AvqIe2FFccggoWomsNvlieRIKtGB1Wb7DdoZuuzPy2HxrsgiztIyKhpbwuz7hDJ02cTGpCPMn-2Fyg1A3Tg55uWEDyyIjLyEJrdQHu22WrShwbxHN887SHfx2QKioq0W1LVMTLwjLZKxxK1LOPeEi8JuMLPGqnd7OkeCVhL8G4I-2F-2FWZTpeIiCN9PGISXDj6wowN31VaBlmPC8DSyhww4zRiK2G2aVH3pbe9Gb1llIo6JRUSKNMcJAppLnnhn82Z8zSkYa9RYTjrfRsbAV7f4E83EoeBrQHHRKO20pxKOYJcs64vQxsJzN9g8flQtfoByWPSXGm0uyW1HkGfHx3J4RwgsZsMuc8mAQBD9E7bG1Ee1NknVig6qUgqCw-2BbL0qCpfpza8HDu9UcbqL1Xi8def9yFBKua1-2BxdPIhnBbUJwyzGexcZyiGLCJqFsTZBTXt8V2Ct5QJW5cABgEpeXLAs4viLxMbPejlkWPLmElQjGyXli497-2FHSYvIcmaqKcJNyKk6lEGOlbtrOi4dlBAMD8D8NV2s-2Bex7NG-2ByjE3S-2F1UssY6K962qTewG13Ob3qXYUvNUxdJtTontl7fNTdprg-3D"
                                    target="_blank"
                                    data-saferedirecturl="https://www.google.com/url?q=https://u27556205.ct.sendgrid.net/ls/click?upn%3DLiOqRNUisYZ1bgUaTX4J9yFnCV-2FuzRQlgGymtJs9eInrg7DarzWEfpkSTg-2BcV3xMWN36e80XeQBnA2Op2zuMYvSpADFAhC9RlWo3Z4W7ZpY-3DZGLq_Zf9noBSXp6zmd8gcAmse0Aoz-2B3AvqIe2FFccggoWomsNvlieRIKtGB1Wb7DdoZuuzPy2HxrsgiztIyKhpbwuz7hDJ02cTGpCPMn-2Fyg1A3Tg55uWEDyyIjLyEJrdQHu22WrShwbxHN887SHfx2QKioq0W1LVMTLwjLZKxxK1LOPeEi8JuMLPGqnd7OkeCVhL8G4I-2F-2FWZTpeIiCN9PGISXDj6wowN31VaBlmPC8DSyhww4zRiK2G2aVH3pbe9Gb1llIo6JRUSKNMcJAppLnnhn82Z8zSkYa9RYTjrfRsbAV7f4E83EoeBrQHHRKO20pxKOYJcs64vQxsJzN9g8flQtfoByWPSXGm0uyW1HkGfHx3J4RwgsZsMuc8mAQBD9E7bG1Ee1NknVig6qUgqCw-2BbL0qCpfpza8HDu9UcbqL1Xi8def9yFBKua1-2BxdPIhnBbUJwyzGexcZyiGLCJqFsTZBTXt8V2Ct5QJW5cABgEpeXLAs4viLxMbPejlkWPLmElQjGyXli497-2FHSYvIcmaqKcJNyKk6lEGOlbtrOi4dlBAMD8D8NV2s-2Bex7NG-2ByjE3S-2F1UssY6K962qTewG13Ob3qXYUvNUxdJtTontl7fNTdprg-3D&amp;source=gmail&amp;ust=1689027781600000&amp;usg=AOvVaw0vMl2CzFU_a220pHKo0huo">Join
                                  Now</a>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </td>
    </tr>
    <tr>
      <td style="padding-top:20px;padding-bottom:20px;padding-left:20px;padding-right:20px;background-color:#ffffff">
        <table border="0" cellspacing="0" cellpadding="0" width="100%">
          <tbody>
            <tr>
              <td
                style="border-top:1px solid #000000;border-color:#ff0000;border-top-style:solid;border-top-width:1px;border-bottom:none;border-right:none;border-width:2px">
              </td>
            </tr>
          </tbody>
        </table>
      </td>
    </tr>
    <tr>
      <td style="background-color:#ff0000">
        <table cellpadding="0" cellspacing="0" width="100%">
          <tbody>
            <tr>
              <td width="50%" style="padding-top:0px;padding-left:20px;padding-bottom:0px;padding-right:0px"
                align="left">
                <div style="display:block">
                  <div
                    style="margin-left:0;color:#ffffff;letter-spacing:normal;padding-left:0px;font-size:20px;margin-top:10px;font-family:Arial,Helvetica,sans-serif;margin-bottom:10px;margin-right:0;line-height:150%">
                    <div><span style="font-family:Arial,Helvetica,sans-serif"><span style="color:#ffffff"><span
                      style="font-size:20px">Connect With</span></span></span><span
                      style="color:#ffffff"></span><span style="font-family:Arial,Helvetica,sans-serif"><span
                      style="color:#ffffff"><span style="font-size:20px"> us on</span></span></span></div>
                  </div>
                </div>
              </td>
              <td width="50%" style="padding-top:10px;padding-left:0px;padding-bottom:10px;padding-right:0px"
                align="right">
                <table border="0" cellspacing="0" cellpadding="0">
                  <tbody>
                    <tr>
                      <td style="padding-right:15px">
                        <a style="text-decoration:none" href="">
                          <img src="https://firebasestorage.googleapis.com/v0/b/airasia-incredible-dev.appspot.com/o/email%2Fcommon%2Ffooter%2Fsocial%2Ffooter-icon-linked-in.png?alt=media&token=a90279c5-db20-4cb8-8bca-6e87afc4580f" width="36" height="36" alt="Image" style="margin-left:0;display:inline-block;margin-top:0px;margin-bottom:0" class="CToWUd" data-bit="iit">
                        </a>
                      </td>
                      <td style="padding-right:15px">
                        <a style="text-decoration:none" href="">
                          <img src="https://firebasestorage.googleapis.com/v0/b/airasia-incredible-dev.appspot.com/o/email%2Fcommon%2Ffooter%2Fsocial%2Ffooter-icon-facebook.png?alt=media&token=78464428-0020-4f93-aae4-9b366fb63c52" width="36" height="36" alt="Image" style="margin-left:0;display:inline-block;margin-top:0px;margin-bottom:0" class="CToWUd" data-bit="iit">
                        </a>
                      </td>
                      <td style="padding-right:15px">
                        <a style="text-decoration:none" href="">
                          <img src="https://firebasestorage.googleapis.com/v0/b/airasia-incredible-dev.appspot.com/o/email%2Fcommon%2Ffooter%2Fsocial%2Ffooter-icon-tiktok.png?alt=media&token=fc19d81e-d71d-41e6-ab48-f476e08e9a3b" width="36" height="36" alt="Image" style="margin-left:0;display:inline-block;margin-top:0px;margin-bottom:0" class="CToWUd" data-bit="iit">
                        </a>
                      </td>
                      <td style="padding-right:15px">
                        <a style="text-decoration:none" href="">
                          <img src="https://firebasestorage.googleapis.com/v0/b/airasia-incredible-dev.appspot.com/o/email%2Fcommon%2Ffooter%2Fsocial%2Ffooter-icon-instagram.png?alt=media&token=8fcda04d-c724-415c-a925-f11158dff9b2" width="36" height="36" alt="Image" style="margin-left:0;display:inline-block;margin-top:0px;margin-bottom:0" class="CToWUd" data-bit="iit">
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
      </td>
    </tr>
    <tr>
      <td style="background-color:#ff0000;padding-top:5px;padding-left:20px;padding-bottom:5px;padding-right:20px"
        align="left">
        <div>
          <div
            style="margin-left:0px;color:#4b6479;letter-spacing:normal;font-size:16px;margin-top:0px;font-family:Arial;margin-bottom:0px;margin-right:0px;line-height:150%">
            <div><span style="color:#ffffff"><span style="font-size:12px"><span
              style="font-family:Arial,Helvetica,sans-serif">© 2023&nbsp;Capital A Berhad. All rights
              reserved.</span></span></span>
            </div>
            <div><span style="color:#ffffff"><span style="font-size:12px"><span
              style="font-family:Arial,Helvetica,sans-serif">To ensure delivery to your inbox, please add <a
              href="mailto:opportunities@careeralerts.airasia.com"
              style="color:#ffffff;font-family:Arial,Helvetica,sans-serif;font-size:inherit;background-color:inherit"
              target="_blank"><span style="color:#ffffff">opportunities@careeralerts.<wbr>airasia.com</span></a>
              to your address book</span></span></span>
            </div>
            <div><br></div>
          </div>
        </div>
      </td>
    </tr>`;
}

export const emailTemplateFooterImage = (imageUrl, link) => {
  return `
    <tr>
      <td style="padding-top:5px;padding-bottom:5px;padding-left:20px;padding-right:20px;background-color:#ffffff" align="left">
        <div>
          <a style="text-decoration:none" href="${link ?? '#'}">
            <img src="${imageUrl ?? 'https://firebasestorage.googleapis.com/v0/b/airasia-incredible-dev.appspot.com/o/email%2Fcommon%2Ffooter%2Ffooter-img.jpg?alt=media&token=ec4223f8-4247-4567-a28e-d3a51c285c48'}" width="540" height="auto" alt="Image" style="margin-top:0px;margin-bottom:0px;margin-left:0px;margin-right:0px" class="CToWUd" data-bit="iit">
          </a>
        </div>
      </td>
    </tr>
  `;
}

export const emailTemplatePreFooter = (url) => {
  return `
    <tr>
      <td>
        <div><br/></div>
        <div style="text-align:center"><span style="color:#c0392b">Click the <strong>I am attending</strong> button below to confirm your attendance.</span></div>
        <div><br/></div>
      </td>
    </tr>
    <tr>
      <td style="padding-top:5px;padding-bottom:5px;padding-left:20px;padding-right:20px;background-color:#ffffff" align="center">
        <table>
          <tbody>
            <tr>
              <td style="padding-top:5px;padding-bottom:5px;padding-left:10px;padding-right:10px;background-color:#ff0000;border-radius:0px;border-width:1px;border-style:solid;border-color:#b6b6b6">
                <table border="0" cellspacing="0" cellpadding="0">
                  <tbody>
                    <tr>
                      <td style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;font-size:14px;font-family:Arial,Helvetica,sans-serif;white-space:nowrap;color:#ffffff">
                        <a style="font-size:14px;font-family:Arial,Helvetica,sans-serif;color:#ffffff;text-decoration:none" href="${url ?? "#"}" target="_blank">
                          I am attending
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
      </td>
    </tr>
    <tr>
      <td style="padding-top:5px;padding-bottom:5px;padding-left:20px;padding-right:20px;background-color:#ffffff" align="left">
        <div>
          <div style="margin-top:0px;margin-right:0px;margin-bottom:0px;margin-left:0px;font-size:16px;color:#4b6479;font-family:Arial;letter-spacing:normal;line-height:150%">
            <div style="text-align:center"><br></div>
            <div>Cheers,</div>
            <div>AirAsia's Recruitment Team</div>
            <div><br></div>
          </div>
        </div>
      </td>
    </tr>`;
}

export const emailTemplateHeader = (link, imageUrl) => {
  return `
    <tr>
      <td style="background-color:#ffffff;padding-top:5px;padding-left:20px;padding-bottom:5px;padding-right:20px" align="left">
        <div>
          <a style="text-decoration:none" href="${link ?? "#"}" target="_blank">
            <img src="${imageUrl ?? "https://firebasestorage.googleapis.com/v0/b/airasia-incredible-dev.appspot.com/o/logoHeader.png?alt=media&token=f105bd02-3a40-404d-a6c2-dcb8d2e910fb"}" width="100" height="100" alt="image-default.png" style="margin-left:0px;margin-top:0px;margin-bottom:0px;margin-right:0px" class="CToWUd" data-bit="iit">
          </a>
        </div>
      </td>
    </tr>`;
}

export const emailTemplateHeaderImage = ( imageUrl ) => {
  if ( imageUrl ) {
    return `
      <tr>
        <td style="padding-top:5px;padding-bottom:5px;padding-left:20px;padding-right:20px;background-color:#ffffff" align="center">
          <div>
            <img src="${imageUrl}" width="540" height="auto" alt="Image" style="margin-top:0px;margin-bottom:0px;margin-left:0px;margin-right:0px" class="CToWUd a6T" data-bit="iit" tabindex="0">
            <div class="a6S" dir="ltr" style="opacity: 0.01; left: 768px; top: 991.5px;">
              <div id=":vv" class="T-I J-J5-Ji aQv T-I-ax7 L3 a5q" role="button" tabindex="0" aria-label="Download attachment " jslog="91252; u014N:cOuCgd,Kr2w4b,xr6bB; 4:WyIjbXNnLWY6MTc3MDc0ODY5Mjk2ODgzOTE4NSIsbnVsbCxbXV0." data-tooltip-class="a1V" data-tooltip="Download">
                <div class="akn">
                  <div class="aSK J-J5-Ji aYr"></div>
                </div>
              </div>
            </div>
          </div>
        </td>
      </tr>`;
  } else {
    return "";
  }
}

export const emailTemplateContainer = ( content ) => {
  return `
    <table cellpadding="0" cellspacing="0" width="580" bgcolor="#FFFFFF" align="center" style="font-family: Arial, Helvetica, sans-serif; background-color:#ffffff;margin:0 auto;width:580">
      <tbody>
        ${content}
      </tbody>
    </table>`;
}
