import React, { useEffect, useState } from 'react'
import { Box, CircularProgress, Divider, Popper, Typography } from '@mui/material'
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { getDashboardEventData, getExAACount } from '../../../lib/db_event';
import HelpIcon from '@mui/icons-material/Help';

const boxStyle = {
    mb: 1,
    p: 2,
    borderRadius: "4px",
    boxShadow: "0px 1px 4px 0px #00000029",
};

const percentageStyle = {
    marginLeft: "5ch",
    padding: "0px 8px",
    alignItems: "center",
    boxShadow: "0px 1px 4px 0px #00000029",
    width: "fit-content",
    backgroundColor: "white",
    border: 1,
    borderRadius: "8px",
};

export default function EventDashboardSideInfo({ event, eventId }) {
    const [details, setDetails] = useState({
        totalRegistration: 0,
        showUp: 0,
        noShow: 0,
        showUpRates: 0,
        noShowUpRates: 0
    })
    const [special, setSpecial] = useState({
        total: 0,
        rates: 0,
    })
    const [isLoading, setIsLoading] = useState(false)
    const [showPopper, setShowPopper] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null)

    useEffect(() => {
        const getShowUpRate = async () => {
            setIsLoading(true)
            const response = await getDashboardEventData(eventId)
            const getSpecial = await getExAACount(eventId)
            setDetails({
                totalRegistration: response.metrics.totalRegistered,
                showUp: response.metrics.totalAttended,
                noShow: response.metrics.totalNoShow,
                showUpRates: Math.round(response.showUpRates * 100) / 100 ,
                noShowUpRates: Math.round(response.noShowUpRates * 100) / 100,
            })
            setSpecial({
                total: getSpecial.special_registration_count,
                rates: Math.round(getSpecial.special_registration_rate * 100) / 100,
            })
            setIsLoading(false)
        }
        getShowUpRate()
    }, [eventId])

    const handleMouseEnter = (e) => {
        setAnchorEl(e.currentTarget);
        setShowPopper(true)
    }

    const handleMouseLeave = () => {
        setShowPopper(false)
    }
    
    return (
        <>
            <Typography sx={{ color: "#4B4FA6", mt: -1 }} variant='h5'><strong>Event {event.event_code}</strong></Typography>
            <Box mt={1} display={'flex'}>
                <Typography sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', flex: 1 }} variant='caption'><CalendarTodayIcon sx={{ mr: 0.5 }} fontSize='small' /><strong>{event.dashboard_date}</strong></Typography>
                <Typography sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', flex: 1 }} variant='caption'><LocationOnIcon sx={{ mr: 0.5 }} fontSize='small' /><strong>{event.event_venue}</strong></Typography>
            </Box>
            <Divider sx={{ borderBottomWidth: 2, mt: 1, mb: 1 }} variant='fullWidth' />
            {!isLoading ?
                <>
                    <Typography variant='body2' sx={{ mb: 1 }}><strong>Registration Details</strong></Typography>
                    <Box display={'flex'}>
                        <Box flex={1} sx={{ backgroundColor: "#3A3D7D", ...boxStyle, backgroundImage: "url(/images/totalRegister.png)", backgroundRepeat: "no-repeat", backgroundPosition: "right" }}>
                            <Typography color={"white"} variant='body2' sx={{ mb: 1 }}>Total Candidates</Typography>
                            <Typography color={"white"} variant='body1'>{details.totalRegistration}</Typography>
                        </Box>
                    </Box>
                    <Box display={'flex'}>
                        <Box flex={1} sx={{ backgroundColor: "#EDEDF6", ...boxStyle }}>
                            <Box display={"flex"}>
                                <Typography color={"#3A3D7D"} variant='body2' sx={{ mb: 1 }}>Special Registration</Typography>
                                <Box onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                                    <HelpIcon sx={{height: "16px", width: "16px", color:'#3A3D7D', ml: "16px"}}/>
                                    <Popper anchorEl={anchorEl} open={showPopper} placement='right'>
                                        <Box sx={{ border: 1, p: 1, bgcolor: 'background.paper' }}>
                                            <Typography variant='caption'>Candidates who are ex-Airasia.</Typography>
                                        </Box>
                                    </Popper>
                                </Box>
                            </Box>
                            <Box display={"flex"}>
                                <Typography color={"#3A3D7D"} variant='body1'>{special.total}</Typography>
                                <Typography color={"#1A5436"} style={percentageStyle}>{special.rates}%</Typography>
                            </Box>
                        </Box>
                    </Box>
                    <Typography variant='body2' sx={{ mb: 1, mt: 1 }}><strong>Number of attendance</strong></Typography>
                    <Box display={'flex'}>
                        <Box flex={1} sx={{ backgroundColor: "#FFFFFF", ...boxStyle, backgroundImage: "url(/images/happy.png)", backgroundRepeat: "no-repeat", backgroundPosition: "right", backgroundPositionY: "-1px" }}>
                            <Typography color={"#3A3D7D"} variant='body2' sx={{ mb: 1 }}>Total show up</Typography>
                            <Box display={"flex"}>
                                <Typography color={"#3A3D7D"} variant='body1'>{details.showUp}</Typography>
                                <Typography color={"#1A5436"} style={percentageStyle}>{details.showUpRates}%</Typography>
                            </Box>
                        </Box>
                    </Box>
                    <Box display={'flex'}>
                        <Box flex={1} sx={{ backgroundColor: "#FFFFFF", ...boxStyle,  backgroundImage: "url(/images/okay.png)", backgroundRepeat: "no-repeat", backgroundPosition: "right", backgroundPositionY: "-1px"  }}>
                            <Typography color={"#75767A"} variant='body2' sx={{ mb: 1 }}>No show</Typography>
                            <Box display={"flex"}>
                                <Typography color={"#75767A"} variant='body1'>{details.noShow}</Typography>
                                <Typography color={"#B4342A"} style={percentageStyle}>{details.noShowUpRates}%</Typography>
                            </Box>
                        </Box>
                    </Box>
                </>
                : <CircularProgress />}
        </>
    )
}
