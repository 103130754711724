import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, TextField, Typography } from "@mui/material"
import { useState } from "react"
import { setCandidateEventAssessmentComment } from "../../lib/page";
import getTime from "../../utils/getTime";

function AddComment({ candidateId, eventId,isDialogOpen, handleCloseDialog, username, isComment }) {
    const [comment, setComment] = useState('')
    const formErrorObj = { error: false, msg: '' };
    const [formError, setFormError] = useState({
        comment: formErrorObj,
    })

    const validateComment = () => {
        let formValid = true
        let commentErr = formErrorObj

        if (comment.length === 0 || comment === undefined) {
            commentErr = { error: true, msg: 'Require' }
            formValid = false
        }
        setFormError({
            comment: commentErr,
        })
        return formValid
    }

    const handleChange = (e) => {
        setComment(e.target.value)
    }

    const handleClose = () => {
        setComment('')
        handleCloseDialog(false);
    }

    const handleSubmit = async(e) => {
        e.preventDefault()
        if(candidateId === null || candidateId === undefined){
            return
        }
        const isFormValid = validateComment()
        if(isFormValid === true){
            const commentForm = {
                id: new Date().getTime(),
                comment: comment,
                module: "General",
                overall_score: "",
                created_by: username,
                created_at: getTime()
            }

            await setCandidateEventAssessmentComment(candidateId, eventId, commentForm)
            
            isComment()
            handleCloseDialog(false);
        }
    }
    return (
        <div>
            <Dialog
                open={isDialogOpen}
                onClose={handleClose}
                fullWidth={true}
                maxWidth={"md"}
            >
                <DialogTitle sx={{ p: 2 }}><Typography variant="h6">ADD COMMENT</Typography></DialogTitle>
                <DialogContent>
                    <FormControl variant="standard" sx={{ width: "100%", p: 1 }}>
                        <Typography>Enter your comment</Typography>
                        <TextField required size="small" id="comment" name="comment" label="comment" onChange={handleChange} error={formError.comment.error} helperText={formError.comment.msg} />
                    </FormControl>
                </DialogContent>
                <DialogActions sx={{ m: 1 }}>
                    <Button onClick={handleClose}> Cancel </Button>
                    <Button autoFocus type="submit" onClick={handleSubmit} variant="contained"> Submit </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default AddComment