import React from "react";
import { Route, Routes } from "react-router-dom";
import CandidatePage from "./components/CandidatePage";
import EventListPage from "./components/EventPage";
import LoginPage from "./components/LoginPage";
import ConfirmAttendancePage from "./components/ConfirmAttendancePage";
import EventDetail from "./components/EventDetail";
import NotFoundPage from "./components/NotFoundPage";
import RegistrationPage from "./components/RegistrationPage";
import TestPage from "./components/TestPage";
import UploadPage from "./components/UploadPage";
import AssessmentPage from "./components/assessment/AssessmentPage";
import TestImport from "./components/TestImport";
import AuthLayout from "./components/AuthLayout";
import DashboardPage from "./components/dashboard/DashboardPage";
import EventDashboard from "./components/dashboard/EventDashboard";

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<LoginPage />}></Route>
        <Route element={<AuthLayout />}>
          <Route path="/dashboard" element={<DashboardPage />}></Route>
          <Route path="/dashboard/:eventID" element={<EventDashboard />}></Route>
          <Route path="/candidates" element={<CandidatePage />}></Route>
          <Route path="/uploadResult" element={<UploadPage />}></Route>
          <Route path="/events" element={<EventListPage />}></Route>
          <Route path="events/:eventID" element={<EventDetail />}></Route>
          <Route
            path="events/:eventID/assessment"
            element={<AssessmentPage />}
          ></Route>
        </Route>

        <Route path="/test" element={<TestPage />}></Route>
        <Route path="/test-import" element={<TestImport />}></Route>

        {/* public routes or page that does not requires auth */}
        <Route
          path="/registration/:eventID"
          element={<RegistrationPage />}
        ></Route>
        <Route
          path="/confirm-attendance/:token"
          element={<ConfirmAttendancePage />}
        ></Route>
        <Route path="*" element={<NotFoundPage />}></Route>
      </Routes>
    </>
  );
}

export default App;
