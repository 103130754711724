
import { Box, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { getEventEmailTemplateById } from "../../lib/db_event";

function EmailTemplateViewer( props ) {
  const { event } = props;
  const [emailTempalte, setEmailTemplate] = useState("");
  const [emailSubject, setEmailSubject] = useState("");

  const templateRef = useRef(null);

  useEffect(() => {
    const loadHtmlTemplate = async () => {
      try {
        if ( event ) {
          const template = await getEventEmailTemplateById(event.id);
        
          setEmailTemplate(template.email_template);
          setEmailSubject(template.subject ?? "");
        }
      } catch(error) {
        console.log(error);
      }
    }
    
    loadHtmlTemplate();
  }, [event]);


  useEffect(() => {
    if (emailTempalte && templateRef.current) {
      templateRef.current.innerHTML = emailTempalte;
    }
  }, [emailTempalte])

  return(
    <>
      <Box mb={3} mt={2} display="flex" alignItems="center">
        <Typography fontSize="16px" color="#000" mr={2}>Email Subject:</Typography>
        <Typography fontSize="16px" color="#000" fontWeight="medium">{emailSubject}</Typography>
      </Box>
      <Box sx={{ border: 1, borderColor: "#ebebeb", width: "580px", backgroundColor: "white", pt: "20px" }}>
        { event && (
          <div ref={templateRef} />
        )}
      </Box>
    </>
  );
}

export default EmailTemplateViewer