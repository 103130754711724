import { Box, Dialog, DialogContent, DialogTitle, IconButton, Typography } from "@mui/material"
import CloseIcon from '@mui/icons-material/Close';

function SuccessMsg({isDialogOpen = false, handleCloseDialog, title, message}){
    const handleClose = () => {
        handleCloseDialog(false)
    }
    return(
        <div>
            <Dialog
                open={isDialogOpen}
                onClose={handleClose}
                fullWidth={true}
                maxWidth={"sm"}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle>
                    <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
                        <Typography variant='h6'></Typography>
                        <IconButton id='close' name='close' onClick={handleClose}>
                            <CloseIcon id='close' name='close' sx={{
                                pointerEvents: 'none',
                                color: 'black'
                            }} />
                        </IconButton>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <Typography variant='h6'>{title}</Typography>
                    <Typography color="#75767A" sx={{mt: 1}}>{message}</Typography>
                </DialogContent>

            </Dialog>
        </div>
    )
}

export default SuccessMsg