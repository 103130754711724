import { Container, Typography, useTheme } from "@mui/material";

function Footer(){
    const theme = useTheme();
    return(
        <Container maxWidth="100%" sx={{ height: "56px",backgroundColor:"#FFFFFF", boxShadow:"0px -4px 4px rgba(0, 0, 0, 0.08)", position: "fixed", bottom: 0}}>
            <Typography color="#75767A" variant="body2" sx={{ml:`calc(${theme.spacing(7)} + 5px)`, paddingTop: "19px"}}> © This site is for AirAsia Group ICT internal use only </Typography>
        </Container>
    )
}

export default Footer