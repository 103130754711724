import { Pagination, PaginationItem } from "@mui/material";
import {
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid";

function CustomPagination() {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);
  
  return (
    <Pagination
      color="primary"
      page={page + 1}
      count={pageCount}
      // @ts-expect-error
      renderItem={(props2) => (
        <PaginationItem
          {...props2}
          disableRipple
          size="large"
          variant="string"
          shape="rounded"
          sx={{
            color: "#C5C9D0",
            ".MuiPaginationItem-icon": {
            color:"#4B4FA6",
              border: 1,
              boxSizing: "border-box",
              padding: "5px",
              fontSize: "30px",
            },
            "&.Mui-selected": {
                backgroundColor: "#FFFFFF",
                color: "#4B4FA6",
                borderColor: "transparent",
                fontWeight: "bold",
            },
          }}
        />
      )}
      onChange={(event, value) => apiRef.current.setPage(value - 1)}
    />
  );
}

export default CustomPagination;
