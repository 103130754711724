import { useNavigate } from 'react-router-dom'
import { Box, Button, Typography } from "@mui/material";
// import { app, auth, googleAuthProvider } from '../lib/firebase';
import { GoogleAuthProvider, getAuth, signInWithPopup } from "firebase/auth";
import React from 'react';
import axios from 'axios';
import { addLoginActivity } from '../lib/db_candidate';
import { checkIfUserIsActive } from '../lib/db_user';

function LoginPage(){
    const navigate = useNavigate()

    const getIpData =  async () => {
        const response = await axios.get('https://ipapi.co/json/');

        let ipData = {};
        if ( response.data ) {
           ipData = response.data ?? {};
        }

        return ipData;
    }

    const googleSignin = async () => {
        const auth = getAuth();
        const provider = new GoogleAuthProvider();        

        signInWithPopup(auth, provider)
        .then( async (result) => {
            // This gives you a Google Access Token. You can use it to access the Google API.
            const credential = GoogleAuthProvider.credentialFromResult(result);
            const token = credential.accessToken;
            const googleUser = result.user;
            const idToken = await getAuth().currentUser?.getIdToken(false);

            const isAA = /@airasia.com\s*$/.test(result.user.email);

            const isActive = await checkIfUserIsActive(result.user.email);

            if (isAA) {

                if ( isActive ) {
                    try {
                        const ipData = await getIpData();
    
                        console.log(ipData);
    
                        const logData = {
                            email: googleUser?.email ?? "",
                            ip_info: ipData,
                        }
                        await addLoginActivity(logData);
                    } catch(error) {
                        console.error(error);
                    }
    
                    sessionStorage.setItem('access_token', token);
                    sessionStorage.setItem('id_token', idToken);
                    sessionStorage.setItem('user_name', googleUser.displayName);
                    sessionStorage.setItem('user_email', googleUser.email)
                    sessionStorage.setItem('user_url', googleUser.photoURL);
                    navigate("/events",{
                        state: {
                            name: googleUser.displayName,
                            email: googleUser.email,
                            displayPic: googleUser.photoURL
                        }
                    })
                } else {
                    await auth.signOut();
                    // TO DO: Replace with proper modal
                    alert("User not permitted to access Redhire.")
                }
            } 
            else{
                await auth.signOut();
                alert('Only @airasia.com is allowed');
            }
            
        }).catch((error) => {
            // Handle Errors here.
            const errorCode = error.code;
            const errorMessage = error.message;
            // The email of the user's account used.
            const email = error.customData.email;
            // The AuthCredential type that was used.
            const credential = GoogleAuthProvider.credentialFromError(error);
            // ...
            console.log(errorCode, errorMessage, email, credential);
            // TO DO: Replace with proper modal
            alert(`Login error: ${errorCode}: ${errorMessage}`);
        });
        
        
    }
    return (
        <div style={{backgroundImage: `url(/images/coverImage.png)`,backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', width:"100vw", height:"100vh"}}>
            <Box sx={{
                width: "578px",
                height: "295px",
                position: 'absolute', left: '50%', top: '50%',
                transform: 'translate(-50%, -50%)',
                boxSizing: "border-box",
                border: 1,
                borderColor: "white",
                background: "linear-gradient(128.03deg, rgba(255, 255, 255, 0.5) -1.21%, rgba(255, 255, 255, 0.05) 138.36%)",
                backdropFilter: "blur(15px)",
                borderRadius: "16px"
            }}>
                <Box sx={{
                    position: 'absolute', left: '50%', top: '50%',
                    transform: 'translate(-50%, -50%)',
                    textAlign: "center"
                }}>
                    <Typography variant='h4' color="#FFFFFF" sx={{mb: 3}}>Welcome</Typography>
                    <Button variant='text' name='loginMenu' onClick={googleSignin} sx={{backgroundColor:"white", pt: 2, pb:2, pl: 5, pr: 5}}>
                        <img src="/images/googleLogo.png" alt="Google Login" style={{height: 24, width: 24, marginRight: 7}}/><Typography variant='body1' color="black"><strong>Sign in with Google</strong></Typography>
                    </Button>
                </Box>
            </Box>
        </div>
    )
}

export default LoginPage