import { Box, Typography } from "@mui/material";
import * as React from "react";
import { useEffect, useState, useCallback } from "react";
import { getEventById } from "../../lib/db_event";

const TableDetail = ({ data, eventId }) => {
  const [assessmentDisplay, setAssessmentDisplay] = useState([]);

  const SetAssessment = useCallback(async() => {
    const assessment = {
      Measurement: {
        remaining: data.measurement.remaining,
        passed: data.measurement.passed,
      },
      "Document Check": {
        remaining: data.document_check.remaining,
        passed: data.document_check.passed,
      },
      Catwalk: { remaining: data.catwalk.remaining, passed: data.catwalk.passed },
      "English Examination": {
        remaining: data.english_exam.remaining,
        passed: data.english_exam.passed,
      },
      "Group Dynamic": {
        remaining: data.group_dynamic.remaining,
        passed: data.group_dynamic.passed,
      },
      "1-1 Interview": {
        remaining: data.one_to_one_interview.remaining,
        passed: data.one_to_one_interview.passed,
      },
    };
    const event = await getEventById(eventId)
    if(!event.event_assessment_module.includes("Measurements")){
      delete assessment['Measurement']
    } 
    if(!event.event_assessment_module.includes("Document Check")){
      delete assessment['Document Check']
    } 
    if(!event.event_assessment_module.includes("English Examinations")){
      delete assessment['English Examination']
    } 
    if(!event.event_assessment_module.includes("Group Dynamics")){
      delete assessment['Group Dynamic']
    } 
    if(!event.event_assessment_module.includes("Catwalk")){
      delete assessment['Catwalk']
    } 
    if(!event.event_assessment_module.includes("1:1 Interview")){
      delete assessment['1-1 Interview']
    } 

    const display = Object.entries(assessment).map(([key, value]) => (
      <Box
        sx={{
          flex: 1,
          border: "1px solid #4B4FA6",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-around",
          opacity: "0.7",
        }}

        key={key}
      >
        <span style={{ fontSize: "16px" }}>{key}</span>
        <Box display={"flex"} flexDirection={"row"} width={"100%"} p={-1} alignItems={"center"} justifyContent={"space-evenly"}>
            <Typography color={"#FFFFFF"} style={{ padding: '2px 8px',alignItems: 'center',width: '30%',backgroundColor: "#75767A",border:1, borderRadius: '50px' }}> {value.remaining}</Typography>
            <Typography color={"#FFFFFF"} style={{ padding: '2px 8px',alignItems: 'center',width: '30%',backgroundColor: "#067E41",border:1, borderRadius: '50px' }}> {value.passed}</Typography>
        </Box>
      </Box>
    ))
    setAssessmentDisplay(display)
  },[data.catwalk.remaining, data.catwalk.passed, data.document_check.remaining, data.document_check.passed, data.english_exam.remaining, data.english_exam.passed, data.group_dynamic.remaining, data.group_dynamic.passed, data.measurement.remaining, data.measurement.passed, data.one_to_one_interview.remaining, data.one_to_one_interview.passed, eventId]);
  useEffect(() => {
    SetAssessment();
  }, [SetAssessment]);
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "row",
        p: 3,
        pt: 0,
      }}
    >
      <Box
        height={"160px"}
        sx={{
          backgroundColor: "#4B4FA6",
          flex: 1,
          textAlign: "center",
          color: "#FFFFFF",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-evenly",
        }}
      >
        Total Confirmed <br /> Registration <br/> {" "}
        <Typography variant="h2">{data.total_registered}</Typography>
      </Box>
      <Box sx={{ flex: 5 }}>
        <Box
          height={"80px"}
          sx={{
            backgroundColor: "#E0A6B1",
            flex: 1,
            display: "flex",
            flexDirection: "row",
          }}
        >
          <Box
            sx={{
              flex: 1,
              border: "1px solid #D35A71",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-around",
              opacity: "0.7",
            }}
          >
            <span style={{ fontSize: "16px" }}>
              Total Pending Registration{" "}
            </span>
            <strong style={{ fontSize: "20px" }}>
              {data.total_pending_registration}
            </strong>
          </Box>
          <Box
            sx={{
              flex: 1,
              border: "1px solid #D35A71",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-around",
              opacity: "0.7",
            }}
          >
            <span style={{ fontSize: "16px" }}>Total Candidates</span>
            <strong style={{ fontSize: "20px" }}>{data.total_invited}</strong>
          </Box>
          <Box
            sx={{
              flex: 1,
              border: "1px solid #D35A71",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-around",
              opacity: "0.7",
            }}
          >
            <span style={{ fontSize: "13px" }}>Total Exception</span>
            <strong style={{ fontSize: "20px" }}>{data.total_exception}</strong>
          </Box>
          <Box
            sx={{
              flex: 1,
              border: "1px solid #D35A71",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-around",
              opacity: "0.7",
            }}
          >
            <span style={{ fontSize: "16px" }}>Total In-Progress</span>
            <strong style={{ fontSize: "20px" }}>
              {data.total_in_progress}
            </strong>
          </Box>
          <Box
            sx={{
              flex: 1,
              border: "1px solid #D35A71",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-around",
              opacity: "0.7",
            }}
          >
            <span style={{ fontSize: "16px" }}>Total Passed</span>
            <strong style={{ fontSize: "20px" }}>{data.total_pass}</strong>
          </Box>
          <Box
            sx={{
              flex: 1,
              border: "1px solid #D35A71",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-around",
              opacity: "0.7",
            }}
          >
            <span style={{ fontSize: "16px" }}>Total Failed</span>
            <strong style={{ fontSize: "20px" }}>{data.total_failed}</strong>
          </Box>
          <Box
            sx={{
              flex: 1,
              border: "1px solid #D35A71",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-around",
              opacity: "0.7",
            }}
          >
            <span style={{ fontSize: "16px" }}>Total KIV</span>
            <strong style={{ fontSize: "20px" }}>{data.total_kiv}</strong>
          </Box>
        </Box>
        <Box
          height={"80px"}
          sx={{
            backgroundColor: "#EDEDF6",
            flex: 1,
            display: "flex",
            flexDirection: "row",
          }}
        >
          {assessmentDisplay}
        </Box>
      </Box>
    </Box>
  );
};

export default TableDetail;
