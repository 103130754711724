// AuthProvider.js
import { getAuth, onAuthStateChanged, getIdTokenResult } from 'firebase/auth';
import React, { useEffect, useState } from 'react';
import {Dialog, DialogTitle, DialogContent,Typography,IconButton,Box} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';

const AuthContext = React.createContext();

/**
 * Auth state on change helper
 * @param {*} props
 * @returns 
 */
const AuthProvider = ( props ) => {
  const {children} = props;

  const navigate = useNavigate()

  const auth = getAuth();
  const [user, setUser] = useState(null);
  
  const [isTokenExpired, setIdTokenExpired] = useState(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {

      const idToken = await user?.getIdToken(false);

      sessionStorage.setItem('id_token', idToken);

      setUser(user);
    });

    // Clean up the subscription when the component unmounts
    return () => unsubscribe();
  }, [auth]);

  // Function to check if the ID token is expired
  const checkIdTokenValidity = async () => {
    try {
      const user = auth.currentUser;

      if (user) {
        const idTokenResult = await getIdTokenResult(user);

        const expirationTimestamp = new Date(idTokenResult.expirationTime).getTime()

        // Check if the ID token is expired
        const isExpired = expirationTimestamp < Date.now();

        // Update the state accordingly
        setIdTokenExpired(isExpired);
      }
    } catch (error) {
      console.error("Error checking ID token validity:", error);
    }
  };

  const handleClose = async () => {
    auth.signOut().then((_) => {
      sessionStorage.clear()
      navigate('/login');
    }).catch((_) => {
      console.error(_)
    });
  }

  useEffect(() => {

    // Set a new timeout
    const intervalId = setInterval(() => {
      checkIdTokenValidity();
    }, 2000);


    return () => {
      if( intervalId ) {
        clearInterval(intervalId)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <AuthContext.Provider value={{ user }}>
      {children}
      {/* display expired access token modal */}
      <Dialog
          open={isTokenExpired}
          onClose={handleClose}
          fullWidth={true}
          maxWidth={"sm"}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
      >
          <DialogTitle>
              <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
                  <Typography variant='h6'></Typography>
                  <IconButton id='close' name='close' onClick={handleClose}>
                      <CloseIcon id='close' name='close' sx={{
                          pointerEvents: 'none',
                          color: 'black'
                      }} />
                  </IconButton>
              </Box>
          </DialogTitle>
          <DialogContent>
              <Typography variant='h6'>Session Expired</Typography>
              <Typography color="#75767A" sx={{mt: 1}}>Please login again.</Typography>
          </DialogContent>
          
      </Dialog>
    </AuthContext.Provider>
  );
};

export { AuthProvider, AuthContext };