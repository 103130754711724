import React, { useEffect, useState } from "react";
import { getBreakdownData } from "../../../lib/db_event";
import { Box, CircularProgress, Stack, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

const numberStyle = {
  padding: "0px 8px",
  alignItems: "center",
  boxShadow: "0px 1px 4px 0px #00000029",
  width: "100%",
  backgroundColor: "#EDEDF6",
  border: 1,
  borderRadius: "8px",
};

export default function GenderAge({ eventId, tab }) {
  const [rows, setRows] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    const getCandidates = async () => {
      setIsLoading(true);
      const res = await getBreakdownData(eventId);
      if (tab === "Gender") {
        res.gender_breakdown.forEach((item, index) => {
          item.id = index;
          item.identity = item.gender_breakdown;
        });
        setRows(res.gender_breakdown);
      } else if (tab === "Age") {
        res.age_grouping.forEach((item, index) => {
          item.id = index;
          item.identity = item.range;
        });
        setRows(res.age_grouping);
      }
      setIsLoading(false);
    };
    getCandidates();
  }, [eventId, tab]);

  const renderCellNumber = (props) => {
    let number = props.value;
    if (props.value === 0) number = "-";
    return (
      <>
        <Typography textAlign={"center"} color={"#3A3D7D"} style={numberStyle}>
          {number}
        </Typography>
      </>
    );
  };

  const renderCellIdentity = (props) => {
    return (
      <>
        <Typography color={"#3A3D7D"} variant="body2">
          <strong>{props.value}</strong>
        </Typography>
      </>
    );
  };

  const columnsCandidate = [
    {
      field: "identity",
      headerClassName: "theme--header",
      headerName: "Gender Breakdown",
      minWidth: 100,
      flex: 1,
      filterable: false,
      headerAlign: "center",
      renderCell: renderCellIdentity,
    },
    {
      field: "assessed",
      headerClassName: "theme--header",
      headerName: "Total Assessed",
      minWidth: 100,
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "passed",
      flex: 1,
      headerClassName: "theme--header",
      headerName: "Passed",
      minWidth: 100,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "failed",
      headerClassName: "theme--header",
      headerName: "Failed",
      minWidth: 100,
      filterable: false,
      flex: 1,
      headerAlign: "center",
      disableClickEventBubbling: true,
      align: "center",
    },
    {
      field: "kiv",
      headerClassName: "theme--header",
      headerName: "KIV",
      minWidth: 100,
      filterable: false,
      flex: 1,
      headerAlign: "center",
      disableClickEventBubbling: true,
      align: "center",
      renderCell: renderCellNumber,
    },
    {
      field: "blacklisted",
      headerClassName: "theme--header",
      headerName: "Blacklisted",
      minWidth: 100,
      filterable: false,
      flex: 1,
      headerAlign: "center",
      disableClickEventBubbling: true,
      align: "center",
      renderCell: renderCellNumber,
    },
  ];
  return (
    <>
      <Box
        sx={{
          height: "auto",
          width: "100%",
          "& .theme--header": {
            backgroundColor: "white",
            marginBottom: "8px",
            marginTop: "8px"
          },
          "& .theme--rows": {
            backgroundColor: "white",
          },
        }}
      >
        <div style={{ flexGrow: 1 }}>
          <DataGrid
            sx={{
              "& .MuiDataGrid-columnHeader": {
                // Forced to use important since overriding inline styles
                height: "unset !important",
              },
              "& .MuiDataGrid-columnHeaders": {
                // Forced to use important since overriding inline styles
                maxHeight: "168px !important",
              },
              ".MuiDataGrid-columnSeparator": {
                display: "none",
              },
              "& .MuiDataGrid-cell": {
                backgroundColor: "white",
              },
              border: 0,
            }}
            rows={rows}
            columns={columnsCandidate}
            autoHeight={true}
            componentsProps={{
              panel: {
                placement: "bottom-end",
              },
            }}
            getRowClassName={(params) => {
              return typeof params.row !== "object" ? "" : "theme--rows";
            }}
            initialState={{
              pagination: { paginationModel: { pageSize: 10 } },
            }}
            pageSizeOptions={[10, 25, 50]}
            disableColumnMenu
            hideFooter
            slots={{
              noRowsOverlay: () => (
                <>
                  <Stack
                    height="100%"
                    alignItems="center"
                    justifyContent="center"
                  >
                    {isLoading ? (
                      <>
                        <p>Getting Data</p>
                        <CircularProgress />
                      </>
                    ) : (
                      'No Data'
                    )}
                  </Stack>
                </>
              )
            }}
          />
        </div>
      </Box>
    </>
  );
}
