import axios from 'axios'
import Papa from "papaparse";
import { db } from "../lib/firebase";
import { 
    doc,
    getDoc,
    setDoc,
    updateDoc,
    collection,
    getDocs,
    query,
    where
} from "firebase/firestore";

function TestImport(){
    const today = new Date()
    const yesterday = new Date(today)
    yesterday.setDate(yesterday.getDate() - 1)
    const targetDate = getIsoDate(yesterday)
    isImportTokenExist(targetDate).then((isExist) => {
        if(!isExist){
            insertImportToken(targetDate).then(() => {
                console.log("Token inserted")
            })
        }
    })
    .finally(() => {
        processImport()
    })
}

function getIsoDate(date){
    const year = date.getFullYear().toString()
    const month = (date.getMonth()+1).toString().padStart(2, "0")
    const day = (date.getDate()).toString().padStart(2, "0")
    return `${year}-${month}-${day}`
}

function importFromWorkdayByDate(targetDate){
    const statistic = {
        candidate: 0,
        work_experience: 0,
        language: 0
    }
    const apiUrlCandidate = `https://wd3-services1.myworkday.com/ccx/service/customreport2/airasia/thammengchoong%40airasia.com/CCH_Candidate_API?Start_Date=${targetDate}&End_Date=${targetDate}&format=csv`
    const apiUrlLanguage = `https://wd3-services1.myworkday.com/ccx/service/customreport2/airasia/thammengchoong%40airasia.com/CCH_Candidate_Languages?Start_Date=${targetDate}&End_Date=${targetDate}&format=csv`
    const apiUrlWorkExp = `https://wd3-services1.myworkday.com/ccx/service/customreport2/airasia/thammengchoong%40airasia.com/CCH_Candidate_Work_Experience?Start_Date=${targetDate}&End_Date=${targetDate}&format=csv`
    const username = "ISU_CCH_Recruiting"
    const password = "CchIntUs3r!"
    const headerAuthorization = `Basic ${btoa(username + ':' + password)}`
    const config = {
        headers: {
            'Authorization': headerAuthorization,
            'content-type': 'text/csv;charset=UTF-8',
            'Access-Control-Allow-Origin' : '*',
            'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        },
    }
    let endpoints = [
        apiUrlCandidate,
        apiUrlLanguage,
        apiUrlWorkExp
    ];
    const candidateInfo = {}
    axios.all(endpoints.map((endpoint) => axios.get(endpoint, config))).then(
        axios.spread(({data: candidateCsv}, {data:languageCsv}, {data:workExpCsv}) => {
            const parsedCandidateCsv = Papa.parse(candidateCsv)
            const candidateLines = parsedCandidateCsv.data
            if(candidateLines[candidateLines.length - 1].length === 1){
                delete candidateLines[candidateLines.length - 1]
            }
            const candidateColumnName = candidateLines[0]
            delete candidateLines[0]
            candidateLines.forEach((line) => {
                let candidate = {}
                line.forEach((value, i) => {
                    candidate[candidateColumnName[i]] = value
                })
                if(candidate.Candidate_ID !== ""){
                    candidateInfo[candidate.Candidate_ID + "_" + candidate.Job_Req_ID] = {...candidate, work_experiences: [], languages: []}
                }
                statistic.candidate++
            })

            const parsedLanguageCsv = Papa.parse(languageCsv)
            const languageLines = parsedLanguageCsv.data
            if(languageLines[languageLines.length - 1].length === 1){
                delete languageLines[languageLines.length - 1]
            }
            const languageColumnName = languageLines[0]
            delete languageLines[0]
            languageLines.forEach((line) => {
                let language = {}
                line.forEach((value, i) => {
                    language[languageColumnName[i]] = value
                })
                if(language.Candidate_ID !== ""){                    
                    candidateInfo[language.Candidate_ID + "_" + language.Job_Req_ID].languages.push({language: language.Language, language_ability_proficiency: language.Language_Ability_Proficiency})
                }
                statistic.language++
            })

            const parsedWorkExpCsv = Papa.parse(workExpCsv)
            const workExpLines = parsedWorkExpCsv.data
            if(workExpLines[workExpLines.length - 1].length === 1){
                delete workExpLines[workExpLines.length - 1]
            }
            const workExpColumnName = workExpLines[0]
            delete workExpLines[0]
            workExpLines.forEach((line) => {
                let workExp = {}
                line.forEach((value, i) => {
                    workExp[workExpColumnName[i]] = value
                })
                
                if(workExp.Candidate_ID !== ""){
                    candidateInfo[workExp.Candidate_ID + "_" + workExp.Job_Req_ID].work_experiences.push({...workExp})
                }
                statistic.work_experience++
            })

            // Insert into firestore
            insertIntoFirestore(candidateInfo)
        })
    )
}

function insertIntoFirestore(candidateInfo){
    const candidateDocRef = collection(db, "candidates");
    const allCandidate = {}
    getDocs(candidateDocRef).then((docs) => {
        docs.forEach((doc) => {
            if(doc.exists){
                allCandidate[doc.id] = {...doc.data()}
            }
        })
        updateCandidateJobApplication(candidateInfo, allCandidate)
    })
}

function updateCandidateJobApplication(candidateInfo, allCandidate){
    const jrNumberDetails = {}
    for(const id in candidateInfo){
        jrNumberDetails[candidateInfo[id].Job_Req_ID] = {
            jr_number: candidateInfo[id].Job_Req_ID,
            title: candidateInfo[id].Job_Posting_Title,
            company: candidateInfo[id].Company,
            location: candidateInfo[id].Location,
            aoc_country: candidateInfo[id].AOC_Country
        }
        if(!(candidateInfo[id].Candidate_ID in allCandidate)){ // National_ID
            // Candidate not found
            // Insert candidate
            const newCandidate = {}
            // Add default field
            newCandidate['address'] = []
            newCandidate['availability_start_date'] = ''
            newCandidate['is_blacklist'] = false;
            newCandidate['is_kiv'] = false;
            newCandidate['cooling_period_expiry'] = ''
            newCandidate['assessment_status'] = 'Pending'
            newCandidate['education'] = []
            newCandidate['source'] = 'Online'
            newCandidate['vaccination'] = false
            newCandidate['registration_status'] = ''
            newCandidate['candidate_status'] = 'Review'
            newCandidate['city'] = candidateInfo[id].City
            newCandidate['country'] = candidateInfo[id].Country
            newCandidate['application_date'] = candidateInfo[id].Candidate_Creation_Date
            newCandidate['email'] = candidateInfo[id].Email
            newCandidate['full_name'] = candidateInfo[id].First_Name + " " + candidateInfo[id].Last_Name
            newCandidate['first_name'] = candidateInfo[id].First_Name
            newCandidate['last_name'] = candidateInfo[id].Last_Name
            newCandidate['gender'] = candidateInfo[id].Gender
            newCandidate['jr_number'] = [candidateInfo[id].Job_Req_ID]
            newCandidate['national_id'] = candidateInfo[id].Candidate_ID    // National_ID
            newCandidate['nationality'] = candidateInfo[id].Nationality
            newCandidate['passport'] = candidateInfo[id].Candidate_ID   // National_ID
            newCandidate['dob'] = candidateInfo[id].Date_of_Birth

            // Insert candidate doc
            setDoc(doc(db, "candidates", candidateInfo[id].Candidate_ID), newCandidate)

            // add newCandidate to allCandidate to avoid duplicate processing.
            allCandidate[candidateInfo[id].Candidate_ID] = {...newCandidate}
        }
        else{
            // Update jr_number
            if(!allCandidate[candidateInfo[id].Candidate_ID].jr_number.includes(candidateInfo[id].Job_Req_ID)){ // National_ID
                //console.log("Add missing JR: " + candidateInfo[id].Job_Req_ID + " to candidate_id: " + candidateInfo[id].Candidate_ID)
                const jr_number = allCandidate[candidateInfo[id].Candidate_ID].jr_number // National_ID
                jr_number.push(candidateInfo[id].Job_Req_ID)
                updateDoc(doc(db, "candidates", candidateInfo[id].Candidate_ID), {jr_number: jr_number}) // National_ID
            }
        }

        // To be removed
        candidateInfo[id].National_ID = candidateInfo[id].Candidate_ID
        candidateInfo[id].Passport = candidateInfo[id].Candidate_ID
        // To be removed

        // Insert job_application to candidate
        setDoc(doc(db, "candidates", candidateInfo[id].Candidate_ID, "job_application", candidateInfo[id].Job_Req_ID), {...candidateInfo[id]})
    }
    //console.log(jrNumberDetails)
    for(const jrNumber in jrNumberDetails){
        setDoc(doc(db, "event_jr_details", jrNumber), jrNumberDetails[jrNumber])
    }
    //console.log(candidateInfo)
}

const isImportTokenExist = async (targetDate) => {
    const importToken = await getDoc(doc(db, "token", "import_from_workday", "trigger", targetDate))
    const isExist = importToken.exists()
    return isExist
}

const insertImportToken = async (targetDate) => {
    const data = {
        target_date: targetDate,
        has_processed: false
    }
    await setDoc(doc(db, "token", "import_from_workday", "trigger", targetDate), data)
}

function processImport(){
    const q = query(collection(db, "token", "import_from_workday", "trigger"), where("has_processed", "==", false))
    getDocs(q).then((importTokens) => {
        importTokens.forEach((token) => {
            if(token.exists){
                //console.log(doc.data())
                const targetDate = token.data().target_date
                console.log("Import data for date: " + targetDate)
                importFromWorkdayByDate(targetDate)
                updateDoc(doc(db, "token", "import_from_workday", "trigger", targetDate), {has_processed: true})
                console.log("Mark has proceesed for date: " + targetDate)
            }
        })
    })
}

export default TestImport