import React, { useEffect, useRef, useState } from 'react';
import Papa from "papaparse"
import { useLocation, useNavigate } from 'react-router-dom'
import { Autocomplete, Box, Button, Checkbox, Container, FormControl, IconButton, InputAdornment, Link, MenuItem, TextField, Typography, useTheme } from '@mui/material';
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import FileUploadIcon from '@mui/icons-material/FileUpload';
import MenuTab from './menu/MenuTab';
import { getAllEvent } from '../lib/db_event';
import { uploadAssessmentResultEnglishExam, uploadAssessmentResultGroupDynamic } from '../lib/page';
import Footer from './menu/Footer';
import SuccessMsg from './SuccessMsg';
import { authenticateUser } from '../lib/userAuthenticate';

function UploadPage() {
    const navigate = useNavigate()
    const location = useLocation()
    const [user, setUser] = useState({
        username: "",
        url: ""
    })
    const [event, setEvent] = useState([])
    const [selectedEvent, setSelectedEvent] = useState("")
    const [assessment, setAssessment] = useState("")
    const [file, setFile] = useState(null)
    const [parseFile, setParseFile] = useState([])
    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;
    const [uploadPopupData, setUploadPopupData] = useState({
        hasError: false,
        message: ""
    })
    const [isShowPopup, setIsShowPopup] = useState(false)

    useEffect(() => {
        authenticateUser(setUser, location, navigate)
    }, [location, navigate])

    useEffect(() => {
        const getEvent = async () => {
            const res = await getAllEvent()
            setEvent(res)
        }
        getEvent()
    }, [])

    const handleChange = (e, val) => {
        if (val === null) {
            setSelectedEvent("")
        } else {
            setSelectedEvent(val.id)
        }
    }
    const handleAssessment = (e) => {
        setAssessment(e.target.value)
    }
    const onChangeInput = async (e) => {
        if (assessment === "English Examination" || assessment === "Group Dynamic") {
            setFile(e.target.files[0])
            Papa.parse(e.target.files[0], {
                complete: async function (results) {
                    let str = results.data.map(a => a.join(",")).join("\n")
                    setParseFile(str)
                },
            })
        } else {
            alert("Please select type of assessment")
        }
    }
    const handleSubmit = async (e) => {
        e.preventDefault()
        if (selectedEvent.length === 0) {
            alert("Please select event")
            return
        }
        if (parseFile.length === 0) {
            alert("Error uploading file")
            return
        }

        if (assessment === "English Examination") {
            const uploadResult = await uploadAssessmentResultEnglishExam(selectedEvent, parseFile)
            setUploadPopupData(uploadResult)
        } else if (assessment === "Group Dynamic") {
            const uploadResult =await uploadAssessmentResultGroupDynamic(selectedEvent, parseFile)
            setUploadPopupData(uploadResult)
        } else {
            setUploadPopupData({
                hasError: true,
                message: "Please select assessment"
            })
        }
        setIsShowPopup(true)
    }

    const handleClosePopup = () => {
        setIsShowPopup(false)
        window.location.reload()
    }

    const theme = useTheme();
    const uploadInputRef = useRef(null);
    return (
        <div style={{ minHeight: "100vh" }}>
            <MenuTab username={user.username} url={user.url} />
            <Container disableGutters maxWidth="100%" component="main" sx={{ pl: `calc(${theme.spacing(7)} + 5px)`, marginBottom: "56px" }}>
                <Box p={2}>
                    <Box marginBottom="20px">
                        <Typography variant="h5" color="inherit" noWrap>Upload Assessment Result</Typography>
                    </Box>
                    <Box
                        sx={{
                            p: 3,
                            boxShadow: "0px 1px 4px 0px #00000029",
                        }}
                    >
                        <Box width="30%">
                            <FormControl variant='standard' sx={{ width: "100%" }}>
                                <Autocomplete
                                    id='event'
                                    options={event}
                                    getOptionLabel={(option) => option.event_code}
                                    renderOption={(props, option, { selected }) => (
                                        <li {...props}>
                                            <Checkbox
                                                icon={icon}
                                                checkedIcon={checkedIcon}
                                                style={{ marginLeft: -10 }}
                                                checked={selected}
                                            />
                                            {option.event_code}
                                        </li>
                                    )}
                                    onChange={handleChange}
                                    renderInput={(params) => (
                                        <TextField {...params} label="Event ID" placeholder="e.g A01" />
                                    )}
                                />
                            </FormControl>
                            <FormControl sx={{ width: "100%", mt: 1 }}>
                                <TextField
                                    select
                                    label="Assessment"
                                    name='Assessment'
                                    value={assessment}
                                    onChange={handleAssessment}
                                >
                                    <MenuItem value={"English Examination"}>English Examination</MenuItem>
                                    <MenuItem value={"Group Dynamic"}>Group Dynamic</MenuItem>
                                </TextField>
                            </FormControl>
                            <FormControl sx={{ width: "100%", mt: 1 }}>
                                <TextField
                                    name='Assessment'
                                    disabled
                                    value={!file ? "Upload CSV" : file.name}
                                    InputProps={{
                                        endAdornment:
                                            <InputAdornment position='end'>
                                                <input
                                                    ref={uploadInputRef}
                                                    type="file"
                                                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" //.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel
                                                    style={{ display: "none" }}
                                                    onChange={onChangeInput}
                                                />
                                                <label htmlFor="button-file">
                                                    <IconButton onClick={(() => uploadInputRef.current && uploadInputRef.current.click())}>
                                                        <FileUploadIcon />
                                                        <input type='file' hidden />
                                                    </IconButton>
                                                </label>
                                            </InputAdornment>
                                    }}
                                />
                            </FormControl>
                            <Button size='large' sx={{ mt: 1, mb: 3 }} variant='contained' onClick={handleSubmit}>
                                Upload
                            </Button>
                            <Typography variant='body2' color="#9D9D9D">Please download templates here:</Typography>
                            <ul>
                                <li><Link href="https://firebasestorage.googleapis.com/v0/b/airasia-incredible-stg.appspot.com/o/template%2FEnglishExam_Template.csv?alt=media" target='_blank' underline="hover" sx={{ color: "#2765BD" }}>English Exam</Link></li>
                                <li><Link href="https://firebasestorage.googleapis.com/v0/b/airasia-incredible-stg.appspot.com/o/template%2FAK_GroupDynamics_Template.csv?alt=media" target='_blank' underline="hover" sx={{ color: "#2765BD" }}>Group Dynamic</Link></li>
                            </ul>
                        </Box>
                    </Box>
                </Box>
                <SuccessMsg isDialogOpen={isShowPopup} handleCloseDialog={handleClosePopup} title={uploadPopupData.hasError ? "Error" : "Success"} message={uploadPopupData.message.length > 0 ? uploadPopupData.message : "Upload successful"}/>
            </Container>
            <Footer />
        </div>
    )
}

export default UploadPage