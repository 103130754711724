import { getAuth, onAuthStateChanged } from "firebase/auth"

const auth = getAuth();

/**
 * Authenticate on page levels
 * @param { React.Dispatch<React.SetStateAction<{username: string;url: string;}>>} setter
 * @param {Location} location
 * @param {NavigateFunction} navigate
 * @return {void}
 */
export const authenticateUser = (setter, location, navigate) => {
    onAuthStateChanged(auth, async (user) => {
        if (user) {
            // User is signed in, see docs for a list of available properties
            // https://firebase.google.com/docs/reference/js/auth.user
            setter({
                username: sessionStorage.getItem("user_name"),
                url: sessionStorage.getItem("user_url")
            })
            if (location.state) {
                setter({
                    username: location.state.name,
                    url: location.state.displayPic
                })
            }
            
            const idToken = await user?.getIdToken(false);

            sessionStorage.setItem('id_token', idToken);

        } else {
            sessionStorage.clear()
            navigate("/")
        }
    })
}