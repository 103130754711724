import { Checkbox, FormControlLabel, FormGroup, Typography } from "@mui/material";

function DocumentCheckbox({handleChange, collection, disabled = false}){
    const row = []
    for (var i in collection) {
        if(collection.hasOwnProperty(i)){
            row.push(
                <FormControlLabel
                    key={i}
                    label={<Typography color={"#75767A"}>{i}</Typography>}
                    control={
                        <Checkbox
                            disabled={disabled}
                            key={i}
                            checked={collection[i]}
                            onChange={handleChange}
                            name={i}
                            style={{display: 'flex', flexDirection: 'row',justifyContent: "space-around"}}
                        />
                    }
                    sx={{minWidth: 500}}
                />
            );
        }
    }
    return(
        <FormGroup>
            {row}
        </FormGroup>
    )
}

export default DocumentCheckbox
