import { useEffect, useState } from "react";
import { Box, Button, FormControl, FormControlLabel, MenuItem, Radio, RadioGroup, Select, TextField, Typography } from "@mui/material"
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { getCandidateAssessmentResultOneToOneInterview, setCandidateAssessmentResultOneToOneInterview } from "../../lib/db_candidate";
import { setCandidateEventAssessmentComment } from "../../lib/page";
import getTime from "../../utils/getTime";
import ClearConfirmation from "./ClearConfirmation";
import { AssessmentButtonAction, AssessmentLayout } from "../layout/AssessmentLayout";
import SuccessMsg from "../SuccessMsg";

function OneInterview({ candidateId, eventId, username, isComment, isCompleted = false }) {
    const [isCancelDialogOpen, setIsCancelDialogOpen] = useState(false)
    const [overallScore,  setOverallScore] = useState({
        status: '',
        comment: ''
    })
    const [commentError, setCommentError] = useState({
        msg: "",
        bool: false
    })
    const [overallError, setOverallError] = useState({
        msg: "",
        bool: false
    })
    const [ratingValue, setRatingValue] = useState(3)
    const [communication, setCommunication] = useState('')
    const [performance, setPerformance] = useState('')
    const [formError, setFormError] = useState(false)
    const [isShowPopup, setIsShowPopup] = useState(false)
    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;

    const resetState = () => {
        setRatingValue(3)
        setCommunication('')
        setPerformance('')
        setOverallScore({
            status: '',
            comment: ''
        })
    }

    useEffect(()=>{
        async function getResult(){
            try {
                const res = await getCandidateAssessmentResultOneToOneInterview(candidateId, eventId)
                if(res !== undefined){
                    setRatingValue(res.characteristic)
                    setCommunication(res.communication)
                    setPerformance(res.performance)
                    setOverallScore(res.overall_score)
                } else {
                    resetState()
                }
            } catch (error) {
                alert('Error getting candidate result 1-1')
            }
        }
        if (candidateId !== undefined) {
            getResult();
        } else {
            resetState()
        }
    },[candidateId, eventId])

    const handleChange = (e) => {
        if(e.target.name === "Communication"){
            setCommunication(e.target.value)
        } else if(e.target.name === "Performance"){
            setPerformance(e.target.value)
        }
    }
    const handleScore = (e) => {
        setOverallScore({
            status: e.target.value,
            comment: overallScore.comment,
        })
        if(overallScore.status !== 0){
            setOverallError({
                bool: false,
                msg: ""
            })
        }
    }

    const handleComment = (event) => {
        setOverallScore({
            status: overallScore.status,
            comment: event.target.value
        })

        if (event.target.value.length !== 0) {
            setCommentError({
                msg: "",
                bool: false
            })
        } 
    }

    const handleClickClear = () => {
        setIsCancelDialogOpen(!isCancelDialogOpen)
    }
    const handleClear = () => {
        resetState()
    }
    const handleSubmit = async (e) => {
        e.preventDefault()
        if (candidateId === undefined) {
            setFormError(true)
            setIsShowPopup(true)
            return
        }
        if(overallScore.status.length === 0) {
            setOverallError({
                bool: true,
                msg: "Overall Score cannot be empty"
            })
            setFormError(true)
            setIsShowPopup(true)
            return
        }
        if(overallScore.status === "Failed" && overallScore.comment.length === 0 ){
            setCommentError({
                bool: true,
                msg: "Missing Remarks"
            })
            setFormError(true)
            setIsShowPopup(true)
            return
        } else {
            const form = {
                characteristic: ratingValue,
                communication,
                performance,
                overall_score: overallScore
            }
            const commentForm = {
                id: new Date().getTime(),
                comment: overallScore.comment,
                module: "1-1 Interview",
                overall_score: overallScore.status,
                created_by: username,
                created_at: getTime()
            }
            await setCandidateAssessmentResultOneToOneInterview(candidateId, eventId, form)
            await setCandidateEventAssessmentComment(candidateId, eventId, commentForm)
            isComment()
            setFormError(false)
            setIsShowPopup(true)
        }
    }
    return (
        <>
            <AssessmentLayout title={"1-1 Interview"}>
                <Box
                    sx={{
                        mt: 2,
                        mb: 2,
                        boxShadow: "0px 1px 4px 0px #00000029",
                        width: "70%",
                        p: 3,
                    }}
                >
                    <Typography variant="h6">The question related to the candidate characteristics/ personality </Typography>
                    <Typography color="#75767A" variant="body1">(E.g. Pleasant Personality, Poised & Confidence, Mature & Responsible, Body Language & Passionate about the role & organization) </Typography>
                    <Box
                        sx={{
                            mt: 2,
                            mb: 2,
                            boxShadow: "0px 1px 4px 0px #00000029",
                            width: "70%",
                            p: 3,
                            borderRadius: '24px',
                            display: "flex",
                            flex: "row",
                            justifyContent: "space-evenly"
                        }}
                    >
                        <Box>
                            <Button disabled={isCompleted} disableRipple onClick={(() => setRatingValue(1))} size="rating" sx={{ backgroundColor: ratingValue === 1 ? "#2765BD" : "none", color: ratingValue === 1 ? "white" : "black" }}>1</Button>
                        </Box>
                        <Box>
                            <Button disabled={isCompleted} disableRipple onClick={(() => setRatingValue(2))} size="rating" sx={{ backgroundColor: ratingValue === 2 ? "#2765BD" : "none", color: ratingValue === 2 ? "white" : "black" }}>2</Button>
                        </Box>
                        <Box>
                            <Button disabled={isCompleted} disableRipple onClick={(() => setRatingValue(3))} size="rating" sx={{ backgroundColor: ratingValue === 3 ? "#2765BD" : "none", color: ratingValue === 3 ? "white" : "black" }}>3</Button>
                        </Box>
                        <Box>
                            <Button disabled={isCompleted} disableRipple onClick={(() => setRatingValue(4))} size="rating" sx={{ backgroundColor: ratingValue === 4 ? "#2765BD" : "none", color: ratingValue === 4 ? "white" : "black" }}>4</Button>
                        </Box>
                        <Box>
                            <Button disabled={isCompleted} disableRipple onClick={(() => setRatingValue(5))} size="rating" sx={{ backgroundColor: ratingValue === 5 ? "#2765BD" : "none", color: ratingValue === 5 ? "white" : "black" }}>5</Button>
                        </Box>
                    </Box>
                    <Box sx={{ width: "70%", display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                        <Box display="flex" alignItems="center"><ThumbDownIcon /><Typography sx={{ pl: 1 }}>Dissatisfied</Typography></Box>
                        <Box display="flex" alignItems="center"><Typography sx={{ pr: 1 }}>Satisfied</Typography> <ThumbUpIcon /></Box>
                    </Box>
                </Box>
                <Box
                    sx={{
                        mt: 2,
                        mb: 2,
                        boxShadow: "0px 1px 4px 0px #00000029",
                        width: "70%",
                        p: 3,
                    }}
                >
                    <FormControl>
                        <Typography variant="h6">Communication Skills</Typography>
                        <RadioGroup value={communication}>
                            <FormControlLabel
                                value={"Poor"}
                                control={
                                    <Radio
                                        disabled={isCompleted}
                                        icon={icon}
                                        checkedIcon={checkedIcon}
                                        name={"Communication"}
                                        onChange={handleChange}
                                    />
                                }
                                label="Poor"
                            />
                            <FormControlLabel
                                value={"Good"}
                                control={
                                    <Radio
                                        disabled={isCompleted}
                                        icon={icon}
                                        checkedIcon={checkedIcon}
                                        name={"Communication"}
                                        onChange={handleChange}
                                    />
                                }
                                label="Good"
                            />
                            <FormControlLabel
                                value={"Moderate Proficiency"}
                                control={
                                    <Radio
                                        disabled={isCompleted}
                                        icon={icon}
                                        checkedIcon={checkedIcon}
                                        name={"Communication"}
                                        onChange={handleChange}
                                    />
                                }
                                label="Moderate Proficiency"
                            />
                        </RadioGroup>
                    </FormControl>
                </Box>
                <Box
                    sx={{
                        mt: 2,
                        mb: 2,
                        boxShadow: "0px 1px 4px 0px #00000029",
                        width: "70%",
                        p: 3,
                    }}
                >
                    <Typography variant="h6">How was the overall performance of the candidate during the interview?</Typography>
                    <Typography variant="body1" color="#75767A">(i.e. good attitude, well prepared, has the potential to excel, willingness to learn, etc.) </Typography>
                    <Typography variant="body2" color="black">*For any unsuccessful candidate, please be precise on the rejection details</Typography>
                    <TextField disabled={isCompleted} multiline name="Performance" onChange={handleChange} value={performance} placeholder="Overall Performance" label="Label Placeholder" rows={4} sx={{width: "100%", mt: 2}}/>
                </Box>
                <Box
                    sx={{
                        mt: 2,
                        mb: 2,
                        boxShadow: "0px 1px 4px 0px #00000029",
                        p: 3,
                        width: "50%",
                    }}
                >
                    <Typography>Overall Score</Typography>
                    <FormControl sx={{minWidth: 250}}>
                        <Select
                            disabled={isCompleted}
                            name='score'
                            value={overallScore.status}
                            onChange={handleScore}
                            error={overallError.bool}
                        >
                            <MenuItem value={""}>-</MenuItem>
                            <MenuItem value={"Passed"}>Passed</MenuItem>
                            <MenuItem value={"KIV"}>KIV</MenuItem>
                            <MenuItem value={"Failed"}>Failed</MenuItem>
                        </Select>
                        {overallError.bool && <Typography color={"red"} variant="caption">{overallError.msg}</Typography>}
                    </FormControl>
                    <FormControl variant="standard" sx={{width: "100%", pt: 1}}>
                    {((overallScore.status === 'Failed' || overallScore.status === 'KIV') && !isCompleted) && <TextField
                        required
                        onChange={handleComment}
                        value={overallScore.comment}
                        size="small"
                        id="comment"
                        name="comment"
                        label="Remarks"
                        error={commentError.bool}
                        helperText={commentError.msg}
                    />}
                    </FormControl>
                </Box>
            </AssessmentLayout>
            { !isCompleted && (
                <AssessmentButtonAction saveAction={handleSubmit} secondButton={"Clear"} secondAction={handleClickClear} isLoading={false}/>
            )}
            <ClearConfirmation isDialogOpen={isCancelDialogOpen} handleCloseDialog={() => setIsCancelDialogOpen(false)} clear={handleClear}/>
            <SuccessMsg isDialogOpen={isShowPopup} handleCloseDialog={() => setIsShowPopup(false)} title={formError ? "Error" : "Success"} message={candidateId === undefined ? "Please select candidate" : formError ? "Form is incomplete" : "Success on saving candidate assessment"}/>
        </>
    )
}

export default OneInterview