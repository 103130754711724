import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import MenuTab from '../menu/MenuTab'
import { Box, Container, useTheme } from '@mui/material'
import DashboardSideLayout from './layout/DashboardSideLayout'
import DashboardMainLayout from './layout/DashboardMainLayout'
import Footer from '../menu/Footer'
import { convertReportsToCSV, getEventById } from '../../lib/db_event'
import EventDashboardSideInfo from './components/EventDashboardSideInfo'
import EventDashboardMainInformation from './components/EventDashboardMainInformation'
import { authenticateUser } from '../../lib/userAuthenticate'
import apiService from '../../lib/apiService'

export default function EventDashboard() {
    const { eventID } = useParams()
    const navigate = useNavigate()
    const location = useLocation()
    const [user, setUser] = useState({
        username: "",
        url: ""
    })
    const [event, setEvent] = useState({})
    const [isLoading, setIsLoading] = useState(false)
    const [reports,setReports] = useState([]);
    const [hasReports, setHasReports] = useState(false);

    const downloadReport = () => {
        convertReportsToCSV(reports);
    }

    useEffect(() => {
        authenticateUser(setUser, location, navigate)
    }, [location, navigate])

    useEffect(() => {
        const getEvent = async () => {
            setIsLoading(true)
            const response = await getEventById(eventID)
            setEvent(response)
            setIsLoading(false)
        }
        const getReports = async () => {
            apiService.getSyncReports({ eventId: eventID }).then((res) => {
                console.log(res);
                const data = res.data;
                const { has_reports = false, reports = []} = data;
                setHasReports(has_reports);
                setReports(reports);
            })
        }
        getEvent();
        getReports();
    }, [eventID])

    function isObjectEmpty(obj) {
        return Object.keys(obj).length === 0;
    }

    const theme = useTheme();
    return (
        <div style={{ minHeight: "100vh" }}>
            <MenuTab username={user.username} url={user.url} />
            
            <Container disableGutters maxWidth="100%" component="main" sx={{ pl: `calc(${theme.spacing(7)} + 5px)`, marginBottom: "56px" }}>
                <Box display={"flex"}>
                    <DashboardSideLayout>
                        <Box flex={1} flexGrow>
                            {
                                (isObjectEmpty(event) || isLoading) ? <></> : <EventDashboardSideInfo event={event} eventId={eventID} />
                            }
                        </Box>
                        {
                            (hasReports && !isLoading ) && (
                                <div style={{ fontSize: "12px", fontWeight: "500" }}>
                                    Reports: <span onClick={downloadReport} style={{ cursor: "pointer", textDecoration: "underline"}}>Candidates failed to move to Background check ({reports.length})</span>
                                </div>
                            )
                        }
                    </DashboardSideLayout>
                    <DashboardMainLayout>
                        <EventDashboardMainInformation eventId={eventID}/>
                    </DashboardMainLayout>
                </Box>
            </Container>
            <Footer />
        </div>
    )
}
