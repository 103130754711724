import { Box, Typography } from "@mui/material"

function DisplayComment({ comments }) {

    const display = []
    for (let index = 0; index < comments.length; index++) {
        display.push(
            <Box key={index} width="100%" sx={{ mt: 1, mb: 1, border: "1px solid #EBEBEB", p: 1, borderRadius: "8px" }}>
                <Box width="100%" display="flex" flexDirection="row">
                    <Box flex={2}>
                        <Typography variant='body2' color="#27272A" sx={{ opacity: 0.7 }}><strong>{comments[index].created_by}</strong></Typography>
                    </Box>
                    <Box flex={1}>
                        <Typography variant='body2' color="#27272A" sx={{ opacity: 0.7 }}><strong>{comments[index].module} - {comments[index].overall_score}</strong></Typography>
                    </Box>
                    <Box flex={1}>
                        <Typography variant='body2' color="#27272A" sx={{ opacity: 0.7 }}><strong>{comments[index].created_at}</strong></Typography>
                    </Box>
                </Box>
                <Box width="100%" marginTop={1}>
                    <Typography whiteSpace="normal" variant='body2' color="#4C4C50" sx={{ opacity: 0.7 }}>{comments[index].comment}</Typography>
                </Box>
            </Box>
        )

    }
    return (
        <>
            {display}
        </>
    )
}

export default DisplayComment