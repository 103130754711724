import { Box } from '@mui/material'
import React from 'react'
import useAppBarHeight from '../../../utils/appBarHeight'

export default function DashboardSideLayout(props) {
    const {children} = props
    const appBarHeight = useAppBarHeight()
  return (
    <Box p={3} display="flex" flexDirection="column" minHeight={`calc(100vh - ${appBarHeight}px - 56px)`} sx={{ backgroundColor: "#FFFFFF", flex: 1 }}>
        {children}
    </Box>
  )
}
