import { db } from "./firebase";
import { getAuth } from "firebase/auth";
import { doc, getDoc, serverTimestamp, setDoc, updateDoc } from "firebase/firestore";


// TO DO:
// roles: [] user roles
// permissions: [] what user can do

/**
 * Updates user
 * @param {*} email 
 * @param {*} data 
 * @returns 
 */
const updateUser = async ( email, data = {} ) => {
  const { role, aoc, name } = data;
  
  if ( !email ) {
      console.error('Email is required!')
      return;
  }

  if ( !role ) {
    console.error('Role is required');
    return;
  }

  if ( !aoc ) {
    console.error('AOC is required');
    return;
  }

  if ( !name ) {
    console.error('Name is required');
    return;
  }

  try {
      const user = getAuth().currentUser?.email ?? null;

      const userDoc = await getDoc( doc( db, "users", email ) )
      const now = new Date();

      if ( userDoc.exists() ) {
        const userData = userDoc.data();

        await updateDoc(
          doc( db, "users", email ),
          {
            ...data,
            updated_by: user?.email ?? "",
            updated_at: serverTimestamp()
          }
        )

        await setDoc(
          doc( db, "users", email, "user_update_history",  now.toISOString()),
          {
            action: "update",
            oldData: {
              ...userData
            },
            newData: {
              ...userData,
              ...data
            },
            created_at: serverTimestamp()
          }
        )
      }
  } catch(error) {
      console.error(error);
  }
}

/**
* Add user
* @param {*} email 
* @returns 
*/
const addUser = async (email, data) => {
  const { role, aoc, name } = data;

  if ( !email ) {
      console.error('Email is required!');
      return;
  }

  if ( !role ) {
    console.error('Role is required');
    return;
  }

  if ( !aoc ) {
    console.error('AOC is required');
    return;
  }

  if ( !name ) {
    console.error('Name is required');
    return;
  }

  try {
      const user = getAuth().currentUser?.email ?? null;

      await setDoc(
          doc( db, "users", email ),
          {  
              email: email,
              is_active: true,
              created_at: serverTimestamp(),
              created_by: user?.email ?? "",
              role,
              aoc,
              name
          },
          {
              merge: true
          }
      )
  } catch(error) {
      console.error(error);
  }
}

/**
 * Checks if user exists
 * @param {*} email 
 * @returns 
 */
const checkIfUserExists = async ( email ) => {
  if ( !email ) {
      console.error("Email is required!");
      return false;
  }

  try {
      const userDoc = await getDoc( doc( db, "users", email ) )

      return userDoc.exists();
  } catch( error ) { 
      console.error(error);
      return false;
  }
}

/**
 * Checks if user is active
 * @param {*} email 
 * @returns 
 */
const checkIfUserIsActive = async ( email ) => {
  if ( !email ) {
    console.error("Email is required!");
    return false;
  }

  try {
      const userDoc = await getDoc( doc( db, "users", email ) )

      if ( userDoc.exists() ) {
        const userData = userDoc.data();

        return userData?.is_active ?? false;
      } else {
        console.error(`User with ${email} does not exists.`);
        return false;
      }
  } catch( error ) { 
      console.error(error);
      return false;
  }
}

export {
  addUser,
  updateUser,
  checkIfUserExists,
  checkIfUserIsActive
}