import { Box, Button, CircularProgress, Typography } from "@mui/material"


export const AssessmentLayout = (props) => {

    const { children, title } = props

    return (
        <>
            <Box sx={{ m: -3, textAlign: "left", p: 2 }}>
                <Typography variant="h6">{title}</Typography>
            </Box>
            <Box
                sx={{
                    mt: 2,
                    mr: -3,
                    ml: -3,
                    mb: 2,
                    p: 3,
                    textAlign: "left",
                    boxShadow: "0px 1px 4px 0px #00000029",
                }}
            >
                {children}
            </Box>
        </>
    )
}

export const AssessmentButtonAction = (props) => {

    const { saveAction, secondButton, secondAction, isLoading } = props

    return (
        <Box m={2} width={"100%"}>
            <Button size='large' sx={{ float: "right" }} variant='contained' onClick={saveAction}>
                Save
            </Button>
            <Button size='large' sx={{ float: "right" }} onClick={secondAction}>
                {secondButton}
            </Button>
            {isLoading && <CircularProgress />}
        </Box>
    )
}

export const AssessmentSection = (props) => {
    const { children, width } = props
    var boxWidth = width
    if(boxWidth === null) {
        boxWidth = "fit-content"
    }
    return (
        <Box
            sx={{
                mt: 2,
                mb: 2,
                boxShadow: "0px 1px 4px 0px #00000029",
                width: {boxWidth},
                p: 3,
            }}
        >
            {children}
        </Box>
    )
}
