import { Box, FormControl, MenuItem, TextField } from '@mui/material'
import {
    DateTimePicker,
    LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from 'dayjs';
import React from 'react'
import AssessmentRender from '../../AssessmentRender';
import { dateToDDMMYYYHHmm } from '../../../utils/date';

export default function DetailsForm(props) {
    const {selectedRows, formError, handleChange, handleDateChange, listCountry, assessmentList, handleAssessmentChange} = props
    return (
        <form autoComplete="off">
            <Box display="flex" flexDirection="row" width="100%">
                <Box sx={{ m: 1, flexGrow: 1 }}>
                    <p style={{ color: "#4C4C50" }}><strong>Created By</strong>: {selectedRows ? selectedRows.created_by : ""} </p>
                    <p style={{ color: "#4C4C50" }}><strong>Created At</strong>: {selectedRows ? dateToDDMMYYYHHmm(new Date(selectedRows?.created_at)) : ""}</p>
                </Box>
                <Box sx={{ m: 1, flexGrow: 1 }}>
                    <p style={{ color: "#4C4C50" }}><strong>Modified By</strong>: {selectedRows ? selectedRows.updated_by : ""}</p>
                    <p style={{ color: "#4C4C50" }}><strong>Last Modified Date</strong>: {selectedRows ? dateToDDMMYYYHHmm(new Date(selectedRows?.updated_at)) : ""}</p>
                </Box>
            </Box>
            <Box display="flex" flexDirection="row" width="100%">
                <Box sx={{ flex: 2 }}>
                    <FormControl variant="standard" sx={{ width: "100%", p: 1 }}>
                        <TextField required size='small' id="name" name="name" label="Event Name" defaultValue={selectedRows ? selectedRows.event_name : ""} onChange={handleChange} error={formError.name.error} helperText={formError.name.msg} />
                    </FormControl>
                    <FormControl variant="standard" sx={{ width: "100%", p: 1 }}>
                        <TextField
                            required
                            size='small'
                            id="venue"
                            name="venue"
                            label="Event Location"
                            onChange={handleChange}
                            defaultValue={selectedRows ? selectedRows.event_venue : ''}
                            error={formError.venue.error} helperText={formError.venue.msg}
                        />
                    </FormControl>
                    <FormControl variant="standard" sx={{ width: "100%", p: 1 }}>
                        <TextField required size='small' id="aoc" name="aoc" label="Company Name" defaultValue={selectedRows ? selectedRows.event_aoc : ''} onChange={handleChange} error={formError.aoc.error} helperText={formError.aoc.msg} />
                    </FormControl>
                </Box>
                <Box sx={{ flex: 1 }}>
                    <FormControl variant="standard" onChange={handleChange} sx={{ width: "100%", p: 1 }}>
                        <TextField
                            disabled
                            size='small'
                            id="jr-number"
                            name="jrNumber"
                            label="JR Number"
                            defaultValue={selectedRows ? selectedRows.jr_number : ''}
                        />
                    </FormControl>
                    <FormControl variant="standard" sx={{ width: "100%", p: 1 }}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DateTimePicker disablePast ampm={false} onChange={handleDateChange} className="date" label="Event Date Time" defaultValue={selectedRows ? dayjs(selectedRows.event_date_time, 'DD-MM-YYYY HHmm') : ''}
                                slotProps={{
                                    textField: {
                                        helperText: formError.dateTime.msg,
                                        size: 'small'
                                    },
                                }}
                                sx={{
                                    "& .MuiFormHelperText-root": {
                                        color: "#c92d21 !important"
                                    }
                                }} />
                        </LocalizationProvider>
                    </FormControl>
                    <FormControl variant="standard" sx={{ width: "100%", p: 1 }}>
                        <TextField
                            required
                            onChange={handleChange}
                            size="small"
                            id="company-country"
                            name="companyCountry"
                            label="Hiring Country"
                            defaultValue={selectedRows ? selectedRows.hiring_country : ""}
                            error={formError.companyCountry.error} helperText={formError.companyCountry.msg}
                            select
                        >
                            {listCountry.map((opt) => (
                                <MenuItem key={opt === "" ? "" : opt} value={opt === "" ? "" : opt}>{opt === "" ? "-" : opt}</MenuItem>
                            ))}
                        </TextField>
                    </FormControl>
                </Box>
            </Box>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    ml: 1,
                    mt: 1,
                }}
            >
                <AssessmentRender list={assessmentList} handleChange={handleAssessmentChange} formError={formError.assessment} isDisabled={false} />
            </Box>
        </form>
    )
}
