export const INITIAL_FILTER = {
  name: "",
  docs: "",
  jrNumber: "",
  city: "",
  country: "",
  gender: "",
  nationality: "",
  languages: "",
  email: "",
  phone: "",
  how: "",
  age: "",
  company: "",
  job: "",
  jobLocation: "",
  experience: "",
  airasia: "",
  companyName: "",
  source: "",
  exceptionStatus: "",
  assessment_status: "",
  registration_status: "",
  candidateNumber: "",
};

export const EXCEPTION_STATUS = [
  { value: "", label: "Please Select" },
  { value: "N/A", label: "N/A" },
  { value: "Blacklisted", label: "Blacklisted" },
  { value: "KIV", label: "KIV" },
  { value: "Under Age", label: "Under Age" },
  { value: "Under Cooling Period", label: "Under Cooling Period" },
];

export const ASSESSMENT_STATUS_OPTIONS = [
  {
    label: "Pending",
    value: "Pending",
  },
  {
    label: "In-progress",
    value: "In Progress",
  },
  {
    label: "Passed",
    value: "Passed",
  },
  {
    label: "Failed",
    value: "Failed",
  },
  {
    label: "KIV",
    value: "KIV",
  },
  {
    label: "Not Attended",
    value: "Not Attended",
  },
];

export const REGISTRATION_STATUS_OPTIONS = [
  {
    label: "Pending",
    value: "Pending",
  },
  {
    label: "Confirmed",
    value: "Confirmed",
  },
  {
    label: "Exception - KIV",
    value: "Exception - KIV",
  },
  {
    label: "Exception - Under Age",
    value: "Exception - Under Age",
  },
  {
    label: "Exception - Under Cooling Period",
    value: "Exception - Under Cooling Period",
  },
  {
    label: "Exception - Blacklisted",
    value: "Exception - Blacklisted",
  },
];

export const ABOUT_THIS_JOB_OPTIONS = [
  {
    label: "Advertisement",
    value: "Advertisement",
  },
  {
    label: "Agency",
    value: "Agency",
  },
  {
    label: "AirAsia Career Site",
    value: "AirAsia Career Site",
  },
  {
    label: "AngelList",
    value: "AngelList",
  },
];

export const SOURCE_OPTIONS = [
  {
    label: "Please Select",
    value: "",
  },
  {
    label: "Walk In",
    value: "Walk In",
  },
  {
    label: "Online",
    value: "Online",
  },
];

export const EVENT_COMPANY_NAME = [
  {
    label: "Please Select",
    value: "",
  },
  {
    label: "MAA AirAsia Berhad",
    value: "MAA AirAsia Berhad",
  },
  {
    label: "AAX AirAsia X Berhad",
    value: "AAX AirAsia X Berhad",
  },
  {
    label: "IAA PT. INDONESIA AIRASIA",
    value: "IAA PT. INDONESIA AIRASIA",
  },
  {
    label: "TAA Thai AirAsia Co., Ltd",
    value: "TAA Thai AirAsia Co., Ltd",
  },
  {
    label: "TAX Thai AirAsia X Co Ltd",
    value: "TAX Thai AirAsia X Co Ltd",
  },
  {
    label: "PAA Philippines AirAsia Inc.",
    value: "PAA Philippines AirAsia Inc.",
  },
  {
    label: "CAM AirAsia (Cambodia) Co., Ltd.",
    value: "CAM AirAsia (Cambodia) Co., Ltd.",
  },
]
