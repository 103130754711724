import { Close } from "@mui/icons-material";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import { useState } from "react";
import { LANGUAGES_SPOKEN, LANGUAGE_ABILITY_TYPE, LANGUAGE_PROFICIENCY } from "../../utils/registrationUtils";

function LanguagesModal( props ) {
  const { open, handleClose, handleSubmit } = props;
  
  const [formValues, setFormValues] = useState({});
  const [formErrors, setFormErrors] = useState({});

  const handleOnChange = ( e ) => {
    const value = `${e.target.value}`;
    const name = `${e.target.name}`;

    if ( name ) {
      const error = formErrors[name];
      if ( error ) {
        setFormErrors({
          ...formErrors,
          [name] : ""
        });
      } 

      setFormValues({
        ...formValues,
        [name] : value
      });
      
    }
  }

  const handleCloseModal = () => {
    setFormErrors({});
    setFormValues({});
    handleClose();
  }

  const handleSubmitLanguage = () => {
    const language = formValues.language;
    const languageAbilityType = formValues.language_ability_type;
    const languageProficiency = formValues.language_proficiency;

    setFormErrors({
      language : !language ? "Select a Language." : "",
      language_ability_type: !languageAbilityType ? "Select language ability type." : "",
      language_proficiency: !languageProficiency ? "Select langugae proficiency." : ""
    });

    let proceed = language && languageAbilityType && languageProficiency;

    if (  proceed ) {
      // proceed
      handleSubmit(formValues);
      handleCloseModal();
    }

  } 

  return(
    <Dialog open={open} 
      onClose={handleCloseModal}
      maxWidth="sm"
      fullWidth>
      <DialogTitle>
        <Box display="flex" alignItems="center" justifyContent="space-between" pt={1}>
          <Typography variant="h5" fontSize="20px" fontWeight="medium" color="#4C4C50" mb={-3}>Add Language</Typography>
          <IconButton onClick={handleCloseModal}>
            <Close />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box height="10px"></Box>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl fullWidth size="small" required>
              <InputLabel>Language</InputLabel>
              <Select
                value={formValues["language"] ?? ""}
                label="Language"
                name="language"
                id="language"
                onChange={handleOnChange}
                error={formErrors["language"] ? true : false}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: '200px', // Set the maximum height here
                    },
                  },
                }}>
                { LANGUAGES_SPOKEN.map((option, index) => {
                  const key = `language-option-item-${index}`;
                  return(
                    <MenuItem key={key} value={option.value}>{option.label}</MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth size="small" required>
              <InputLabel>Language Ability Type</InputLabel>
              <Select
                value={formValues["language_ability_type"] ?? ""}
                label="Language Ability Type"
                name="language_ability_type"
                id="language_ability_type"
                onChange={handleOnChange}
                error={formErrors["language_ability_type"] ? true : false}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: '200px', // Set the maximum height here
                    },
                  },
                }}>
                { LANGUAGE_ABILITY_TYPE.map((option, index) => {
                  const key = `language-ability-type-option-item-${index}`;
                  return(
                    <MenuItem key={key} value={option.value}>{option.label}</MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth size="small" required>
              <InputLabel>Language Proficiency</InputLabel>
              <Select
                value={formValues["language_proficiency"] ?? ""}
                label="Language Proficiency"
                name="language_proficiency"
                id="language_proficiency"
                onChange={handleOnChange}
                error={formErrors["language_proficiency"] ? true : false}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: '200px', // Set the maximum height here
                    },
                  },
                }}>
                { LANGUAGE_PROFICIENCY.map((option, index) => {
                  const key = `language-ability-type-option-item-${index}`;
                  return(
                    <MenuItem key={key} value={option.value}>{option.label}</MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <Box px={2} pb={2}>
        <DialogActions spacing={3}>
          <Button
            onClick={handleCloseModal} 
            sx={{ minWidth: "90px" }}>
            Cancel
          </Button>
          <Button 
            variant="contained"
            onClick={handleSubmitLanguage}
            sx={{ minWidth: "90px" }}>
            Save
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
}

export default LanguagesModal;