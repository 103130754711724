import { useRef, useState } from "react";
import { Box, Button, ButtonGroup, FormControl, IconButton, MenuItem, TextField, Tooltip, Autocomplete } from "@mui/material"
import { GridToolbarContainer } from "@mui/x-data-grid"
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import { FORM_COUNTRY_OPTIONS, LANGUAGES_SPOKEN } from "../../utils/registrationUtils";
import { ABOUT_THIS_JOB_OPTIONS, ASSESSMENT_STATUS_OPTIONS, EVENT_COMPANY_NAME, EXCEPTION_STATUS, REGISTRATION_STATUS_OPTIONS, SOURCE_OPTIONS } from "../../utils/candidateFilterUtil";
import { autoSuggestOptions } from "../../lib/db_candidate";

function CandidateToolbarFilter({ handleSelect, handleChange, allJrNumber, handleFilter, holder, eventDetail, forEvent = false }) {
    const [moreFilter, setMoreFilter] = useState(false)
    const jrRef = useRef()
    const nameRef = useRef()
    const handleClickMoreFilter = () => {
        setMoreFilter(!moreFilter)
    }

    const [options, setOptions] = useState([]);

    const handleInputChange = async (key, newValue) => {
    
        if ( newValue && newValue !== "" ) {
            const suggestions = await autoSuggestOptions(key, newValue);

            setOptions(suggestions);
        } else {
            setOptions([]);
        }
    };

    const handleInputBlur = () => {
        setOptions([]);
    };

    const onJrNumberSelect = (e)  => {
        const val = e.target.value;

        if ( val && val !== "" ) {
            setOptions(allJrNumber)
        } else {
            setOptions(allJrNumber.slice(0, 10));
        }

        handleChange(e, val);
    }

    const onJrNumberChange = (e, val)  => {
        const name = jrRef.current.getAttribute("name");
        if ( val && val !== "" ) {
            setOptions(allJrNumber)
        } else {
            setOptions(allJrNumber.slice(0, 10));
        }

        handleSelect(name, val);
    }

    const handleNameChange = (e, val) => {
        const name = nameRef.current.getAttribute("name");
        handleSelect(name, val)
    }

    const handleNameSelect = (e) => {
        handleChange(e, e.target.value)
    }

    return (
        <>
            <GridToolbarContainer sx={{ maxWidth: '100%', justifyContent: "center", pt: 1, pb: 1 }}>
                <>
                { !forEvent && (<FormControl sx={{ flex: 1 }}>
                    <Autocomplete
                        ref={jrRef}
                        key="jrNumber"
                        id="jrNumber"
                        name="jrNumber"
                        defaultValue={holder.jrNumber}
                        options={options}
                        limitTags={10}
                        multiple={false}
                        getOptionLabel={(option) => option ?? ""}
                        onBlur={handleInputBlur}
                        //
                        onSelect={onJrNumberSelect}
                        onChange={onJrNumberChange}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                autoComplete="off"
                                id="jrNumber"
                                name="jrNumber"
                                label="JR Number"
                                size="small"
                                variant="outlined"
                            />
                        )}
                    />
                    {/* <TextField onChange={handleChange} autoComplete='off' id='jrNumber' name='jrNumber' defaultValue={holder.jrNumber} label="JR Number" size='small' variant="outlined" select >
                        <MenuItem key="" value="">Please Select</MenuItem>
                        {allJrNumber.map((opt) => (
                            <MenuItem key={opt === "" ? "" : opt} value={opt === "" ? "" : opt}>{opt === "" ? "Please Select" : opt}</MenuItem>
                        ))}
                    </TextField> */}
                </FormControl>)}
                <FormControl sx={{ flex: 1 }}>
                    <TextField onChange={handleChange} autoComplete='off' id='companyName' name='companyName' defaultValue={holder.companyName} label="Event Company Name" size='small' variant="outlined" select>
                        {EVENT_COMPANY_NAME.map((option)=>(
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                </FormControl>
                <FormControl sx={{ flex: 1 }}>
                    <TextField onChange={handleChange} autoComplete='off' id='source' name='source' defaultValue={holder.source} label="Source" size='small' variant="outlined" select>
                        {SOURCE_OPTIONS.map((option)=>(
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                </FormControl>
                {forEvent && (
                    <FormControl onChange={handleChange} sx={{ flex: 1 }}>
                        <TextField autoComplete='off' id='candidateNumber' name='candidateNumber' defaultValue={holder.candidateNumber} label="Candidate #" size='small' variant="outlined" />
                    </FormControl>
                )}
                <FormControl onChange={handleChange} sx={{ flex: 1 }}>
                    <Autocomplete
                        ref={nameRef}
                        key="name123"
                        id="name"
                        name="name"
                        defaultValue={holder.name}
                        options={options}
                        multiple={false}
                        getOptionLabel={(option) => option ?? ""}
                        onInputChange={(_, v) => {
                            handleInputChange("full_name", v)
                        }}
                        onBlur={handleInputBlur}
                        //
                        onSelect={handleNameSelect}
                        onChange={handleNameChange}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                autoComplete="off"
                                id="name"
                                name="name"
                                label="First Name / Last Name"
                                size="small"
                                variant="outlined"
                            />
                        )}
                    />
                </FormControl>
                <FormControl onChange={handleChange} sx={{ flex: 1 }}>
                    <TextField autoComplete='off' id='docs' name='docs' defaultValue={holder.docs} label="National ID / Passport" size='small' variant="outlined" />
                </FormControl>
                </>
                <Button variant='outlined' onClick={handleClickMoreFilter}>
                    {moreFilter === false ? "+ More Filter" : "- Hide Filter"}
                </Button>
            </GridToolbarContainer>
            {moreFilter === true ? 
            <GridToolbarContainer sx={{ maxWidth: '100%', justifyContent: "center", pt: 1, pb: 1 }}>
                <FormControl onChange={handleChange} sx={{ flex: 1 }}>
                    <Autocomplete
                        key="city"
                        id="city"
                        name="city"
                        defaultValue={holder.city}
                        options={options}
                        multiple={false}
                        getOptionLabel={(option) => option ?? ""}
                        onInputChange={(_, v) => {
                            handleInputChange("city", v)
                        }}
                        onBlur={handleInputBlur}
                        onSelect={handleChange}
                        onChange={handleChange}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                autoComplete="off"
                                id="city"
                                name="city"
                                label="City"
                                size="small"
                                variant="outlined"
                            />
                        )}
                    />
                </FormControl>
                <FormControl sx={{ flex: 1 }}>
                    <TextField  onChange={handleChange} autoComplete='off' id='country' name='country' defaultValue={holder.country} label="Country" size='small' variant="outlined" select>
                        {FORM_COUNTRY_OPTIONS.map((option)=>(
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                </FormControl>
                <FormControl sx={{ flex: 1 }}>
                    <TextField onChange={handleChange} autoComplete='off' id='gender' name='gender' defaultValue={holder.gender} label="Gender" size='small' variant="outlined" select>
                        <MenuItem key="" value="">
                            Please Select
                        </MenuItem>
                        <MenuItem key="M" value="M">
                            MALE
                        </MenuItem>
                        <MenuItem key="F" value="F">
                            FEMALE
                        </MenuItem>
                        <MenuItem key="P" value="P">
                            PREFER NOT TO DECLARE
                        </MenuItem>
                    </TextField>
                </FormControl>
                <FormControl onChange={handleChange} sx={{ flex: 1 }}>
                    <TextField autoComplete='off' id='age' name='age' defaultValue={holder.age} label="Age" size='small' variant="outlined" />
                </FormControl>
                <FormControl onChange={handleChange} sx={{ flex: 1 }}>
                    <Autocomplete
                        key="nationality"
                        id="nationality"
                        name="nationality"
                        defaultValue={holder.nationality}
                        options={options}
                        multiple={false}
                        getOptionLabel={(option) => option ?? ""}
                        onInputChange={(_, v) => {
                            handleInputChange("nationality", v)
                        }}
                        onBlur={handleInputBlur}
                        onSelect={handleChange}
                        onChange={handleChange}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                autoComplete="off"
                                id="nationality"
                                name="nationality"
                                label="Nationality"
                                size="small"
                                variant="outlined"
                            />
                        )}
                    />
                </FormControl>
                
            </GridToolbarContainer> : ""}
            {moreFilter === true ? 
            <GridToolbarContainer sx={{ maxWidth: '100%', justifyContent: "center", pt: 1, pb: 1 }}>
                <FormControl sx={{ flex: 1 }}>
                    <TextField onChange={handleChange} autoComplete='off' id='languages' name='languages' defaultValue={holder.languages} label="Languages Spoken" size='small' variant="outlined" select>
                        {LANGUAGES_SPOKEN.map((option)=>(
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                </FormControl>
                <FormControl onChange={handleChange} sx={{ flex: 1 }}>
                    <TextField autoComplete='off' id='email' name='email' defaultValue={holder.email} label="Email Address" size='small' variant="outlined" />
                </FormControl>
                <FormControl onChange={handleChange} sx={{ flex: 1 }}>
                    <TextField autoComplete='off' id='phone' name='phone' defaultValue={holder.phone} label="Phone Number" size='small' variant="outlined" />
                </FormControl>
                <FormControl sx={{ flex: 1 }}>
                    <TextField onChange={handleChange}  autoComplete='off' id='how' name='how' defaultValue={holder.how} label="How did you hear about this job?" size='small' variant="outlined" select>
                        <MenuItem key="" value="">
                            Please Select
                        </MenuItem>
                        {ABOUT_THIS_JOB_OPTIONS.map((option)=>(
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                </FormControl>
                <FormControl sx={{ flex: 1 }}>
                    <TextField onChange={handleChange} autoComplete='off' id='airasia' name='airasia' defaultValue={holder.airasia} label="Have you worked at Airasia before?" size='small' variant="outlined" select>
                        <MenuItem key={""} value={""}>
                            Please Select
                        </MenuItem>
                        <MenuItem key={"Yes"} value={"Yes"}>
                            YES
                        </MenuItem>
                        <MenuItem key={"No"} value={"No"}>
                            NO
                        </MenuItem>
                    </TextField>
                </FormControl>
            </GridToolbarContainer> : ""}
            {moreFilter === true ? <GridToolbarContainer sx={{ maxWidth: '100%', justifyContent: "center", pt: 1, pb: 1 }}>
                <FormControl onChange={handleChange} sx={{ flex: 1 }}>
                    <Autocomplete
                        key="company"
                        id="company"
                        name="company"
                        defaultValue={holder.company}
                        options={options}
                        multiple={false}
                        getOptionLabel={(option) => option ?? ""}
                        onInputChange={(_, v) => {
                            handleInputChange("job_companies", v)
                        }}
                        onBlur={handleInputBlur}
                        onSelect={handleChange}
                        onChange={handleChange}
                        renderInput={(params) => (
                            <TextField 
                                {...params}
                                autoComplete='off' 
                                id='company' 
                                name='company' 
                                label="Work Exp - Company Name" 
                                size='small' 
                                variant="outlined"
                            />
                        )}
                    />
                    
                </FormControl>
                <FormControl onChange={handleChange} sx={{ flex: 1 }}>
                    {/* <TextField autoComplete='off' id='job' name='job' defaultValue={holder.job} label="Work Exp - Job Title" size='small' variant="outlined" /> */}
                    <Autocomplete
                        key="job"
                        id="job"
                        name="job"
                        defaultValue={holder.job}
                        options={options}
                        multiple={false}
                        getOptionLabel={(option) => option ?? ""}
                        onInputChange={(_, v) => {
                            handleInputChange("job_titles", v)
                        }}
                        onBlur={handleInputBlur}
                        onSelect={handleChange}
                        onChange={handleChange}
                        renderInput={(params) => (
                            <TextField 
                                {...params} 
                                autoComplete='off' 
                                id='job' 
                                name='job' 
                                label="Work Exp - Job Title" 
                                size='small' 
                                variant="outlined"
                            />
                        )}
                    />
                </FormControl>
                <FormControl onChange={handleChange} sx={{ flex: 1 }}>
                    {/* <TextField autoComplete='off' id='jobLocation' name='jobLocation' defaultValue={holder.jobLocation} label="Work Exp - Location" size='small' variant="outlined" /> */}
                    <Autocomplete
                        key="jobLocations"
                        id="jobLocations"
                        name="jobLocations"
                        defaultValue={holder.jobLocations}
                        options={options}
                        multiple={false}
                        getOptionLabel={(option) => option ?? ""}
                        onInputChange={(_, v) => {
                            handleInputChange("job_locations", v)
                        }}
                        onBlur={handleInputBlur}
                        onSelect={handleChange}
                        onChange={handleChange}
                        renderInput={(params) => (
                            <TextField 
                                {...params} 
                                autoComplete='off' 
                                id='jobLocations' 
                                name='jobLocations' 
                                label="Work Exp - Location" 
                                size='small' 
                                variant="outlined"
                            />
                        )}
                    />
                </FormControl>
                <FormControl onChange={handleChange} sx={{ flex: 1 }}>
                    <TextField autoComplete='off' id='experience' name='experience' defaultValue={holder.experience} label="Years of experiences" size='small' variant="outlined" />
                </FormControl>
                {!forEvent && (<FormControl sx={{ flex: 1 }}>
                    <TextField onChange={handleChange} autoComplete='off' id='exceptionStatus' name='exceptionStatus' defaultValue={holder.exceptionStatus} label="Exception Status" size='small' variant="outlined" select>
                        {EXCEPTION_STATUS.map((option)=>(
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                </FormControl>)}
                { forEvent && (
                    <FormControl sx={{ flex: 1 }}>
                        <TextField placeholder="All" onChange={handleChange} autoComplete='off' id='assessment_status' name='assessment_status' defaultValue={holder.assessment_status} label="Assessment Status" size='small' variant="outlined" select>
                            <MenuItem key={"All"} value={""}>
                                All
                            </MenuItem>
                            { ASSESSMENT_STATUS_OPTIONS.map((status) => {
                                return (
                                    <MenuItem key={status.value} value={status.value}>
                                        {status.label}
                                    </MenuItem>
                                )
                            })}
                        </TextField>
                    </FormControl>
                )}
                { forEvent && (
                    <FormControl sx={{ flex: 1 }}>
                        <TextField onChange={handleChange} autoComplete='off' id='registration_status' name='registration_status' defaultValue={holder.registration_status} label="Registration Status" size='small' variant="outlined" select>
                            <MenuItem key={"All"} value={""}>
                                All
                            </MenuItem>
                            { REGISTRATION_STATUS_OPTIONS.map((status) => {
                                return (
                                    <MenuItem key={status.value} value={status.value}>
                                        {status.label}
                                    </MenuItem>
                                )
                            })}
                        </TextField>
                    </FormControl>
                )}
            </GridToolbarContainer> : ""}
            <GridToolbarContainer sx={{ maxWidth: '100%', justifyContent: "center" }}>
                <Box sx={{
                    display: "flex",
                }}>
                    <ButtonGroup variant='text' size="small">
                        <Tooltip title="Apply Filter">
                            <IconButton id='applyFilter' name='applyFilter' onClick={handleFilter}>
                                <FilterAltIcon id='applyFilter' name='applyFilter' sx={{
                                    pointerEvents: 'none',
                                    color: 'black'
                                }} />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Clear Filter">
                            <IconButton id='clearFilter' name='clearFilter' onClick={handleFilter}>
                                <FilterAltOffIcon id='clearFilter' name='clearFilter' sx={{
                                    pointerEvents: 'none',
                                    color: 'black'
                                }} />
                            </IconButton>
                        </Tooltip>
                    </ButtonGroup>
                </Box>
            </GridToolbarContainer>
        </>
    )
}

export default CandidateToolbarFilter