

/**
 * Convert array to string
 * @param {*} input 
 * @param {*} maxToShow 
 * @returns dog,cat,mouse + 5 more
 */
const arrayToString = ( inputArray = "", maxToShow = 5 ) => {
  const remainingCount = inputArray.length - maxToShow;

  const resultString = inputArray.slice(0, maxToShow).join(", ") + (remainingCount > 0 ? ` and ${remainingCount} more` : '');

  return resultString;
}

export default arrayToString;