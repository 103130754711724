import { Button, Dialog, DialogActions, DialogTitle, Typography } from "@mui/material"

function ClearConfirmation({ isDialogOpen, handleCloseDialog, clear }){
    const handleClose = () => {
        clear()
        handleCloseDialog(false);
    }
    const handleCancel = () => {
        handleCloseDialog(false);
    }
    return(
        <div>
            <Dialog
                open={isDialogOpen}
                onClose={handleClose}
                fullWidth={true}
                maxWidth={"sm"}
            >
                <DialogTitle sx={{ p: 2 }}><Typography variant="h6">Are you sure you want to clear the form?</Typography></DialogTitle>
                <DialogActions sx={{ m: 1 }}>
                    <Button onClick={handleCancel}>Cancel</Button>
                    <Button autoFocus type="submit" onClick={handleClose} variant="contained"> Clear </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default ClearConfirmation