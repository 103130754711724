import { db } from "./firebase"
import { 
    collection,
    addDoc,
    doc,
    getDoc,
    updateDoc
} from "firebase/firestore"

const generateSendEmailToken = async (eventInfo, candidateInfoList, emailTemplate) => {
    let token = ""
    const sendEmailDetails = {
        event_info: eventInfo,
        candidate_info_list: candidateInfoList,
        email_template: emailTemplate,
        has_processed: false
    }
    //console.log(sendEmailDetails)
    try{
        const docRef = await addDoc(collection(db, "token", "invitation_email", "request"), sendEmailDetails)
        //console.log(docRef.id)
        token = docRef.id
    } catch (e) {
        console.error("Error adding document: ", e);
    }
    return token
}

const getSendEmailDetails = async (token) => {
    let sendEmailDetails = {}
    const docRef = doc(db, "token", "invitation_email", "request", token)
    const docSnap = await getDoc(docRef)
    if (docSnap.exists()) {
        sendEmailDetails = {token: docSnap.id, ...docSnap.data()}
    }
    //console.log(sendEmailDetails)
    return sendEmailDetails
}

const setTokenToHasProcessed = async (token) => {
    await updateDoc(doc(db, "token", "invitation_email", "request", token), { has_processed: true })
}

const generateAcceptInvitationToken = async (eventId, candidateId) => {
    let token = ""
    const invitationTokenDetails = {
        event_id: eventId,
        candidate_id: candidateId,
        has_processed: false
    }
    try{
        const docRef = await addDoc(collection(db, "token", "accept_invitation", "token"), invitationTokenDetails)
        //console.log(docRef.id)
        token = docRef.id
    } catch (e) {
        console.error("Error adding document: ", e);
    }
    return token
}

const getInvitationTokenDetails = async (token) => {
    let invitationTokenDetails = {}
    const docRef = doc(db, "token", "accept_invitation", "token", token)
    const docSnap = await getDoc(docRef)
    if (docSnap.exists()) {
        invitationTokenDetails = {token: docSnap.id, ...docSnap.data()}
    }
    //console.log(invitationTokenDetails)
    return invitationTokenDetails
}

const setInvitationTokenToHasProcessed = async (token) => {
    await updateDoc(doc(db, "token", "accept_invitation", "token", token), { has_processed: true })
}

export {
    generateSendEmailToken,
    getSendEmailDetails,
    setTokenToHasProcessed,
    generateAcceptInvitationToken,
    getInvitationTokenDetails,
    setInvitationTokenToHasProcessed
}