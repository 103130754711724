import { Box, CircularProgress, Stack, Typography } from '@mui/material'
import { DataGrid, GRID_CHECKBOX_SELECTION_COL_DEF } from '@mui/x-data-grid'
import React, {useEffect, useState } from 'react'
import CandidateToolbarFilter from '../../candidate/CandidateToolbarFilter'
import { INITIAL_FILTER } from '../../../utils/candidateFilterUtil'
import { getAddToEventCandidateList } from '../../../lib/db_candidate'
import AddToEventModal from '../AddToEventModal'
import apiService from '../../../lib/apiService'

export default function AddCandidates(props) {
    const { OpenFirstName, OpenLastName, selectedRows, setSelectedCandidate, setAddedCandidate, addToEventModalIsOpen, setAddToEventModalIsOpen, selectedCandidate, setIsMsgOpen} = props

    const [allJrNumber, setAllJrNumber] = useState([])
    const [isLoadingCandidate, setIsLoadingCandidate] = useState(false)
    const [filterHolder, setFilterHolder] = useState(INITIAL_FILTER)
    const [candidateList, setCandidateList] = useState([]);

    const [candidateCount, setCandidateCount] = useState(0);
    const [isLoading, setIsLoading] = useState(false)

    const initializeCandidateList = async ( filter ) => {
        setIsLoadingCandidate(true);
        const eventId = selectedRows.id;

        const {candidates = [], count = 0 } = await getAddToEventCandidateList( eventId, filter );
        
        console.log(candidates);
        setCandidateCount(count);
        setCandidateList(candidates);
        setIsLoadingCandidate(false);
    }

    const getListJrNumber  = async () => {
        apiService.getJrNumbers().then((result) => {
            const jrNumbers = result.data?.jr_numbers ?? [];

            setAllJrNumber(jrNumbers);
        })
    }

    useEffect(()=>{
        const getData = async () => {
            setIsLoading(true)
            await initializeCandidateList({...INITIAL_FILTER, jrNumber: selectedRows.jr_number})
            await getListJrNumber();
            setIsLoading(false)
        }

        getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    useEffect(() => {
        setFilterHolder(prevState => ({
            ...prevState,
            jrNumber: selectedRows.jr_number
        }))
    }, [selectedRows.jr_number])

    function CustomToolbar() {
        const [filter, setFilter] = useState(INITIAL_FILTER)
        useEffect(()=>{
            setFilter(filterHolder)
        },[])
        const handleChange = (e, val) => {
            setFilter({
                ...filter,
                [e.target.name]: e.target.value
            })
        }

        const handleSelect = (name, val) => {
            if(val === null) {
                val = ""
            }
            setFilter({
                ...filter,
                [name]: val
            })
        }

        const handleFilter = async (e, val) => {
            e.preventDefault()

            if(e.target.id === "applyFilter"){
                setFilterHolder(filter)
            } else if(e.target.id === "clearFilter"){
                setFilterHolder(INITIAL_FILTER)
            }

            await initializeCandidateList(
                e.target.id === "clearFilter"
                    ? INITIAL_FILTER
                    : filter
            );
        }
        return (
            <CandidateToolbarFilter handleSelect={handleSelect} handleChange={handleChange} allJrNumber={allJrNumber} handleFilter={handleFilter} holder={filterHolder}/>
        )
    }

    const handleSelectionChange = (selection) => {
        let selected = []
        for (let i = 0; i < selection.length; i++) {
            for (let k = 0; k < candidateList.length; k++) {
                if (candidateList[k].id === selection[i]) {
                    selected.push(candidateList[k])
                }
            }
        }
        setSelectedCandidate(selected)
    }

    const handleAddToEventModalClose = () => {
        setAddToEventModalIsOpen(false);
    }

    const columnsCandidate = [
        { field: 'full_name', headerName: 'First Name', minWidth: 150, flex: 1, filterable: false, renderCell: OpenFirstName },
        { field: 'last_name', headerName: 'Last Name', minWidth: 150, flex: 1, filterable: false, renderCell: OpenLastName },
        { field: 'candidate_status', headerName: 'Candidate Status', width: 150, filterable: false },
        { field: 'application_date', headerName: 'Application Date', width: 150 },
        { field: 'no_of_events', headerName: '# of events', width: 100 },
        { field: 'gender', headerName: 'Gender', width: 80 },
        { field: 'city', headerName: 'City', width: 100, filterable: false },
        { field: 'country', headerName: 'Country', width: 100, filterable: false },
        { ...GRID_CHECKBOX_SELECTION_COL_DEF, width: 80 },
    ]
    return (
        <Box sx={{ m: -4 }}>
            {!isLoadingCandidate ? <Box sx={{
                height: "auto", width: "100%",
                '& .theme--header': {
                    backgroundColor: '#F1F1F1',
                    opacity: 0.5,
                },
            }}>
                <div style={{ flexGrow: 1 }}>
                    <DataGrid
                        checkboxSelection
                        sx={{
                            "& .MuiDataGrid-columnHeader": {
                                // Forced to use important since overriding inline styles
                                height: "unset !important"
                            },
                            "& .MuiDataGrid-columnHeaders": {
                                // Forced to use important since overriding inline styles
                                maxHeight: "168px !important"
                            },
                            '.MuiDataGrid-columnSeparator': {
                                display: 'none',
                            },
                            "& .MuiDataGrid-cell": {
                                border: 1,
                                borderColor: "#D9DBE0"
                            },
                            p: 3
                        }}
                        rows={candidateList}
                        columns={columnsCandidate}
                        autoHeight={true}
                        onRowSelectionModelChange={handleSelectionChange}
                        components={{ 
                            toolbar: CustomToolbar,
                            NoRowsOverlay: () => (
                                <>
                                    <Stack height="100%" alignItems="center" justifyContent="center">
                                        {isLoading ? <><p>Getting Candidates</p><CircularProgress/></> : "No candidates"}
                                    </Stack>
                                </>
                            ),
                            
                        }}
                        componentsProps={{
                            panel: {
                                placement: "bottom-end",
                            },
                            pagination: {
                                labelRowsPerPage: 'Rows per page:', // Customize the label as needed
                                labelDisplayedRows: ({ from, to, count }) => {
                                    if ( count > 0 ) {
                                        const totalCount = candidateCount > 0
                                            ? `${candidateCount}`
                                            : `${count-1}+`
                                        return `${from}-${to} of ${totalCount}`;
                                    } else {
                                        return ``;
                                    }
                                }
                            },
                        }}
                        getRowClassName={(params) => {
                            return typeof params.row !== "object" ? "": "theme--rows"
                        }}
                        />
                </div>
            </Box> : <><Typography variant='h6'>Loading Candidate</Typography> <CircularProgress /></>}
            <AddToEventModal
                open={addToEventModalIsOpen}
                eventId={selectedRows.id}
                handleCloseModal={handleAddToEventModalClose}
                candidates={selectedCandidate}
                onSuccess={() => {
                    setAddedCandidate(true)
                    initializeCandidateList(filterHolder)
                    setIsMsgOpen(true)
                    handleAddToEventModalClose();
                }}
            />
        </Box>
    )
}
